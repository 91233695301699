import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IncomeStatementService } from 'src/app/services/IncomeStatement/IncomeStatement.service';
import { ExerciseService } from 'src/app/services/sockets/exercises/exercise.service';

@Component({
  selector: 'app-dialog-cash-flow-exercise',
  templateUrl: './dialog-cash-flow-exercise.component.html',
  styleUrls: ['./dialog-cash-flow-exercise.component.scss']
})
export class DialogCashFlowExerciseComponent implements OnInit, OnDestroy {
  monthHistoric = 0;
  numbers = [];
  forecastExc : number = 0
  cycles : number = 0
  auxMes : number = 0
  auxNumber : number = 0
  destroy$: Subject<boolean> = new Subject<boolean>();
  arrColumnas = [];
  isFacilitator = false;
  isfinished: boolean = false;
  workshopId = 0;
  jsonLabels = [];
  mes: number = 0;
  teamId: number = 0;
  canEdit = true;
  isLocked : boolean = true;
  cfflock : number;
  datos = {
    "startCash": 0,
    "daysReceivables": 0,
    "daysLoansPayables": 0,
    "upgrade": 0,
    "recruitment": 0,
    "rawMaterials": 0,
    "labour": 0,
    "overheads": 0,
    "advertising": 0,
    "cashSales": 0,
    "result1": 0,
    "result2": 0,
    "result3": 0,
    "result4": 0,
    "result5": 0,
    "result6": 0,
    "result7": 0,
    "result8": 0,
    "result9": 0,
    "result10": 0,
  }

  datosFacilitador = {
    "startCash": 0,
    "daysReceivables": 0,
    "daysLoansPayables": 0,
    "upgrade": 0,
    "recruitment": 0,
    "rawMaterials": 0,
    "labour": 0,
    "overheads": 0,
    "advertising": 0,
    "cashSales": 0,
    "result1": 0,
    "result2": 0,
    "result3": 0,
    "result4": 0,
    "result5": 0,
    "result6": 0,
    "result7": 0,
    "result8": 0,
    "result9": 0,
    "result10": 0,
  }
  constructor(
    public matDialogRef: MatDialogRef<DialogCashFlowExerciseComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private exerciseService: ExerciseService,
    private incomeService: IncomeStatementService,
  ) {
    this.mes = this.data.month;
    this.teamId = this.data.teamId;
    this.isFacilitator = this.data.blnFacilitador;
    this.workshopId = this.data.workshopId;
    this.forecastExc = this.data.forecastExc
    this.cycles = this.data.cycles
    this.monthHistoric = this.mes;
   }

  async ngOnInit(): Promise<void>  {
    switch (this.cycles) {
      case 4:
        this.numbers = Array(1)
        this.auxMes = 4
        break;
      case 5:
        this.numbers = this.forecastExc == 1 ? Array(2) : Array(1)
        this.auxMes = this.forecastExc == 1 ? 4 : 5
        break;
      case 6:
        this.numbers = this.forecastExc == 1 ? Array(3) : Array(2)
        this.auxMes = this.forecastExc == 1 ? 4 : 5
        break;
    
      default:
        break;
    }
    
    if(this.mes>this.cycles){
      console.log("Entra")
      this.isfinished = true;
      this.isfinishedWS()
      return;
    }
    this.isLocked = this.isFacilitator ? false : true;
    const responseData = await this.exerciseService.getCff(this.teamId, this.mes);
    const responseDataFac = await this.exerciseService.getCffFac(this.teamId, this.mes);
    const respondeCffLock = await this.exerciseService.getCffLock(this.workshopId);
    console.log(responseData)
    console.log(responseDataFac)
    console.log(respondeCffLock)
    // this.cfflock = respondeCffLock.data.CffLock
    if(this.isFacilitator){
      this.isLocked = false;
    }else{
      this.isLocked = false;
    }
    console.log(this.isLocked);
    if(responseData.intResponse == 200 && responseData.data !=null){
      console.log("ok")
      console.log(responseData)
      this.datos.advertising = responseData.data['advertising']
      this.datos.cashSales = responseData.data['cashSales']
      this.datos.daysLoansPayables = responseData.data['daysLoansPayables']
      this.datos.daysReceivables = responseData.data['daysReceivables']
      this.datos.labour = responseData.data['labour']
      this.datos.overheads = responseData.data['overheads']
      this.datos.rawMaterials = responseData.data['rawMaterials']
      this.datos.recruitment = responseData.data['recruitment']
      this.datos.startCash = responseData.data['startCash']
      this.datos.upgrade = responseData.data['upgrade']
      this.fnResultado(this.datos)
    }else{
      console.log("Ceros")
      this.datos.advertising = null
      this.datos.cashSales = null
      this.datos.daysLoansPayables = null
      this.datos.daysReceivables = null
      this.datos.labour = null
      this.datos.overheads = null
      this.datos.rawMaterials =null
      this.datos.recruitment = null
      this.datos.startCash = null
      this.datos.upgrade = null
    }
    if(responseDataFac.intResponse == 200 && responseDataFac.data !=null){
      console.log("ok")
      console.log(responseDataFac)
      this.datosFacilitador.advertising = responseDataFac.data['advertising']
      this.datosFacilitador.cashSales = responseDataFac.data['cashSales']
      this.datosFacilitador.daysLoansPayables = responseDataFac.data['daysLoansPayables']
      this.datosFacilitador.daysReceivables = responseDataFac.data['daysReceivables']
      this.datosFacilitador.labour = responseDataFac.data['labour']
      this.datosFacilitador.overheads = responseDataFac.data['overheads']
      this.datosFacilitador.rawMaterials = responseDataFac.data['rawMaterials']
      this.datosFacilitador.recruitment = responseDataFac.data['recruitment']
      this.datosFacilitador.startCash = responseDataFac.data['startCash']
      this.datosFacilitador.upgrade = responseDataFac.data['upgrade']
      this.fnResultado(this.datosFacilitador)
    }else{
      console.log("Ceros")
      this.datosFacilitador.advertising = null
      this.datosFacilitador.cashSales = null
      this.datosFacilitador.daysLoansPayables = null
      this.datosFacilitador.daysReceivables = null
      this.datosFacilitador.labour = null
      this.datosFacilitador.overheads = null
      this.datosFacilitador.rawMaterials =null
      this.datosFacilitador.recruitment = null
      this.datosFacilitador.startCash = null
      this.datosFacilitador.upgrade = null
    }
    this.initSocket();
    console.log("hola");
  }
  async isfinishedWS(){
    const responseData = await this.exerciseService.getCff(this.teamId, this.mes-1);
    const responseDataFac = await this.exerciseService.getCffFac(this.teamId, this.mes-1);
    const respondeCffLock = await this.exerciseService.getCffLock(this.workshopId-1);
if(responseData.intResponse == 200 && responseData.data !=null){
      console.log("ok")
      console.log(responseData)
      this.datos.advertising = responseData.data['advertising']
      this.datos.cashSales = responseData.data['cashSales']
      this.datos.daysLoansPayables = responseData.data['daysLoansPayables']
      this.datos.daysReceivables = responseData.data['daysReceivables']
      this.datos.labour = responseData.data['labour']
      this.datos.overheads = responseData.data['overheads']
      this.datos.rawMaterials = responseData.data['rawMaterials']
      this.datos.recruitment = responseData.data['recruitment']
      this.datos.startCash = responseData.data['startCash']
      this.datos.upgrade = responseData.data['upgrade']
      this.fnResultado(this.datos)
    }else{
      console.log("Ceros")
      this.datos.advertising = null
      this.datos.cashSales = null
      this.datos.daysLoansPayables = null
      this.datos.daysReceivables = null
      this.datos.labour = null
      this.datos.overheads = null
      this.datos.rawMaterials =null
      this.datos.recruitment = null
      this.datos.startCash = null
      this.datos.upgrade = null
    }
    if(responseDataFac.intResponse == 200 && responseDataFac.data !=null){
      console.log("ok")
      console.log(responseDataFac)
      this.datosFacilitador.advertising = responseDataFac.data['advertising']
      this.datosFacilitador.cashSales = responseDataFac.data['cashSales']
      this.datosFacilitador.daysLoansPayables = responseDataFac.data['daysLoansPayables']
      this.datosFacilitador.daysReceivables = responseDataFac.data['daysReceivables']
      this.datosFacilitador.labour = responseDataFac.data['labour']
      this.datosFacilitador.overheads = responseDataFac.data['overheads']
      this.datosFacilitador.rawMaterials = responseDataFac.data['rawMaterials']
      this.datosFacilitador.recruitment = responseDataFac.data['recruitment']
      this.datosFacilitador.startCash = responseDataFac.data['startCash']
      this.datosFacilitador.upgrade = responseDataFac.data['upgrade']
      this.fnResultado(this.datosFacilitador)
    }else{
      console.log("Ceros")
      this.datosFacilitador.advertising = null
      this.datosFacilitador.cashSales = null
      this.datosFacilitador.daysLoansPayables = null
      this.datosFacilitador.daysReceivables = null
      this.datosFacilitador.labour = null
      this.datosFacilitador.overheads = null
      this.datosFacilitador.rawMaterials =null
      this.datosFacilitador.recruitment = null
      this.datosFacilitador.startCash = null
      this.datosFacilitador.upgrade = null
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
    this.exerciseService.closeListener();
  }

  initSocket(){
    this.exerciseService.setupSocketConnection();
    this.exerciseService._cashFlow.pipe(takeUntil(this.destroy$)).subscribe(response => {
      if(response){
        if(response.status == 'updateInputCashFlowServer'){
          this.datos[response.data.id] = response.data.value;
          if(response.data.resultado != -1){
            this.fnResultado(response.data.resultado);
          }
        } else if(response.status == 'updateInputCashFlowExampleServer'){
          console.log(response)
          this.datosFacilitador[response.data.id] = response.data.value;
          if(response.data.resultado != -1){
            this.fnResultado(response.data.resultado);
          }
        }else if(response.status == 'updateLockCashFlowServer'){
          console.log(response);
          this.cfflock = response.data.isLocked
          if(this.isFacilitator){
            this.isLocked = !response.data.isLocked;
          }else{
            this.isLocked = response.data.isLocked;
          }
          console.log(this.isLocked);
        }
      }
    })
  }
  fnResultado(resultado){
    console.log(this.datos)
    console.log(this.datosFacilitador)
    this.datosFacilitador.result1 = this.datosFacilitador.startCash + this.datosFacilitador.daysReceivables;
    this.datos.result1 = this.datos.startCash + this.datos.daysReceivables;
    this.datos.result2 = this.datos.result1 - this.datos.daysLoansPayables;
    this.datosFacilitador.result2 = this.datosFacilitador.result1 - this.datosFacilitador.daysLoansPayables;
    this.datos.result3 = this.datos.result2 - this.datos.upgrade;
    this.datosFacilitador.result3 = this.datosFacilitador.result2 - this.datosFacilitador.upgrade;
    this.datos.result4 = this.datos.result3 - this.datos.recruitment;
    this.datosFacilitador.result4 = this.datosFacilitador.result3 - this.datosFacilitador.recruitment;
    this.datos.result5 = this.datos.result4 - this.datos.rawMaterials;
    this.datosFacilitador.result5 = this.datosFacilitador.result4 - this.datosFacilitador.rawMaterials;
    this.datosFacilitador.result6 = this.datosFacilitador.result5 - this.datosFacilitador.labour;
    this.datos.result6 = this.datos.result5 - this.datos.labour;
    this.datosFacilitador.result7 = this.datosFacilitador.result6 - this.datosFacilitador.overheads;
    this.datos.result7 = this.datos.result6 - this.datos.overheads;
    this.datos.result8 = this.datos.result7 - this.datos.advertising;
    this.datosFacilitador.result8 = this.datosFacilitador.result7 - this.datosFacilitador.advertising;
    this.datos.result9 = this.datos.result8 + this.datos.cashSales;
    this.datos.result10 = this.datos.result9;
    this.datosFacilitador.result9 = this.datosFacilitador.result8 + this.datosFacilitador.cashSales;
    this.datosFacilitador.result10 = this.datosFacilitador.result9;

    // switch (resultado) {
    //   case 1:
    //     this.datosFacilitador.result1 = this.datosFacilitador.startCash + this.datosFacilitador.daysReceivables;
    //     this.datos.result1 = this.datos.startCash + this.datos.daysReceivables;
    //     break;
    //   case 2:
    //     this.datos.result2 = this.datos.result1 - this.datos.daysLoansPayables;
    //     this.datosFacilitador.result2 = this.datosFacilitador.result1 - this.datosFacilitador.daysLoansPayables;
    //     break;
    //   case 3:
    //     this.datos.result3 = this.datos.result2 - this.datos.upgrade;
    //     this.datosFacilitador.result3 = this.datosFacilitador.result2 - this.datosFacilitador.upgrade;
    //     break;
    //   case 4:
    //     this.datos.result4 = this.datos.result3 - this.datos.recruitment;
    //     this.datosFacilitador.result4 = this.datosFacilitador.result3 - this.datosFacilitador.recruitment;
    //     break;
    //   case 5:
    //     this.datos.result5 = this.datos.result4 - this.datos.rawMaterials;
    //     this.datosFacilitador.result5 = this.datosFacilitador.result4 - this.datosFacilitador.rawMaterials;
    //     break;
    //   case 6:
    //     this.datosFacilitador.result6 = this.datosFacilitador.result5 - this.datosFacilitador.labour;
    //     this.datos.result6 = this.datos.result5 - this.datos.labour;
    //     break;
    //   case 7:
    //     this.datosFacilitador.result7 = this.datosFacilitador.result6 - this.datosFacilitador.overheads;
    //     this.datos.result7 = this.datos.result6 - this.datos.overheads;
    //     break;
    //   case 8:
    //     this.datos.result8 = this.datos.result7 - this.datos.advertising;
    //     this.datosFacilitador.result8 = this.datosFacilitador.result7 - this.datosFacilitador.advertising;
    //     break;
    //   case 9:
    //     this.datos.result9 = this.datos.result8 + this.datos.cashSales;
    //     this.datos.result10 = this.datos.result9;
    //     this.datosFacilitador.result9 = this.datosFacilitador.result8 + this.datosFacilitador.cashSales;
    //     this.datosFacilitador.result10 = this.datosFacilitador.result9;
    //     break;
    //   default:
    //     break;
    // }

  }

  updateInputExample(id, resultado){
    console.log(id, resultado)
    if(this.isFacilitator){
      console.log("aqui")

      this.exerciseService.updateInputCashFlowExample(id, this.datosFacilitador[id], resultado, 'roomWorkShop'+this.workshopId, this.teamId);
    }else{
      console.log("aqui")
      this.exerciseService.updateInputCashFlowExample(id, this.datosFacilitador[id], resultado, 'room'+this.teamId, this.teamId);

    }
    if(resultado != -1){
      this.clickEqual(id,resultado);
    }
  }

  updateInput(id, resultado){
    // this.exerciseService.updateInputCashFlow(id, this.datos[id], resultado, 'room'+this.teamId, this.teamId);
    if(resultado != -1){
      this.clickEqual(id,resultado);
    }
  }

  clickEqual(id, resultado){
    console.log("SI")
    this.exerciseService.updateCff(this.datos,this.teamId,this.mes).subscribe(res =>{
      console.log(res)
      // if(res.intResponse == 200 ){
      
      // }

    });
    this.exerciseService.updateCffFac(this.datosFacilitador,this.teamId,this.mes).subscribe(res =>{
      console.log(res)
      // if(res.intResponse == 200 ){
      
      // }
    });
    this.exerciseService.updateInputCashFlow(id, this.datos[id], resultado, 'room'+this.teamId, this.teamId);
    setTimeout(() => {
      // this.exerciseService.updateInputCashFlowExample(id, this.datosFacilitador[id], resultado, 'roomWorkShop'+this.workshopId, this.teamId);
    }, 500);
    if(resultado != -1){
      this.fnResultado(resultado);
    }
  }

  fnCloseModal(){
    this.matDialogRef.close({
      status: 'cancel'
    })
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this.data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }
  async fnChangeMonthHistoric(view:string){
    this.monthHistoric = parseInt(view.slice(5));
    console.log(this.monthHistoric)
    const responseData = await this.exerciseService.getCff(this.teamId, this.monthHistoric);
    const responseDataFac = await this.exerciseService.getCffFac(this.teamId, this.monthHistoric);
    console.log(responseData)
    console.log(responseDataFac)
    if(responseData.intResponse == 200 && responseData.data !=null){
      console.log("ok")
      this.datos.advertising = responseData.data['advertising']
      this.datos.cashSales = responseData.data['cashSales']
      this.datos.daysLoansPayables = responseData.data['daysLoansPayables']
      this.datos.daysReceivables = responseData.data['daysReceivables']
      this.datos.labour = responseData.data['labour']
      this.datos.overheads = responseData.data['overheads']
      this.datos.rawMaterials = responseData.data['rawMaterials']
      this.datos.recruitment = responseData.data['recruitment']
      this.datos.startCash = responseData.data['startCash']
      this.datos.upgrade = responseData.data['upgrade']
      this.fnResultado(this.datos)
    }else{
      console.log("Ceros")
      this.datos.advertising = 0
      this.datos.cashSales = 0
      this.datos.daysLoansPayables = 0
      this.datos.daysReceivables = 0
      this.datos.labour = 0
      this.datos.overheads = 0
      this.datos.rawMaterials =0
      this.datos.recruitment = 0
      this.datos.startCash = 0
      this.datos.upgrade = 0
    }
    if(responseDataFac.intResponse == 200 && responseDataFac.data !=null){
      console.log("ok")
      this.datosFacilitador.advertising = responseDataFac.data['advertising']
      this.datosFacilitador.cashSales = responseDataFac.data['cashSales']
      this.datosFacilitador.daysLoansPayables = responseDataFac.data['daysLoansPayables']
      this.datosFacilitador.daysReceivables = responseDataFac.data['daysReceivables']
      this.datosFacilitador.labour = responseDataFac.data['labour']
      this.datosFacilitador.overheads = responseDataFac.data['overheads']
      this.datosFacilitador.rawMaterials = responseDataFac.data['rawMaterials']
      this.datosFacilitador.recruitment = responseDataFac.data['recruitment']
      this.datosFacilitador.startCash = responseDataFac.data['startCash']
      this.datosFacilitador.upgrade = responseDataFac.data['upgrade']
      this.fnResultado(this.datosFacilitador)
    }else{
      console.log("Ceros")
      this.datosFacilitador.advertising = 0
      this.datosFacilitador.cashSales = 0
      this.datosFacilitador.daysLoansPayables = 0
      this.datosFacilitador.daysReceivables = 0
      this.datosFacilitador.labour = 0
      this.datosFacilitador.overheads = 0
      this.datosFacilitador.rawMaterials =0
      this.datosFacilitador.recruitment = 0
      this.datosFacilitador.startCash = 0
      this.datosFacilitador.upgrade = 0
    }
  }
  lockCff(){
    if(this.cfflock == 1){
      this.cfflock = 0
    }else{
      this.cfflock = 1;
    }
    console.log(this.cfflock);
    this.exerciseService.updateCffLock(this.workshopId,this.cfflock).subscribe(response => {
      console.log(response);
      this.exerciseService.lockCashFlow(this.isLocked, 'roomWorkShop'+this.workshopId, this.teamId);

    });
  }
}
