import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment';
import { AuthService } from './../../services/auth/auth.service';
import { UsuarioService} from 'src/app/services/usuario/usuario.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  blnAdministrators = false;
  blnLanguages = false;
  blnWorkshops = false;
  nombre;
  email;
  isSuperAdmin = false;
  constructor(private authService: AuthService, private usuarioService: UsuarioService) {}

  ngOnInit(): void {
    if(environment.UserID == 216){
      this.isSuperAdmin = true;
    }
    if (environment.type == 1) {
      this.blnAdministrators = true;
      this.blnLanguages = true;
    }
    if (environment.type == 2) {
      //this.blnAdministrators = true;
      this.blnLanguages = true;
      this.blnWorkshops = true;
    }
    if (
      environment.type == 1 ||
      environment.type == 6
    ) {
      this.blnWorkshops = true;
      this.blnAdministrators = true;
      this.blnLanguages = true;
    }
    
    this.fnObtenerUsuario();
  }

  fnObtenerUsuario(){
    this.usuarioService.getUserInfo(environment.UserID).subscribe((response) => {
      console.log("Información usuario: ",response['data']);
      this.nombre = response['data'].nameComplete;
      this.email = response['data'].Email;
    });
  }

  fnCerrarSesion() {
    sessionStorage.setItem('isLogged', '0');
    sessionStorage.removeItem('UserID');
    sessionStorage.removeItem('type');
    sessionStorage.removeItem('DistributorID');
    sessionStorage.removeItem('CompanyName');
    sessionStorage.removeItem('IsFacilitator');
    this.authService.emitUserLogout();
  }
}
