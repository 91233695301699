import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportsService } from 'src/app/services/reports/reports.service';
import { ResultViewComponent } from '../result-view/result-view.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface LightField {
  wsID : number,
  array : string[]
}
export interface txtArea {
  idtextAreaReports: number;
  text: string;
  typeReport: number;
}

@Component({
  selector: 'app-result-ratios',
  templateUrl: './result-ratios.component.html',
  styleUrls: ['./result-ratios.component.scss']
})
export class ResultRatiosComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  lstTeams = [];
  lstTeamsData = [];
  month = 0;
  lightFields : LightField = {wsID: 0, array: []};
  backGround = "white"
  workshopId = 0;
  data = [];
  blnFacilitador = false;
  lstTextArea: Array<Array<txtArea>> = [];

  constructor(
    public matDialogRef: MatDialogRef<ResultViewComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private reportsService: ReportsService
  ) {
    this.lstTeams = [...this._data.teamsList];
    this.lstTeamsData = [...this._data.teamsList];
    console.log(this.lstTeamsData);
    console.log("hola")
    this.workshopId = parseInt(this._data.workshopId);
    this.lightFields.wsID = this.workshopId
    this.month = this._data.currentMonth;
    this.blnFacilitador = this._data.blnFacilitador;
    if(this.blnFacilitador){
      this.lstTeamsData.splice(0,1);
      console.log(this.lstTeamsData);
      console.log(this.lstTeams);
      this.lstTeams.splice(0,1);
      console.log(this.lstTeams);
    }
    this.lstTeams.unshift({ Avatar: "         ", Color: "white" })
    this.data = [
      { strLabel: `${this.getSingleTextLabel('96')} (${this.getSingleTextLabel('74')})`, key: 'profit' },
      { strLabel: this.getSingleTextLabel('15'), key: 'sales' },
      { strLabel: `${this.getSingleTextLabel('5021')} ${this.getSingleTextLabel('7013')} (${this.getSingleTextLabel('7010')})`, key: 'profitability' },
      { strLabel: this.getSingleTextLabel('45'), key: 'asset' },
      { strLabel: `${this.getSingleTextLabel('7014')} (${this.getSingleTextLabel('7011')})`, key: 'assetsTurnOver' },
      { strLabel: `${this.getSingleTextLabel('7015')} (${this.getSingleTextLabel('7010')})`, key: 'returnOnAssets' },
      { strLabel: `${this.getSingleTextLabel('74')} (${this.getSingleTextLabel('5022')})`, key: 'retained' },
    ];
    for (let i = 0; i < 2; i++) {
      this.lstTextArea.push(new Array<txtArea>());
      for (let j = 0; j < (this.lstTeamsData.length + 1); j++) {
        this.lstTextArea[i].push({
          idtextAreaReports: null,
          text: '',
          typeReport: 0,
        });
      }
    }
  }

  ngOnInit(): void {
    this.initSocket();
    this.reportsService.getQuantitiesRatioView(this.workshopId, this.month).subscribe(response => {
      console.log(response);
      if (response.income.intResponse == 200 && response.balance.intResponse == 200 && response.retained.intResponse == 200) {
        this.lstTeamsData.forEach((item, i) => {
          const index = response.income.info.findIndex((element) => element.teamId == item.TeamId);
          if (index != -1) {
            item.profit = response.income.info[index].netIncome,
              item.sales = response.income.info[index].total,
              item.profitability =Math.round(Number((response.income.info[index].netIncome / response.income.info[index].total * 100).toFixed(2))),
              item.asset = response.balance.info[index].totalCurrent1,
              item.assetsTurnOver = (response.income.info[index].total / response.balance.info[index].totalCurrent1).toFixed(2),
              item.returnOnAssets =Math.round(Number((response.income.info[index].netIncome / response.balance.info[index].totalCurrent1 * 100).toFixed(2))),
              item.retained = response.retained.info[index].retained
            item.profitability = !isFinite(item.profitability) ? "- " : item.profitability;
            item.assetsTurnOver = !isFinite(item.assetsTurnOver) ? this.getSingleTextLabel('5023') : item.assetsTurnOver;
            item.returnOnAssets = !isFinite(item.returnOnAssets) ? this.getSingleTextLabel('5023') : item.returnOnAssets;
            if(item.profit < 0){
              item['colorColumn'] = 'red';
            }else{
              item['colorColumn'] = 'black';
            }

          }
        });
      }
    });
    this.reportsService.getTextAreaByWorkshopIdAndType(this.workshopId, 0).subscribe((response: any) => {
      if (response.intResponse == 200) {
        if (response.info.length != 0) {
          this.convertArrayToMatriz(response.info);
        }
      }
    })
    this.getLight();
    console.log(this.lstTeams);

  }

  getLight(){
    let miArrayGuardado  = localStorage.getItem('lightFieldsRatios');
    if (miArrayGuardado) {
      var aux = JSON.parse(miArrayGuardado);
      if(aux.wsID == this.workshopId){
        this.lightFields = aux;
        console.log(this.lightFields); 
        setTimeout(() => {
          this.lightFields.array.forEach(element => {
            document.getElementById(element).style.backgroundColor = "yellow";
          });
        }, 500);
      }else{
        this.lightFields.array = [];
        localStorage.setItem('lightFieldsRatios', JSON.stringify(this.lightFields));
      }
    } else {
      this.lightFields.array = [];
      console.log("El array no existe en localStorage.");
    }
  }
  changeTextArea() {
    let arrayAux = [];
    this.lstTextArea.forEach(item => {
      item.forEach(element => {
        arrayAux.push(element);
      });
    });
    this.reportsService.createUpdTextArea(this.workshopId, arrayAux).subscribe(response => {
      if (response.intResponse == 200) {
        console.log(response);
        if (response.newLst.length != 0) {
          let colums = response.newLst.length / 2;
          for (let i = 0; i < 2; i++) {
            for (let j = 0; j < colums; j++) {
              this.lstTextArea[i][j].idtextAreaReports = response.newLst[colums * i + j].idtextAreaReports;
            }
          }
        }
      }
    })
  }
  convertArrayToMatriz(array: Array<txtArea>) {
    this.lstTextArea = [];
    let colums = array.length / 2;
    for (let i = 0; i < 2; i++) {
      this.lstTextArea.push(new Array<txtArea>());
      for (let j = 0; j < colums; j++) {
        this.lstTextArea[i].push(array[colums * i + j]);
      }
    }
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }

  fnClickSelect(j,i) {
      if(!this.blnFacilitador){
        return;
      }
    var idElement = "cell"+j + i;
    var colorActual = document.getElementById(idElement).style.backgroundColor;
    console.log(idElement,this.lightFields,colorActual)
    if (colorActual == "white") {
      document.getElementById(idElement).style.backgroundColor = "yellow";
      this.lightFields.array.push(idElement)
      localStorage.setItem('lightFieldsRatios', JSON.stringify(this.lightFields));
      this.reportsService.updateLightRatios('roomWorkShop'+this.workshopId ,idElement,'yellow')

    } else {
      document.getElementById(idElement).style.backgroundColor = "white";
      this.reportsService.updateLightRatios('roomWorkShop'+this.workshopId ,idElement,'white')
      let miArrayGuardado  = localStorage.getItem('lightFieldsRatios');
      if (miArrayGuardado) {
        this.lightFields = JSON.parse(miArrayGuardado);
        console.log(this.lightFields); // Esto imprimirá el array recuperado desde localStorage
        if(this.lightFields.array.includes(idElement)){
          let indexToDelete = this.lightFields.array.indexOf(idElement)
          if(this.lightFields.array.length == 1){
            console.log("SOLO ES 1")
            this.lightFields.array.pop();
          }else{
            this.lightFields.array.splice(indexToDelete,1);
          }
          console.log(this.lightFields);
          localStorage.setItem('lightFieldsRatios', JSON.stringify(this.lightFields));

        }

      } else {
        console.log("El array no existe en localStorage.");
      }
    }

    console.log(this.lightFields);
  }
  initSocket() {
    this.reportsService.setupSocketConnection();
    this.reportsService._ratios
      .pipe(takeUntil(this.destroy$)).subscribe(response => {
        if (response) {
          switch (response.status) {
            case 'updateLightRadiosServer':
              console.log("RECIBI LIGHTS")
              console.log(response.data);
              if (this.blnFacilitador) {
                return;
              }
              if (response.data['color'] == 'yellow') {
                console.log("es yellow");
                let idElement = response.data['idElement']
                document.getElementById(idElement).style.backgroundColor = "yellow";
                this.lightFields.array.push(idElement)
                localStorage.setItem('lightFieldsRatios', JSON.stringify(this.lightFields));
              } else {
                console.log("es white")
                let idElement = response.data['idElement']

                document.getElementById(idElement).style.backgroundColor = "white";
                let miArrayGuardado = localStorage.getItem('lightFieldsRatios');
                if (miArrayGuardado) {
                  this.lightFields = JSON.parse(miArrayGuardado);
                  console.log(this.lightFields); // Esto imprimirá el array recuperado desde localStorage
                  if (this.lightFields.array.includes(idElement)) {
                    let indexToDelete = this.lightFields.array.indexOf(idElement)
                    if (this.lightFields.array.length == 1) {
                      console.log("SOLO ES 1")
                      this.lightFields.array.pop();
                    } else {
                      this.lightFields.array.splice(indexToDelete, 1);
                    }
                    console.log(this.lightFields);
                    localStorage.setItem('lightFieldsRatios', JSON.stringify(this.lightFields));

                  }

                } else {
                  console.log("El array no existe en localStorage.");
                }
              }
              // this.lightFields.array = response.data['array'];
              // console.log(this.lightFields);
              break;
            default:
              break;
          }
        }
      });
  }
}
