<!doctype html>
<html lang="en">

<head>
    <!-- Compiled and minified Bootstrap CSS -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
        integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">

</head>

<body>
    <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel" style="height: 100%;"
        *ngIf="jsonRules[47] != undefined">
        <ol class="carousel-indicators bgWhite">
            <li data-target="#carouselExampleCaptions" style="background-color: black;" data-slide-to="0"
                class="active"></li>
            <li data-target="#carouselExampleCaptions" style="background-color: black;" data-slide-to="1"
                aria-label="1"></li>
            <li data-target="#carouselExampleCaptions" style="background-color: black;" data-slide-to="2"
                aria-label="2"></li>
            <li data-target="#carouselExampleCaptions" style="background-color: black;" data-slide-to="3"
                aria-label="3"></li>
            <li data-target="#carouselExampleCaptions" style="background-color: black;" data-slide-to="4"
                aria-label="4"></li>
            <li data-target="#carouselExampleCaptions" style="background-color: black;" data-slide-to="5"
                aria-label="5"></li>
           <!-- <li data-target="#carouselExampleCaptions" style="background-color: black;" data-slide-to="6"
                aria-label="6"></li> -->
        </ol>

        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner" style="height: 100%;">
                <div class="carousel-item active">
                    <img src="https://www.publicdomainpictures.net/pictures/330000/velka/green-linesstripes-background-1586946257p6z.jpg"
                        style="height: 550px !important;">
                    <div class="carousel-caption" style="top: 40%;">
                        <div class="container">
                            <p style="font-size: 65px !important;" class="card-text rulesText style1 calibriFont">
                                {{fnObtenerLabel('8004')}}</p>
                        </div>
                    </div>
                </div>
                <!--fin vista 1-->

                <div class="carousel-item">
                    <img src="https://wallpapercave.com/wp/wp7120188.jpg"
                        style="height: 550px !important; opacity:0.7;">
                    <div class="carousel-caption scroll">
                        <div class="container scroll">
                            <div class="row">
                                <h1 style="font-weight: bold;" class="style12 calibriFont">{{fnObtenerLabel('8006')}}</h1>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="card"
                                        style="width: 100%;  background-color: rgba(255, 255, 255, 0.918);">
                                        <div class="card-body">
                                            <h3 class="card-title header style3 calibriFont">{{fnObtenerLabel('8007')}}
                                            </h3>
                                            <p class="card-text text style2a calibriFont">
                                                {{fnObtenerLabel('8008')}}</p>
                                            <p class="card-text text style2a calibriFont">
                                                {{fnObtenerLabel('8009')}}</p>
                                            <p class="card-text text style2a calibriFont">
                                                {{fnObtenerLabel('8010')}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="card"
                                        style="width: 100%;  background-color: rgba(255, 255, 255, 0.918); margin-top: 10px;">
                                        <div class="card-body">
                                            <h3 class="card-title header style3 calibriFont"> {{fnObtenerLabel('8011')}}
                                            </h3>
                                            <p class="card-text text style2a calibriFont">
                                                {{fnObtenerLabel('8012')}}</p>
                                            <p class="card-text text style2a calibriFont">
                                                {{fnObtenerLabel('8013')}}</p>
                                            <p class="card-text text style2a calibriFont">
                                                {{fnObtenerLabel('8014')}}</p>
                                            <p class="card-text text style2a calibriFont">
                                                {{fnObtenerLabel('8015')}}</p>
                                            <p class="card-text text style2a calibriFont">
                                                {{fnObtenerLabel('8016')}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--fin vista 2-->

                <div class="carousel-item">
                    <img src="https://wallpapercave.com/wp/wp7120188.jpg"
                        style="height: 550px !important; opacity:0.7;">
                    <div class="carousel-caption scroll">
                        <div class="container scroll">
                            <div class="row">
                                <div class="col">
                                    <div class="card"
                                        style="width: 100%;  background-color: rgba(255, 255, 255, 0.918);">
                                        <div class="card-body">
                                            <h3 class="card-title header style3 calibriFont">{{fnObtenerLabel('8017')}}
                                            </h3>
                                            <p class="card-text text style2a calibriFont">
                                                {{fnObtenerLabel('8018')}}</p>
                                            <p class="card-text text style2a calibriFont pt-1">
                                                {{fnObtenerLabel('8019')}}</p>
                                            <p class="card-text text style2a calibriFont pt-1">
                                                {{fnObtenerLabel('8020')}}</p>
                                            <p class="card-text text style2a calibriFont pt-1">
                                                {{fnObtenerLabel('8021')}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--fin vista 3-->

                <div class="carousel-item">
                    <img src="https://wallpapercave.com/wp/wp7120188.jpg"
                        style="height: 550px !important; opacity:0.7;">
                    <div class="carousel-caption scroll">
                        <div class="container scroll">
                            <div class="row">
                                <div class="col">
                                    <div class="card"
                                        style="width: 100%;  background-color: rgba(255, 255, 255, 0.918); margin-top: 5px;">
                                        <div class="card-body">
                                            <h3 class="card-title header style3 calibriFont"> {{fnObtenerLabel('8022')}}
                                            </h3>
                                            <p class="card-text text style2a calibriFont"></p>
                                            <p class="card-text text style2a calibriFont">
                                                {{fnObtenerLabel('8024')}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="data.boardType == 'Mfg EBIT'">
                                <div class="col">
                                    <div class="card"
                                        style="width: 100%; background-color: rgba(255, 255, 255, 0.918); margin-top: 5px;">
                                        <div class="card-body">
                                            <h3 class="card-title header style3 calibriFont"> {{fnObtenerLabel('8026')}}
                                            </h3>
                                            <p class="card-text text style2a calibriFont">
                                                {{fnObtenerLabel('8027')}}</p>
                                            <p class="card-text text style2a calibriFont">
                                                {{fnObtenerLabel('8028')}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="data.boardType == 'S&S EBIT'">
                                <div class="col">
                                    <div class="card"
                                        style="width: 100%; background-color: rgba(255, 255, 255, 0.918); margin-top: 5px;">
                                        <div class="card-body">
                                            <h3 class="card-title header style3 calibriFont"> {{fnObtenerLabel('8026')}}
                                            </h3>
                                            <p class="card-text text style2a calibriFont">
                                                {{fnObtenerLabel('8027.1')}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="data.boardType == 'S&S EBIT'">
                                <div class="col">
                                    <div class="card"
                                        style="width: 100%; background-color: rgba(255, 255, 255, 0.918); margin-top: 5px;">
                                        <div class="card-body">
                                            <h3 class="card-title header style3 calibriFont"> {{fnObtenerLabel('8027.2')}}
                                            </h3>
                                            <p class="card-text text style2a calibriFont pt-1">
                                                {{fnObtenerLabel('8028.1')}}</p>
                                            <p class="card-text text style2a calibriFont pt-1">
                                                {{fnObtenerLabel('8031.1')}}</p>
                                            <p class="card-text text style2a calibriFont pt-1">
                                                {{fnObtenerLabel('8032.1')}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--fin vista 4-->

                <div class="carousel-item">
                    <img src="https://wallpapercave.com/wp/wp7120188.jpg"
                        style="height: 550px !important; opacity:0.7;">
                    <div class="carousel-caption scroll">
                        <div class="container scroll">
                            <div class="row">
                                <div class="col">
                                    <div class="card"
                                        style="width: 100%;  background-color: rgba(255, 255, 255, 0.918);">
                                        <div class="card-body">
                                            <h3 class="card-title header style3 calibriFont">{{fnObtenerLabel('8030')}}
                                            </h3>
                                            <p class="card-text text style2a calibriFont">
                                                <!-- {{fnObtenerLabel('8031')}} {{fnObtenerLabel('8032')}}</p> -->
                                                {{fnObtenerLabel('8032')}}</p>
                                            <p class="card-text text style2a calibriFont pt-1">
                                                {{fnObtenerLabel('8031')}}</p>
                                            <p class="card-text text style2a calibriFont pt-1">
                                                {{fnObtenerLabel('8033')}}</p>
                                            <p class="card-text text style2a calibriFont pt-1">
                                                {{fnObtenerLabel('8034')}}</p>
                                            <p class="card-text text style2a calibriFont pt-1">
                                                {{fnObtenerLabel('8035')}}</p>
                                            <p class="card-text text style2a calibriFont pt-1">
                                                {{fnObtenerLabel('8036')}}</p>
                                            <p class="card-text text style2a calibriFont pt-1">
                                                {{fnObtenerLabel('8037')}}</p>
                                            <p class="card-text text style2a calibriFont pt-1"></p>
                                            <p class="card-text text style2a calibriFont pt-1"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--fin vista 5-->

                <div class="carousel-item">
                    <img src="https://wallpapercave.com/wp/wp7120188.jpg"
                        style="height: 550px !important; opacity:0.7;">
                    <div class="carousel-caption scroll">
                        <div class="container scroll">
                            <div class="row">
                                <div class="col">
                                    <div class="card"
                                        style="width: 100%;  background-color: rgba(255, 255, 255, 0.918);">
                                        <div class="card-body">
                                            <h3 class="card-title header style3 calibriFont">{{fnObtenerLabel('8041')}}
                                            </h3>
                                            <p class="card-text text style2a calibriFont">
                                                {{fnObtenerLabel('8042')}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="card"
                                        style="width: 100%;  background-color: rgba(255, 255, 255, 0.918);  margin-top: 15px;">
                                        <div class="card-body">
                                            <h3 class="card-title header style3 calibriFont"> {{fnObtenerLabel('8044')}}
                                            </h3>
                                            <p class="card-text text style2a calibriFont">
                                                {{fnObtenerLabel('8045')}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--fin vista 6-->
              
                <!--
                <div class="carousel-item">
                    <img src="https://wallpapercave.com/wp/wp7120188.jpg"
                        style="height: 550px !important; opacity:0.5;">
                    <div class="carousel-caption" style="top: 6%;">
                        <div class="container scroll">
                            <div class="row">
                                <div class="col">
                                    <div class="card"
                                        style="width: 100%; background-color: rgba(255, 255, 255, 0.918);">
                                        <div class="card-body">
                                            <h3 class="card-title header style3 calibriFont">{{fnObtenerLabel('8046')}}
                                            </h3>
                                            <p class="card-text text style2a calibriFont">
                                                {{fnObtenerLabel('8048')}}</p>
                                            <p class="card-text text style2a calibriFont pt-1">
                                                {{fnObtenerLabel('8050')}}</p>
                                            <p class="card-text text style2a calibriFont pt-1">
                                                {{fnObtenerLabel('8052')}}</p>
                                            <p class="card-text text style2a calibriFont pt-1">
                                                {{fnObtenerLabel('8054')}}</p>
                                            <p class="card-text text style2a calibriFont pt-1">
                                                {{fnObtenerLabel('8056')}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--fin vista 7-->
            </div>
            <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>

    <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
        integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
        crossorigin="anonymous"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"
        integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1"
        crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
        integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
        crossorigin="anonymous"></script>
</body>

</html>