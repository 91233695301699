import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { ContentObserver } from '@angular/cdk/observers';
import { element } from 'protractor';
import Swal from 'sweetalert2';
//import { BalancesheetreportComponent } from '../../views/balancesheetreport/balancesheetreport.component'; 
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  opcSelect = 0;
  currentView = 0;
  userTeam = 0;
  lstTeamsR = [];
  selectedteamR = [];
  // FinStatMessage = "Select the team you want to see their financial statements";
  FinStatMessage = this.getSingleTextLabel('5018');
  selectManage: string[] = [this.getSingleTextLabel('5016'),this.getSingleTextLabel('13'),this.getSingleTextLabel('44')];
  //@ViewChild(BalancesheetreportComponent, {static: false}) hijo : BalancesheetreportComponent;

  constructor( 
    public matDialogRef: MatDialogRef<ReportsComponent>,
      @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
      console.log('_data::::', this._data);
      console.log('this._data entrante::::', this._data.workshopId,this._data.actualTeam );
      this.userTeam = this._data['userTeam']
      if(this._data.actualTeam  != 0)
      {
        console.log("idteam diferente 0");
        this._data.teamsList.forEach(element => {
         
          if(element.TeamId == this._data.actualTeam){
           element.check = true;
            this.selectedteamR = element;
            console.log("encontramos element asignamos a modelo this.selectedteam:",this.selectedteamR)
          }else{
            element.check = false;
          }
          this.lstTeamsR.push(element);
          console.log("this.lstTeams", this.lstTeamsR);
        });
      }
      
    }

  ngOnInit(): void {
    console.log("entre ViewFinantial"+ this.userTeam);
  }

/*
  async fnSelectTeam(){
    var jsnteamSelct = this.lstTeams.filter(el=>{
      if(el.TeamId == this.selectedteam)//view board
        return el;
    });
    console.log("entra fn person",jsnteamSelct);

      //significa que el usuario es un facilitador
     
      this.matDialogRef.close({
        jsnTeamSelected: jsnteamSelct
      });
    
  }*/

  fnCancel() {
    this.matDialogRef.close({
      jsnTeamSelected: 0
    });
  }

  onTabClick(event){
    console.log(this.lstTeamsR);
    this.lstTeamsR.forEach(element => {
      if(element.check == true){
        console.log("El que tiene true es: " + element.TeamId);
        // this.radioChange(element.TeamId);
      }
    });
    // var element = <HTMLInputElement> document.getElementById("radio");
    // if (element.checked) {
    //   console.log("si");
    //   console.log(element.value);
    // }
    console.log("event.::", event.index);
    if(event.index == 0 || event.index ==2){
        this.opcSelect = event.index;
    }else{
      this.currentView = event.index;
    }
    
    //this.opcSelect = event.index;
  }

  radioChange(event)
  {
    this.opcSelect =7;
    console.log("event radio change:: ", event.value," actual: this._data - ", this._data);
    //Actualizamos el id del team al seleccionado
    //existe la funcion getOrders() que usa el servicio this.boardService.getOrdersByTeamId(this.teamId, this.status == 0 ? this.currentMonth - 1 : this.currentMonth).subscribe(response => {
      //que llena el array de ordenes, podemos usar ese para obtener las ordenes de una team??
      //aqui necesitamos tambien actualizar orders para sacar el totSales y actualizarlo para sacar correctamente el reporte del team
    this._data.teamId = event.value;
    console.log("nueva: this._data - ", this._data);
    setTimeout(() => {
      this.opcSelect = this.currentView;
    }, 1000);

    
/*data: {
            languageId: this.idLenguage,
            teamId: this.teamId,
            currentMonth: this.currentMonth,
            cycles: this.Cycles,
            workshopId: this.workshopId,
            canEdit: false,
            arrTextLabels: this.arrTextLabels,

            teamsList: lstTeams,
            actualTeam: this.teamId,

            totSales: totSales,
              control: this.isOpenMarketDisabledBoard,

          }*/
    //this.hijo.fnHija();

  }
/*
  fnchargedata(){
    console.log("vamos a cargar con lo nuevos datos:",this.currentView);
    this.opcSelect = this.currentView;
  }*/

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }

  get control(): boolean {
    return this._data.accessResults;
  }
}
