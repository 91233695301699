import { Component, OnInit, Inject } from '@angular/core';
import { BalanceSheetService } from '../../services/balance-sheet/balance.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BalanceIncomeService } from '../../services/sockets/balance-income/balance-income.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'
import { IncomeStatementService } from '../../services/IncomeStatement/IncomeStatement.service';
import { BoardService } from './../../services/board/board.service';
import { ReportsService } from 'src/app/services/reports/reports.service';
import { ImprovementService } from 'src/app/services/improvement/improvement.service';
import { txtArea } from '../result-ratios/result-ratios.component';

export interface select {
  idtextAreaReports: number;
  text: string;
  typeReport: number;
}
export interface LightField {
  wsID : number,
  array : string[]
}
@Component({
  selector: 'app-result-view',
  templateUrl: './result-view.component.html',
  styleUrls: ['./result-view.component.scss']
})

export class ResultViewComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  lightFields : LightField = {wsID: 0, array: []};
  lstTeams = [];
  lstResults = [];
  cycles = 4;
  optionSelected = '';
  typeReport = -1;
  auxArray = [];
  workshopId = 0;

  lstTabletotalsales = [];
  blnShowtable = false;
  blnFacilitador = false;
  elements1erImprovement = [];
  elements2doImprovement = [];
  //OptionMonthSelect=0;
  blnShow2Improvement = false;
  lstTextArea: Array<Array<txtArea>> = [];
  lstSelects: Array<select> = [];
  monthSelected = -1;

  constructor(private incomeService: IncomeStatementService,
    private balanceService: BalanceSheetService,
    private boardService: BoardService,
    private improvementService: ImprovementService,
    public matDialogRef: MatDialogRef<ResultViewComponent>,
    private reportsService: ReportsService,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {

    this.workshopId = parseInt(this._data.workshopId);

  }

  ngOnInit(): void {
    console.log('this._data entrante a VISTA RESULTADOS this._data::::', this._data);
    this.blnFacilitador = this._data.blnFacilitador;
    this.lstTeams = [...this._data.teamsList];
    console.log(this.lstTeams);
    if(this.blnFacilitador){
      this.lstTeams.splice(0,1);
      console.log(this.lstTeams);
    }
    this.initSocket();
    this.initTextAreas();
    for (let i = 0; i < this.lstTeams.length; i++) {
      this.lstSelects.push({
        idtextAreaReports: null,
        text: '',
        typeReport: 10,
      });
    }
    //this.lstTeams.unshift({Avatar: "         ",    Color: "white"})
    //this.fnObtenerDatos();
    this.cycles = this._data.cycles;
    for (let i = 0; i < this.cycles; i++) {
      this.auxArray.push(i + 1);
    }
    this.reportsService.getTextAreaByWorkshopIdAndType(this.workshopId, 10).subscribe((response: any) => {
      if (response.intResponse == 200) {
        for (let i = 0; i < 12; i++) {
          if (response.info[i]) {
            this.lstSelects[i] = response.info[i];
          }
        }
      }
    });
    this.lightFields.wsID = this.workshopId
    console.log(this.lightFields.wsID ,this.workshopId)
  }

  initTextAreas() {
    this.lstTextArea = [];
    for (let i = 0; i < 2; i++) {
      this.lstTextArea.push(new Array<txtArea>());
      for (let j = 0; j < (this.lstTeams.length + 1); j++) {
        this.lstTextArea[i].push({
          idtextAreaReports: null,
          text: '',
          typeReport: -1,
        });
      }
    }
  }

  fnValidateShow2Improv(mesActual) {
    this.blnShow2Improvement = false;
    if (this.cycles == 4 && mesActual == 4) {
      this.blnShow2Improvement = true;
    } else if ((this.cycles == 5 || this.cycles == 6) && (mesActual == 5 || mesActual == 6)) {
      this.blnShow2Improvement = true;

    }
  }
  getLight(){
    let miArrayGuardado  = localStorage.getItem('lightFields'+this.optionSelected);
    if (miArrayGuardado) {
      var aux = JSON.parse(miArrayGuardado);
      if(aux.wsID == this.workshopId){
        this.lightFields = aux;
        console.log(this.lightFields); 
        setTimeout(() => {
          this.lightFields.array.forEach(element => {
            document.getElementById(element).style.backgroundColor = "yellow";
          });
        }, 500);
      }else{
        this.lightFields.array = [];
        localStorage.setItem('lightFields'+this.optionSelected, JSON.stringify(this.lightFields));
      }
    } else {
      this.lightFields.array = [];
      console.log("El array no existe en localStorage.");
    }
  }
  changeOption(event) {
    this.getLight();
    this.blnShowtable = false;
    console.log("opselected:", this.optionSelected);
    console.log(event);
    var MesSelect = 0;
    //this.OptionMonthSelect = 0;
    switch (this.optionSelected) {
      case "month6": {
        MesSelect = 6;
        this.fnValidateShow2Improv(6);
        break;
      }
      case "month4": {
        MesSelect = 4;
        this.fnValidateShow2Improv(4);
        break;
      }
      case "month3": {
        MesSelect = 3;
        this.fnValidateShow2Improv(3);
        break;
      }
      case "month2": {
        MesSelect = 2;
        this.fnValidateShow2Improv(2);
        break;
      }
      case "month1": {
        MesSelect = 1;
        this.fnValidateShow2Improv(1);
        break;
      }
      case "month5": {
        MesSelect = 5;
        this.fnValidateShow2Improv(5);
        break;
      }
      default: {
        MesSelect = 0;
        this.fnValidateShow2Improv(0);
        break;
      }
    }
    if (this.optionSelected != "ratio") {
      this.getTextAreas(MesSelect == 0 ? -1 : MesSelect);
      this.typeReport = MesSelect == 0 ? -1 : MesSelect;
    }
    this.monthSelected = MesSelect;
    this.fnObtenerDatos(this._data.workshopId, MesSelect);

  }

  fnObtenerDatos(workshopId, MesSelect) {
    this.lstTabletotalsales = [];
    console.log("datos a enviar getdata:", workshopId, MesSelect);
    var lstTablesrc = [];
    var lstordenesdata = [];
    this.boardService.getOrdersByWsandMonth(workshopId, MesSelect /*this._data.currentMonth*/).subscribe(responseOrders => {
      console.log("respuestaOrders::", responseOrders.orders)
      lstordenesdata = responseOrders.orders;
      var lstincomestatement = [];
      this.incomeService.getCantidadesLlenadobyMonth(workshopId, MesSelect).subscribe((responseincome) => {
        console.log("respgetcantidadesllenado", responseincome);
        lstincomestatement = responseincome.data;
        console.log("response data lstTempxd::", lstincomestatement);
        /* var lstTmpTeams = this.lstTeams;
              lstTmpTeams.shift()*/

        this.balanceService.getCantidadesLlenadobyMonth(workshopId, MesSelect).subscribe((responsebalance) => {
          var lstbalance = [];
          lstbalance = responsebalance.data;
          console.log("response data lstbalance::", lstbalance);

          this.balanceService.getBidsbyMonth(workshopId, MesSelect).subscribe(async (responseBids) => {
            var lstBids = [];
            lstBids = responseBids.data

            var lstimprovements = [];
            if (MesSelect >= 3) {
              let responseImprovements = await this.improvementService.getAllmonthImprovementsByWorkShopId(workshopId).toPromise();
              console.log("fnGetImprovementsByWorlkshopId", responseImprovements);

              lstimprovements = responseImprovements.improvements;
              const x = await this.fncreatestrctureData(lstincomestatement, lstordenesdata, lstbalance, lstBids, lstimprovements);


            }
            else {
              const x = await this.fncreatestrctureData(lstincomestatement, lstordenesdata, lstbalance, lstBids, lstimprovements);
            }
            /* if(MesSelect > 3){

             }*/

            console.log(this.elements1erImprovement,this.elements2doImprovement);




            console.log("this.lstTabletotalsales final:", this.lstTabletotalsales);
            this.blnShowtable = true;
          });


        });

      });







    });



  }



  fnClickSelect(rowrec, index) {
    if(!this.blnFacilitador){
      return;
    }
    console.log("fnclick idrecibido:", rowrec, index, this.lstTabletotalsales);
    var idElement = rowrec + index;
    var colorActual = document.getElementById(idElement).style.backgroundColor;
    if (colorActual == "white") {
      document.getElementById(idElement).style.backgroundColor = "yellow";
      this.lightFields.array.push(idElement)
      localStorage.setItem('lightFields'+this.optionSelected, JSON.stringify(this.lightFields));
      this.reportsService.updateLights('roomWorkShop'+this.workshopId ,idElement,'yellow');
    } else {
      document.getElementById(idElement).style.backgroundColor = "white";
      this.reportsService.updateLights('roomWorkShop'+this.workshopId ,idElement,'white');
      let miArrayGuardado  = localStorage.getItem('lightFields'+this.optionSelected);
      if (miArrayGuardado) {
        this.lightFields = JSON.parse(miArrayGuardado);
        console.log(this.lightFields); // Esto imprimirá el array recuperado desde localStorage
        if(this.lightFields.array.includes(idElement)){
          let indexToDelete = this.lightFields.array.indexOf(idElement)
          if(this.lightFields.array.length == 1){
            console.log("SOLO ES 1")
            this.lightFields.array.pop();
          }else{
            //console.log(this.lightFields.splice(indexToDelete,1));
            //console.log(this.lightFields);
            //console.log(indexToDelete);
            // this.lightFields.splice(indexToDelete,1)
            this.lightFields.array.splice(indexToDelete,1);
          }
          console.log(this.lightFields);
          localStorage.setItem('lightFields'+this.optionSelected, JSON.stringify(this.lightFields));

        }

      } else {
        console.log("El array no existe en localStorage.");
      }
      
      
    }


  }

  fnGetImprovement(option: number) {
    let strOption: string = "";
    switch (option) {
      case 1:
        strOption = this.getSingleTextLabel('90');//"Raw Materials"
        break;
      case 2:
        strOption = this.getSingleTextLabel('91');//"Efficiency"
        break;
      case 3:
        strOption = this.getSingleTextLabel('92')//"Customizing"
        break;
      case 4:
        strOption = this.getSingleTextLabel('93');//"Quality"
        break;
      case 5:
        strOption = this.getSingleTextLabel('94');//"Expansion"
        break;
      case 6:
        strOption = this.getSingleTextLabel('8067');//"No Improvement"
        break;
    }
    return strOption;
  }


  fncreatestrctureData(lstincomestatement, lstordenesdata, lstbalance, lstBids, lstimprovements) {
    return new Promise(async (resolve, reject) => {
      let x: any;
      this.elements1erImprovement = [];
      this.elements2doImprovement = [];
      console.log(lstincomestatement, lstordenesdata, lstbalance, lstBids, lstimprovements,this.lstTeams)
      for(let i = 0; i<this.lstTeams.length; i++){
        x = await this.chargeDataIntoArray(lstincomestatement, lstordenesdata, lstbalance, lstBids, lstimprovements, this.lstTeams[i]);
        this.lstTabletotalsales.push(x);
      }
      console.log(this.lstTabletotalsales)
      resolve(true);
    });

  }

  chargeDataIntoArray(lstincomestatement, lstordenesdata, lstbalance, lstBids, lstimprovements, element) {
    return new Promise(async (resolve, reject) => {
      var tmpelementIS = lstincomestatement.filter(x => x.teamId === element.TeamId);
      var tmpelementOrders = lstordenesdata.filter(x => x.TeamId === element.TeamId);
      var varTotalsalesquantity = 0
      tmpelementOrders.forEach(x => {
        varTotalsalesquantity += x.Quantity;
      });

      var tmpelementBS = lstbalance.filter(x => x.teamId === element.TeamId);
      // se sustituyo por la llamada a la api "getAdvertisingBill"
      // var tmpelementsBids = lstBids.filter(x=>x.TeamId === element.TeamId);
      // console.log("tmpelementsBids:",tmpelementsBids);

      if (lstimprovements.length > 0) {
        var lsttmpImp1 = lstimprovements.filter(x => x.TeamId === element.TeamId && x.Month === 3);
        if (lsttmpImp1.length > 0) {
          this.elements1erImprovement.push({
            TeamId: lsttmpImp1[0].TeamId, improvement: this.fnGetImprovement(lsttmpImp1[0].Option)
          })
        }else{
          if(lsttmpImp1.length == 0){
            this.elements1erImprovement.push({
              improvement: ''
            })
          }
        }
        var lsttmpImp2 = lstimprovements.filter(x => x.TeamId === element.TeamId && x.Month > 3);
        if (lsttmpImp2.length > 0) {
          this.elements2doImprovement.push({
            TeamId: lsttmpImp2[0].TeamId, improvement: this.fnGetImprovement(lsttmpImp2[0].Option)
          })
        }else{
          if(lsttmpImp2.length == 0){
            this.elements2doImprovement.push({
              improvement: ''
            })
          }
        }

      }


      //var averagetmp = (tmpelementIS[0].total / varTotalsalesquantity).toFixed(2);
      var averagetmp = Math.round((tmpelementIS[0].total / varTotalsalesquantity) * 100) / 100;
      // Math.round(num * 100) / 100

      // responseAdvertising = await this.boardService.getAdvertisingBill(element.TeamId, this.monthSelected).toPromise();
      
      let bids = lstBids.filter(x => x.TeamId === element.TeamId)
      console.log(bids)

      let Advertising = bids.length;

      resolve({
        totalsales: tmpelementIS[0].total,
        totalquantity: varTotalsalesquantity,
        averageprice: averagetmp.toString() == "NaN" ? 0 : averagetmp,
        netIncome: tmpelementIS[0].netIncome,
        advertising: Advertising,
        retained: tmpelementBS[0].retained,
        color_netIncome: tmpelementIS[0].netIncome >= 0 ? "black" : "red",
        color_retained: tmpelementBS[0].retained >= 0 ? "black" : "red"
      });

    });
  }

  changeTextArea() {
    let arrayAux = [];
    this.lstTextArea.forEach(item => {
      item.forEach(element => {
        element.typeReport = this.typeReport;
        arrayAux.push(element);
      });
    });
    this.reportsService.createUpdTextArea(this.workshopId, arrayAux).subscribe(response => {
      if (response.intResponse == 200) {
        console.log(response);
        if (response.newLst.length != 0) {
          let colums = response.newLst.length / 2;
          for (let i = 0; i < 2; i++) {
            for (let j = 0; j < colums; j++) {
              this.lstTextArea[i][j].idtextAreaReports = response.newLst[colums * i + j].idtextAreaReports;
            }
          }
        }
      }
    })
  }

  changeSelect() {
    console.log(this.lstSelects)
    this.reportsService.createUpdSelect(this.workshopId, this.lstSelects).subscribe(response => {
      if (response.intResponse == 200) {
        console.log(response);
        this.lstSelects = [...response.newLst]
        console.log(this.lstSelects)

      }
    })
  }

  getTextAreas(month: number) {
    this.reportsService.getTextAreaByWorkshopIdAndType(this.workshopId, month).subscribe((response: any) => {
      if (response.intResponse == 200) {
        if (response.info.length != 0) {
          this.convertArrayToMatriz(response.info);
        } else {
          this.initTextAreas();
        }
      }
    });
  }

  convertArrayToMatriz(array: Array<txtArea>) {
    this.lstTextArea = [];
    let colums = array.length / 2;
    for (let i = 0; i < 2; i++) {
      this.lstTextArea.push(new Array<txtArea>());
      for (let j = 0; j < colums; j++) {
        this.lstTextArea[i].push(array[colums * i + j]);
      }
    }
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }

  initSocket(){
    this.reportsService.setupSocketConnection();
    this.reportsService._report
    .pipe(takeUntil(this.destroy$)).subscribe(response => {
      if(response){
        switch (response.status) {
          case 'updateTxtAreas':
              console.log("RECIBI MENSAJES");
              console.log(response.data.newLst)
              console.log(this.lstTextArea)
              this.convertArrayToMatriz(response.data.newLst)
            break;
            case 'updateSelected':
              console.log("RECIBI SELECTEDs");
              console.log(response.data.newLst)
              console.log(this.lstSelects)
              this.lstSelects = [...response.data.newLst]
            break;
            case 'updateLightsServer':
              console.log("RECIBI LIGHTS")
                console.log(response.data);
                if(this.blnFacilitador){
                  return;
                }
                if(response.data['color'] == 'yellow'){
                  console.log("es yellow")
                  let idElement = response.data['idElement']
                  document.getElementById(idElement).style.backgroundColor = "yellow";
                  this.lightFields.array.push(idElement)
                  localStorage.setItem('lightFields'+this.optionSelected, JSON.stringify(this.lightFields));
                }else{
                  console.log("es white")
                  let idElement = response.data['idElement']
                  document.getElementById(idElement).style.backgroundColor = "white";
                  let miArrayGuardado  = localStorage.getItem('lightFields'+this.optionSelected);
                        if (miArrayGuardado) {
                          this.lightFields = JSON.parse(miArrayGuardado);
                          if(this.lightFields.array.includes(idElement)){
                            let indexToDelete = this.lightFields.array.indexOf(idElement)
                            if(this.lightFields.array.length == 1){
                              console.log("SOLO ES 1")
                            }else{

                              this.lightFields.array.splice(indexToDelete,1);
                            }
                            localStorage.setItem('lightFields'+this.optionSelected, JSON.stringify(this.lightFields));
                  
                          }
                  
                        } else {
                          console.log("El array no existe en localStorage.");
                        }
                }
                // this.lightFields.array = response.data['array'];
                // console.log(this.lightFields);
              break;
          default:
            break;
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.reportsService.closeListener();
  }
}
