import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BoardService } from 'src/app/services/board/board.service';
import Swal from 'sweetalert2';



class Piece {
  StatusBoardId: number;
  TeamId: number;
  AreaDrag: number;
  strAreaDrag: string;
  Valor: number;
  tipo: number;

  constructor(object:{AreaDrag: number, Valor: number, tipo: number, TeamId: number, StatusBoardId: number}){
    this.AreaDrag = object['AreaDrag'];
    this.strAreaDrag = AREASDRAG[object['AreaDrag']-1];
    this.Valor = object['Valor'];
    this.tipo = object['tipo'];
    this.TeamId = object['TeamId'];
    this.StatusBoardId = object['StatusBoardId'];
  }
}

class CheckBox{
  CheckListId: number;
  idCheck: number;
  valor: number;
  TeamId: number;
  title: string;

  constructor(object:{CheckListId: number, idCheck: number, valor: number, TeamId: number,title: string}){
    this.CheckListId = object['CheckListId'];
    this.idCheck = object['idCheck'];
    this.valor = object['valor'];
    this.TeamId = object['TeamId'];
    this.title = object['title'];
  }
}

class CheckList{
  checkList: Array<CheckBox> = [];

  constructor(json:[]){
    json.forEach(item => {
      this.checkList.push(new CheckBox(item));
    })
  }
  codeCheckList(){
    return JSON.stringify(this.checkList);
  }
}

export class TeamBoard{
  board: Array<Piece> = [];
  boardTemp: Array<Piece> = [];
  lstPiecesModified: Array<Piece> = [];
  lstPiecesAdded: Array<Piece> = [];
  lstPiecesDeleted: Array<Piece> = [];
  checkList: CheckList;
  Status: number;
  Month: number;
  Avatar: string;
  Color: string;
  Mode: number;
  Room: string;
  TeamId: number;
  WokshopId: number;

  constructor(json: any){
    json['board'].forEach(piece => {
      this.board.push(new Piece(piece));
    });
    this.boardTemp = [...this.board];
    this.lstPiecesDeleted = [];
    this.lstPiecesAdded = [];
    this.lstPiecesModified = [];
    this.checkList = new CheckList(json['checkList']);
    this.Status = json['Status'];
    this.Month = json['Month'];
    this.Avatar = json['Avatar'];
    this.Color = json['Color'];
    this.Mode = json['Mode'];
    this.Room = json['Room'];
    this.TeamId = json['TeamId'];
    this.WokshopId = json['WokshopId'];
  }

  getUpdates = ()=>{
    let json = {
      'lstPiecesAdded': this.lstPiecesAdded,
      'lstPiecesModified': this.lstPiecesModified,
      'lstPiecesDeleted': this.lstPiecesDeleted,
      'Status': this.Status,
      'Month': this.Month,
      // 'checkList': this.checkList
    }
    return JSON.stringify(json);
  }
}

@Component({
  selector: 'app-pieces-teams-board',
  templateUrl: './pieces-teams-board.component.html',
  styleUrls: ['./pieces-teams-board.component.scss']
})
export class PiecesTeamsBoardComponent implements OnInit {
  AREASDRAG = [
    'Trash Cash',
    'Bank',
    'CapEx' ,
    'Labor Pool',
    'Suppliers',
    'Raw Materials', 
    'Machine 1 (CapEx)',
    'Machine 1 (People)',
    'Machine 1 (WIP)',
    'Land and buildings',
    'FGI',
    'Loans 90 days',
    'Loans 60 days',
    'Loans 30 days',
    'Receivables 90 days',
    'Receivables 60 days',
    'Receivables 30 days',
    'Capital Stock',
    'Retained Earnings',
    'Machine Overhead',
    'Depreciation',
    'Change and Innovation',
    'Sales and Marketing',
    'Advertising',
    'Administration',
    'Admin overhead',
    'Recruitment and Training',
    'Rent',
    'Interest',
    'Cash',
    'COGS',
    'Other',
    '',
    'Income Before Taxes',
    'Net Income',
    'Taxes',
    'Payables 60 days',
    'Payables 30 days',
    '',
    'Trash Capital Stock',
    'Customizing Machine',
    '',
    '',
    '',
    '',
    '',
    'Machine 2 (CapEx)',
    'Machine 2 (Peple)',
    'Machine (WIP)'
  ]
  TYPES = [
    'Cash',
    'Captial Stock',
    'Loans',
    'CapEx',
    'Machine',
    'Acuman',
    'Materials blue',
    'royals 3+3',
    'Cash & Loans',
    '',
    '',
    'Acuman yellow',
    '',
    'Materials green',
    '',
    'royal 1+3',
    'royal 3+2',
    'royal 1+2',
  ]
  workshopId: number;
  lstTeamsBoard: Array<TeamBoard> = [];
  formPiece: FormGroup = new FormGroup({
    tipo: new FormControl('',[Validators.required]),
    AreaDrag: new FormControl('',[Validators.required]),
    Valor: new FormControl('',[Validators.required, Validators.max(20)]),
  });
  valueInputSerch: string;

  constructor(
    private spinner: NgxSpinnerService,
    private boardService: BoardService,
    @Inject(MAT_DIALOG_DATA) private data: any,

  ) { }

  ngOnInit(): void {
    this.workshopId = parseInt(this.data.workshopId);
    this.spinner.show();
    this.boardService.getBoardsInfByWorkshopId(this.workshopId).subscribe((response: any) => {
      if(response.intResponse == 200){
        response.data.board.forEach(team => {
          let aux = new TeamBoard(team);
          this.lstTeamsBoard.push(aux);
        });
        this.spinner.hide();
      }
    })
  }

  fnChangePieceAreaDrag(indexTeam: number, indexPiece: number, event: number, piece: Piece){
    piece.AreaDrag = event;
    this.addPieceToTeamLstModfied(indexTeam, indexPiece, piece);
  }

  fnChangePieceType(indexTeam: number, indexPiece: number, event: number , piece: Piece){
    piece.tipo = event;
    this.addPieceToTeamLstModfied(indexTeam, indexPiece, piece);
  }

  addPieceToTeamLstModfied(indexTeam: number, indexPiece: number, piece: Piece){
    if(piece.StatusBoardId != -1){
      // existe pieza en bd
      let indexPieceModified = this.lstTeamsBoard[indexTeam].lstPiecesModified.indexOf(piece);
      if(indexPieceModified != -1){
        this.lstTeamsBoard[indexTeam].lstPiecesModified[indexPieceModified] = piece;
      }else{
        this.lstTeamsBoard[indexTeam].lstPiecesModified.push(piece);
      }
    } else {
      // no existe pieza en bd
      let indexPieceModified = this.lstTeamsBoard[indexTeam].lstPiecesAdded.indexOf(piece);
      if(indexPieceModified != -1){
        this.lstTeamsBoard[indexTeam].lstPiecesAdded[indexPieceModified] = piece;
      }else{
        this.lstTeamsBoard[indexTeam].lstPiecesAdded.push(piece);
      }
    }
  }

  addPieceToBoard(indexTeam: number){
    let aux = this.formPiece.value;
    aux['TeamId']=this.lstTeamsBoard[indexTeam].TeamId;
    aux['StatusBoardId'] = -1;
    let piece = new Piece(aux);
    this.lstTeamsBoard[indexTeam].lstPiecesAdded.push(piece);
    this.lstTeamsBoard[indexTeam].board.unshift(piece);
    this.formPiece.reset();
  }

  deletePiece(indexTeam: number, indexPiece: number, piece: Piece){
    if(piece.StatusBoardId == -1){
      this.lstTeamsBoard[indexTeam].board.splice(indexPiece,1);
      let indexPieceAdded = this.lstTeamsBoard[indexTeam].lstPiecesAdded.indexOf(piece);
      this.lstTeamsBoard[indexTeam].lstPiecesAdded.splice(indexPieceAdded,1);
    } else {
      this.lstTeamsBoard[indexTeam].lstPiecesDeleted.push(piece);
      this.lstTeamsBoard[indexTeam].board.splice(indexPiece, 1);
    }
    
  }

  mandarTeam(){
    console.log(this.lstTeamsBoard);
    console.log(JSON.stringify(this.lstTeamsBoard));
    this.lstTeamsBoard.forEach((item: TeamBoard) => {
      console.log(item.getUpdates());
    })
  }

  serchArea(indexTeam: number){
    let team: TeamBoard = this.lstTeamsBoard[indexTeam];
    if(this.valueInputSerch == ''){
      team.board  = [...team.boardTemp];
      return;
    }
    const strSearchBoxRegex = new RegExp(`/*${this.valueInputSerch}`, 'i');
    team.board = team.boardTemp.filter(
      (jsnItem) =>
        strSearchBoxRegex.test(jsnItem.strAreaDrag)
    );

  }

  modifyPieceSendBD(indexTeam: number, indexPiece: number, piece: Piece){
    console.log("mandar piece actualizar", piece);
    if(!piece.Valor || !piece.AreaDrag || !piece.tipo){
      Swal.fire({icon:'error', title:'Please enter all data'});
      return;
    }
    if(piece.Valor > 20){
      Swal.fire({icon:'error', title:'Value must be less than 20 '});
      return;
    }
    if(!this.isValidPiece(piece)){
      Swal.fire({icon:'error', title:'Something is wrong with the value of the piece'});
      return;
    }
    this.boardService.updatePiece(piece).subscribe(response => {
      if(response.intResponse == 200){
        Swal.fire({icon: 'success', title:"Update successfully"});
      } else {
        Swal.fire({icon:'error', title:'Something wrong'});
      }
    });
  }

  addPieceSendBD(indexTeam: number){
    console.log(this.formPiece.value);
    if(this.formPiece.invalid){
      Swal.fire({icon:'error', title:'Please enter all data'});
      return;
    }
    let team: TeamBoard = this.lstTeamsBoard[indexTeam];
    let aux = this.formPiece.value;
    aux['TeamId']=team.TeamId;
    aux['StatusBoardId'] = -1;
    let piece = new Piece(aux);
    if(!this.isValidPiece(piece)){
      Swal.fire({icon:'error', title:'Something is wrong with the value of the piece'});
      return;
    }
    console.log("mandar piece agregar", piece);
    this.boardService.createCoin(piece).subscribe(response => {
      if(response.intResponse == 200){
        Swal.fire({icon: 'success', title:"Update successfully"});
        piece.StatusBoardId = response.newCoin.id
        team.board.unshift(piece);
        team.boardTemp.unshift(piece);
      } else {
        Swal.fire({icon:'error', title:'Something wrong'});
      }
    })

  }

  deletePieceSendBD(indexTeam: number, indexPiece: number, piece: Piece){
    let team: TeamBoard = this.lstTeamsBoard[indexTeam];
    this.boardService.deletePiece(piece).subscribe(response => {
      if(response.intResponse == 200){
        Swal.fire({icon: 'success', title:"Update successfully"});
        team.board.splice(indexPiece,1);
      } else {
        Swal.fire({icon:'error', title:'Something wrong'});
      }
    });
  }

  sendMonthAndStatus(indexTeam: number){
    let team: TeamBoard = this.lstTeamsBoard[indexTeam];
    console.log("mandar mes y estado",team.Status, team.Month);
    if(!team.Status || !team.Month){
      Swal.fire({icon:'error', title:'Please enter all data'});
      return;
    }
    this.boardService.updateMonthAndStatus(team.TeamId, team.Month, team.Status).subscribe(response => {
      if(response.intResponse == 200){
        Swal.fire({icon: 'success', title:"Update successfully"});
      } else {
        Swal.fire({icon:'error', title:'Something wrong'});
      }
    });
  }

  isValidPiece(piece: Piece): boolean{
    let isValid = false;
    switch(piece.tipo){
      case 1:
      case 2:
      case 3:
      case 4:
        isValid = piece.Valor <= 20;
        break;
      case 5: 
        isValid = piece.Valor == 1;
        break;
      case 6:
        isValid = piece.Valor == 1 || piece.Valor == 12;
        break;
      case 7:
        isValid = piece.Valor % 3 == 0 && piece.Valor <= 30;
        break;
      case 8:
        isValid = piece.Valor % 6 == 0 && piece.Valor <= 60;
        piece.Valor = isValid ? piece.Valor/2:piece.Valor;
        break;
      case 9:
        isValid = piece.Valor == 20;
        break;
      case 12:
        isValid = piece.Valor == 1;
        break;
      case 14:
        isValid = (piece.Valor == 1 || piece.Valor == 4 || piece.Valor == 6 )&& piece.Valor <= 20;
        break;
      case 16:
        isValid = piece.Valor % 4 == 0 && piece.Valor <= 40;
        break;
      case 17:
        isValid = piece.Valor % 5 == 0 && piece.Valor <= 50;
        break;
      case 18:
        isValid = piece.Valor % 3 == 0 && piece.Valor <= 30;
        break;
    }
    return isValid;
  }

  sendCheckList(indexTeam: number){
    let team: TeamBoard = this.lstTeamsBoard[indexTeam];
    console.log("mandar mes y estado",team.checkList);
    this.boardService.updateCheckList(team.TeamId, team.checkList).subscribe(response => {
      if(response.intResponse == 200){
        Swal.fire({icon: 'success', title:"Update successfully"});
      } else {
        Swal.fire({icon:'error', title:'Something wrong'});
      }
    });
  }


}


const AREASDRAG = [
  'Trash Cash',
  'Bank',
  'CapEx' ,
  'Labor Pool',
  'Suppliers',
  'Raw Materials', 
  'Machine 1 (CapEx)',
  'Machine 1 (People)',
  'Machine 1 (WIP)',
  'Land and buildings',
  'FGI',
  'Loans 90 days',
  'Loans 60 days',
  'Loans 30 days',
  'Receivables 90 days',
  'Receivables 60 days',
  'Receivables 30 days',
  'Capital Stock',
  'Retained Earnings',
  'Machine Overhead',
  'Depreciation',
  'Change and Inovation',
  'Sales and Marketing',
  'Advertasing',
  'Administration',
  'Admin overhead',
  'Recruitment and Training',
  'Rent',
  'Interest',
  'Cash',
  'COGS',
  'Other',
  '',
  'Income Before Taxes',
  'Net Income',
  'Taxes',
  'Payables60',
  'Payables30',
  '',
  'Trash Capital Stock',
  '',
  '',
  '',
  '',
  '',
  '',
  'Machine 2 (CapEx)',
  'Machine 2 (Peple)',
  'Machine (WIP)'
]
const TYPES = [
  'Cash',
  'Captial Stock',
  'Loans',
  'CapEx',
  'Machine',
  'Acuman',
  'Materials blue',
  'royals 3+3',
  'Cash & Loans',
  '',
  '',
  'Acuman yellow',
  '',
  'Materials green',
  '',
  'royal 1+3',
  'royal 3+2',
  'royal 1+2',
]