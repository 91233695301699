<div class="container mt-3">
    <div class="row">
        <div class="col text-center">
            <label class="FontMontserratTitles">Languages</label>
        </div>
    </div>
    <div class="row">
        <div class="col text-right">
            <button class="btn btn-outline-success" [routerLink]="['/new-language']" routerLinkActive="router-link-active" style="Background-color:#6FB45C;border-radius: 50px;color: white;font-family: 'Montserrat';">
                <i class="fas fa-plus-circle fa-lg"  title="Clic to create new Language" ></i>
                <span >&nbsp;&nbsp;Create&nbsp;&nbsp;</span>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-2 col-md-2 col-lg-2"></div>
        <div class="col-sm-8 col-md-8 col-lg-8">
            <label  class="FontMontserratText" >To change a predetermined label, please select the language you want to work with</label>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2"></div>
    </div>
    <div class="row">
        <!-- <div class="col-sm-3 col-md-3 col-lg-3">
            <label for="lstLang" class="FontMontserratText" style="color: #334d6e;">{{lblTitleAndromeda}}</label>
                <br>
            <ng-multiselect-dropdown [settings]="dropDownSettingsLng" class="ddlSelect" [(ngModel)]="languageSeleccionado" [data]="lstLanguages"
            name="lstLang"  [placeholder]="lblplaceholderlstlanguages" (onSelect)="fnChangeLanguage($event)" (onDeSelect)="fnDeselectLang()" [disabled]="DistSeleccionado.length > 0"></ng-multiselect-dropdown>
            
        </div> -->
        <!-- PRIMERA COLUMNA (LENGUAJES INTERNACIONALES) -->
        <div class="col-sm-3 col-md-3 col-lg-3">     
            <label for="lstLang" class="FontMontserratText" style="color: #334d6e;">{{lblTitleAndromeda}}</label>
                <br>
            <ng-multiselect-dropdown [settings]="dropDownSettingsLng" class="ddlSelect" [(ngModel)]="languageSeleccionado" [data]="lstLanguagesInternationalAux"
            name="lstLang"  [placeholder]="lblplaceholderlstlanguages" (onSelect)="fnChangeLanguage($event)" (onDeSelect)="fnDeselectLang()" [disabled]="DistSeleccionado.length > 0 || languageSeleccionadoPrivate.length > 0"></ng-multiselect-dropdown>
            
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3">     
            <label for="lstLangPrivate" class="FontMontserratText" style="color: #334d6e;">Private Languages</label>
                <br>
            <ng-multiselect-dropdown [settings]="dropDownSettingsLngPrivate" class="ddlSelect" [(ngModel)]="languageSeleccionadoPrivate" [data]="lstLanguagesPrivateAux"
            name="lstLangPrivate"  [placeholder]="lblplaceholderlstlanguages" (onSelect)="fnChangeLanguagePrivate($event)" (onDeSelect)="fnDeselectLang()" [disabled]="DistSeleccionado.length > 0 || languageSeleccionado.length > 0"></ng-multiselect-dropdown>
            
        </div>
        <div *ngIf="blnisAdminUser && !blnisfacilitator" class="col-sm-3 col-md-3 col-lg-3">
            <label for="lstDistribs" class="FontMontserratText" style="color: #334d6e;">{{lblDistributors}}</label>
                <br>
            <ng-multiselect-dropdown [settings]="dropDownSettingsDist" class="ddlSelect" [(ngModel)]="DistSeleccionado"  [data]="lstDistributors"
            name="lstDistribs"  [placeholder]="lblplaceholderlstOrganization" (onSelect)="fnSelectDistributor($event)" [disabled]="languageSeleccionado.length > 0"></ng-multiselect-dropdown>
            
        </div>
        <div *ngIf="blnisAdminUser && lstLanguagesOrganization.length > 0 && !blnisfacilitator" class="col-sm-3 col-md-3 col-lg-3" >
            <label for="lstLangOrg" class="FontMontserratText" style="color: #334d6e;">{{lblTitleLangOrgs}}</label>
                <br>
            <ng-multiselect-dropdown [settings]="dropDownSettingsLngOrg" class="ddlSelect" [(ngModel)]="languageSeleccionadoOrg" [data]="lstLanguagesOrganization"
            name="lstLangOrg"  [placeholder]="lblplaceholderlstlanguages" (onSelect)="fnChargeLanguagesOfOrganization($event)" (onDeSelect)="fnDeselectLang()"></ng-multiselect-dropdown>
            
        </div>

        <!-- <div *ngIf="!blnisAdminUser && !blnisfacilitator" class="col-sm-3 col-md-3 col-lg-3">
            <label for="lstLangInter" class="FontMontserratText" style="color: #334d6e;">International Languages</label>
                <br>
            <ng-multiselect-dropdown [settings]="dropDownSettingsLngInter" class="ddlSelect" [(ngModel)]="languageSeleccionadoIntern" [data]="lstLangInternationals"
            name="lstLangInter"  [placeholder]="lblplaceholderlstlanguages" (onSelect)="fnChargeLanguagesInternational($event)" (onDeSelect)="fnDeselectLang()" [disabled]="languageSeleccionado.length > 0"></ng-multiselect-dropdown>
            

        </div> -->
    </div>
    <hr>
    <div class="row text-center" *ngIf="intIdLangGlobal > 0">
        <div class="col-sm-12 col-md-12 col-lg-12 text-left">
            <label for="lstLang" class="FontMontserratText">Name of Language selected:</label><br>
            
                <!--&nbsp;<i class="fa fa-pencil fa-lg"  title="Save Language Description" style="color:green;position:relative; top:10px"></i>-->
            
        </div>
    </div>
    <div class="row text-center" *ngIf="intIdLangGlobal > 0">
        <div class="col-sm-4 col-md-4 col-lg-3 text-left" >
            <input  name="name" class="form-control" id="textNameEdit" type="text" value="{{txtLanguageSelected}}" >
             
         </div>
         <div *ngIf="blnShowUpdate" class="col-sm-2 col-md-2 col-lg-2 text-left" >
            <button class="btn btn-outline-success"  (click)="fnChangeNameLanguage()" style="Background-color:#6FB45C;border-radius: 50px;color: white;font-family: 'Montserrat';"><i class="fa fa-pencil-square-o" title="Save Language Description" ></i><span >&nbsp;&nbsp;Update&nbsp;&nbsp;</span></button>
             
         </div>
         <div *ngIf="(!isSuperAdmin && blnShowUpdate && blnShowDelete) || (isSuperAdmin)" class="col-sm-2 col-md-2 col-lg-2 text-left" >
            <button class="btn btn-outline-danger"  (click)="fnDeleteLanguage()" style="Background-color:#f13f3f;border-radius: 50px;color: white;font-family: 'Montserrat';"><i class="fa fa-trash-o" title="Delete Language Description" ></i><span >&nbsp;&nbsp;Delete&nbsp;&nbsp;</span></button>
             
         </div>
         <div class="col-sm-5 col-md-5 col-lg-4 text-left" >
            
            <button class="btn btn-outline-success"  (click)="showInput()" style="Background-color:#25206e;border-radius: 50px;color: white;font-family: 'Montserrat';"><i class="fa fa-files-o" style="color: white;" title="Save Language Description" ></i><span >&nbsp;&nbsp;Make a Copy&nbsp;&nbsp;</span></button>
             
         </div>
        
    </div><br>
    <div class="row text-center" *ngIf="intIdLangGlobal > 0 && this.blnisAdminUser">
        <div class="col-sm-12 col-md-12 col-lg-12 text-left" >
            
            <mat-radio-group  [disabled]="!blnShowUpdate" >
                <mat-radio-button style="margin: 0 5px;" [checked]="isPrivate == 1" class="example-margin" value="1" (change)="radioChangetypeLang($event)">
                    Private </mat-radio-button>
                <mat-radio-button style="margin: 0 5px;" [checked]="isPrivate == 0" class="example-margin" value="0" (change)="radioChangetypeLang($event)">
                    International</mat-radio-button>
                
            </mat-radio-group>
        </div>

    </div>
    <br>
    <div class="row text-center" *ngIf="intIdLangGlobal > 0">
        <div class="col-sm-12 col-md-12 col-lg-12 text-left FontMontserratText">Pages to Edit Labels</div>
        
    </div>
    <hr style="color: rgb(57, 95, 218);">
    <br>

    <div class="row" *ngIf="intIdLangGlobal > 0">
        <!--BOAR view-->
            <div class="col-sm-2 col-md-2 col-lg-2">
                
                <mat-card class="example-card" style="background-color:#244C91;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Game Board & Fin Statements')">
                   <!--<mat-card-header>
                    <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;"><a (click)="fnModalEdit('Board')">Main Board View</a>&nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('Board')" title="Edit labels of Main Board" style="color:green;position:relative; top:4px"></i></mat-card-title>
                    <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                    </mat-card-header>-->
                    
                    <!--<mat-card-content style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Game Board & Fin Statements
                    </mat-card-content>-->
                    <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                        Game Board & Fin Statements
                    </mat-card-title>
                </mat-card>

            </div>
        <!--SetUp view-->  

        <div class="col-sm-2 col-md-2 col-lg-2">
            
            <mat-card class="example-card" style="background-color:#A7C158;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('SetUp')">
                <!--<mat-card-header>
                <div mat-card-avatar class="example-header-imageSU"></div>
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Set Up View &nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('SetUp')" title="Edit labels of Set Up view" style="color:green;position:relative; top:4px"></i></mat-card-title>
                <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header>-->

                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Team SetUp
                </mat-card-title>
                
                <!--<mat-card-content>
                    <img mat-card-image src="../../../assets/setup.png" alt="Actually View">
                <p>
                    Manage configuration view labels.
                </p>
                </mat-card-content>-->
            </mat-card>
        </div>
        <!--BOAR Markets Customers-->
        <div class="col-sm-2 col-md-2 col-lg-2" >
                
            <mat-card class="example-card" style="background-color:#685D9F;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Notes on Financial Statements')">
               <!-- <mat-card-header>
                <div mat-card-avatar class="example-header-imageMC"></div> 
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Notes on Financial Statements &nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('MarketsCustomers')" title="Edit labels of Markets Customers View" style="color:green;position:relative; top:4px"></i>  </mat-card-title>
                <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header> -->
                
                <!--<mat-card-content>
                    <img mat-card-image src="../../../assets/marketbk.jpg" alt="Actually View">
                <p>
                    Page to manage Markets Customers.
                </p>
                </mat-card-content>-->
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Notes on Financial Statements
                </mat-card-title>

            </mat-card>
        </div>

        <!--Income statement-->
        <!--
        <div class="col-sm-2 col-md-2 col-lg-2" >
                
            <mat-card class="example-card" style="background-color:#4EA7C8;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Checklist')">
                <!- -<mat-card-header>
                <div mat-card-avatar class="example-header-imageMF"></div>
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Checklist &nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('Marketfacilitator')" title="Edit labels of Market Facilitators master sheet" style="color:green;position:relative; top:4px"></i></mat-card-title>
                <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header>
                
                <mat-card-content>
                    <img mat-card-image src="../../../assets/bkmanage.png" alt="Actually View">
                <p>
                    Page to manage Market (Facilitator master sheet) View.
                </p>
                </mat-card-content>- ->
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Checklist
                </mat-card-title>

            </mat-card>
        </div>
        -->
        <!--Income statement-->
        <div class="col-sm-2 col-md-2 col-lg-2">
                
            <mat-card class="example-card" style="background-color:#40874D;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Monthly Debrief/Results')">
                <!--<mat-card-header>
                <div mat-card-avatar class="example-header-imageIO"></div>
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Income statement <i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('Incomestatement')" title="Edit labels of Income statement View" style="color:green;position:relative; top:4px"></i></mat-card-title>
                <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header>
                
                <mat-card-content>
                    <img mat-card-image src="../../../assets/iobk.png" alt="Actually View">
                <p>
                    Page to manage Income Statement View.
                </p>
                </mat-card-content>-->

                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Monthly Debrief/Results
                </mat-card-title>
            </mat-card>
        </div>

        <div class="col-sm-2 col-md-2 col-lg-2">
                
            <mat-card class="example-card" style="background-color:#925D9C;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Markets')">
                <!--<mat-card-header>
                <div mat-card-avatar class="example-header-image"></div>
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Markets &nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('RatioSlides')" title="Edit labels of Ratio Slides view" style="color:green;position:relative; top:4px"></i></mat-card-title>
                <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header>
                
                <mat-card-content>
                    <img mat-card-image src="../../../assets/bkmanage.png" alt="Actually View">
                <p>
                    Page to Ratio Slides.
                </p>
                </mat-card-content>-->

                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Markets
                </mat-card-title>
            </mat-card>
        </div>

        <div class="col-sm-2 col-md-2 col-lg-2">
            
            <mat-card class="example-card" style="background-color:#40874D;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Learning Slides')">
                <!--<mat-card-header style="text-align: center; position: absolute;">
                <div mat-card-avatar class="example-header-imageSU"></div>
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white; justify-content: center; position: absolute;">Learning Slides &nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('DebriefTab')" title="Edit labels of Debrief Tab" style="color:green;position:relative; top:4px"></i></mat-card-title>
                <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header>
                
                <mat-card-content>
                    <img mat-card-image src="../../../assets/bkmanage.png" alt="Actually View">
                <p>
                    Manage labels of Debrief Tab.
                </p>
                </mat-card-content>-->
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Learning Slides
                </mat-card-title>
            </mat-card>
        </div>

    </div>
<br><br>
    <div class="row" *ngIf="intIdLangGlobal > 0">  
        <!-- view-->  

        <!-- -->
        <div class="col-sm-2 col-md-2 col-lg-2" >
                
            <mat-card class="example-card" style="background-color:#5ea0cc;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Improvement Options')">
                <!--<mat-card-header>
                <div mat-card-avatar class="example-header-imageMC"></div>
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Rules & Improvement Options &nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('PublicInfo')" title="Edit labels of  Public Info View" style="color:green;position:relative; top:4px"></i></mat-card-title>
                <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header>
                
               <mat-card-content>
                    <img mat-card-image src="../../../assets/bkmanage.png" alt="Actually View">
                <p>
                    Page to manage Public Info view.
                </p>
                </mat-card-content>-->
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Improvement Projects
                </mat-card-title>
            </mat-card>
        </div>
        
        <div class="col-sm-2 col-md-2 col-lg-2" >
                
            <mat-card class="example-card" style="background-color:#6FB45C;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Rules')">
            <!-- <mat-card-header>
                <div mat-card-avatar class="example-header-imageIO"></div>
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Working Copy for Improvement Options &nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('ResultsView')" title="Edit labels of Results View" style="color:green;position:relative; top:4px"></i></mat-card-title>
                <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header>
                
                <mat-card-content>
                    <img mat-card-image src="../../../assets/iobk.png" alt="Actually View">
                <p>
                    Page to manage labels of Results View.
                </p>
                </mat-card-content> -->
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Rules
                </mat-card-title>
            </mat-card>
        </div>
       
        <div class="col-sm-2 col-md-2 col-lg-2" >
                
            <mat-card class="example-card" style="background-color:#3dbea2;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Messages')">
                <!--<mat-card-header>
                <div mat-card-avatar class="example-header-imageIO"></div>
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Working Copy for Improvement Options &nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('ResultsView')" title="Edit labels of Results View" style="color:green;position:relative; top:4px"></i></mat-card-title>
                <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header>
                
                <mat-card-content>
                    <img mat-card-image src="../../../assets/iobk.png" alt="Actually View">
                <p>
                    Page to manage labels of Results View.
                </p>
                </mat-card-content>-->
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Messages
                </mat-card-title>
            </mat-card>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2" >
                
            <mat-card class="example-card" style="background-color:#4EA7C8;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Checklist')">
                <!--<mat-card-header>
                <div mat-card-avatar class="example-header-imageIO"></div>
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Working Copy for Improvement Options &nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('ResultsView')" title="Edit labels of Results View" style="color:green;position:relative; top:4px"></i></mat-card-title>
                <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header>
                
                <mat-card-content>
                    <img mat-card-image src="../../../assets/iobk.png" alt="Actually View">
                <p>
                    Page to manage labels of Results View.
                </p>
                </mat-card-content>-->
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Checklist
                </mat-card-title>
            </mat-card>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2" >
                
            <mat-card class="example-card" style="background-color:#d49f2c;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Services Version')">
                <!--<mat-card-header>
                <div mat-card-avatar class="example-header-imageIO"></div>
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Working Copy for Improvement Options &nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('ResultsView')" title="Edit labels of Results View" style="color:green;position:relative; top:4px"></i></mat-card-title>
                <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header>
                
                <mat-card-content>
                    <img mat-card-image src="../../../assets/iobk.png" alt="Actually View">
                <p>
                    Page to manage labels of Results View.
                </p>
                </mat-card-content>-->
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Services Version
                </mat-card-title>
            </mat-card>
        </div>

        <div class="col-sm-2 col-md-2 col-lg-2" >
                
            <mat-card class="example-card" style="background-color:#d4d42c;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;">
                <!--<mat-card-header>
                <div mat-card-avatar class="example-header-imageIO"></div>
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">Working Copy for Improvement Options &nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('ResultsView')" title="Edit labels of Results View" style="color:green;position:relative; top:4px"></i></mat-card-title>
                <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header>
                
                <mat-card-content>
                    <img mat-card-image src="../../../assets/iobk.png" alt="Actually View">
                <p>
                    Page to manage labels of Results View.
                </p>
                </mat-card-content>-->
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Professional Services Version
                </mat-card-title>
            </mat-card>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" ></div>


    </div>
    <br><br>
   <!-- <div class="row" *ngIf="intIdLangGlobal > 0">
        <div class="col-sm-2 col-md-2 col-lg-2">
            
            <mat-card class="example-card" style="background-color:#dbba57;border-width: 40px;height: 100px !important;cursor: pointer;text-align: center;" (click)="fnModalEdit('Learning Slides SS')">  -->
                <!--<mat-card-header style="text-align: center; position: absolute;">
                <div mat-card-avatar class="example-header-imageSU"></div>
                <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white; justify-content: center; position: absolute;">Learning Slides &nbsp;&nbsp;&nbsp;<i class="fa fa-pencil fa-lg"  (click)="fnModalEdit('DebriefTab')" title="Edit labels of Debrief Tab" style="color:green;position:relative; top:4px"></i></mat-card-title>
                <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header>
                
                <mat-card-content>
                    <img mat-card-image src="../../../assets/bkmanage.png" alt="Actually View">
                <p>
                    Manage labels of Debrief Tab.
                </p>
                </mat-card-content>-->
                 <!-- <mat-card-title style="cursor: pointer;font-size: 20px;align-self: center; text-align: center !important;align-content: center;color: white;">
                    Learning Slides Services
                </mat-card-title>
            </mat-card>
        </div>
    </div>  -->

<br><br>
<br><br>
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.75)" size = "large" color = "#ffffff" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>

  

<button type="button" class="btn btn-primary"  id="btnExampleModal" data-toggle="modal" data-target="#exampleModal"   data-backdrop="static" data-keyboard="false" hidden></button>
<!--app-editlabels [strTypeView]="strTypeView"  (funcionOut)="fnSaveEdited($event)"></app-editlabels-->
