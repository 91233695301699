<mat-tab-group #tabGroup [selectedIndex]="mes-auxMes" scrollDistance="300px" (selectedTabChange)="fnChangeMonthHistoric($event['tab']['textLabel'])">
    <mat-tab label="{{getSingleTextLabel('5013')}} {{auxMes+(i)}}" *ngFor="let item of numbers; let i = index;" [disabled]="(i+auxMes) > mes || isfinished"></mat-tab>
</mat-tab-group>
<div mat-dialog-title style="border-bottom: 1px solid #f3f3f3; width: 100% !important; text-align: center;">
</div>
<mat-dialog-content cdkDrag cdkDragRootElement=".cdk-overlay-pane" style="height:85%">
    <div class="calibriFont style1">
        <p>{{getSingleTextLabel('5024')}}</p>
    </div>

    <div class="calibriFont style2b" *ngIf="!isfinished; else finishWS">
        <p style="text-align: center;">{{getSingleTextLabel('5025')}} - {{getSingleTextLabel('5013')}} {{monthHistoric}}</p>
    </div>
    <ng-template #finishWS>
        <div class="calibriFont style2b">
            <p style="text-align: center;">{{getSingleTextLabel('5025')}} - {{getSingleTextLabel('5013')}} {{monthHistoric-1}}</p>
        </div>
    </ng-template>


    <div class="row" class="marginwhite">
        <div class="grid-container">
            <div *ngIf="!isfinished; else finishWS2" class="marginblack style12 calibriFont" style="width: 190px; text-align: center;">
                <span> {{getSingleTextLabel('5013')}} {{monthHistoric}} </span>
            </div>
            <ng-template #finishWS2>
                <div class="marginblack style12 calibriFont" style="width: 190px; text-align: center;" >
                    <span> {{getSingleTextLabel('5013')}} {{monthHistoric-1}} </span>
                </div>
            </ng-template>
            <div class=" marginLeft marginblack style3 calibriFont" style="width: 100px; text-align: center;">
                <span>{{getSingleTextLabel('5026')}}</span>
            </div>
            <div class=" marginLeft marginblack style3 calibriFont" style="width: 100px; text-align: center;">
                <span>{{getSingleTextLabel('5027')}}</span>
            </div>
            <div class=" marginLeft marginblack style3 calibriFont" style="width: 100px; text-align: center;">
                <span>{{getSingleTextLabel('5028')}} </span>
            </div>
            <div class=" marginLeft marginblack style3 calibriFont" style="width: 100px; text-align: center;">
                <span>{{getSingleTextLabel('5029')}} </span>
            </div>
            <div class="grid-container">
                <div>
                    <div class=" marginLeft style3 calibriFont" style="text-align: center; border: 1px solid;
                    border-bottom: 0px;
                    border-left: 0px;"><span>{{getSingleTextLabel('5030')}}</span>&nbsp;&nbsp;
                    <button class="styleButtonOK" [disabled]="!canEditTarget" (click)="updateInput('topDownApproch', 'netIncome', 90)">
                     
                        <span>&#61;</span>
                      </button>
                    

                    </div>
                    <div class="grid-container">
                        <div class=" marginLeft marginblack style3 calibriFont" style="width: 100px; text-align: center;">
                            <span>{{getSingleTextLabel('5031')}} </span>
                        </div>
                        <div class=" marginLeft marginblack style3 calibriFont" style="width: 100px; text-align: center;">
                            <span>% </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" class="marginwhite2"><!--Price / Royal-->
        <div class="grid-container style2a calibriFont">
            <div class="marginRigth colorblue textoazul">{{getSingleTextLabel('5032')}}/{{getSingleTextLabel('200')}}</div>
            <div class="textoblanco"></div>
            <div class="margin4 textoInput  border ">
                <input (change)="updateInput('topDownApproch', 'priceRoyal',-1)" type="number" step="any" id="input100" matInput
                    class="wblue style5b calibriFont" readonly [(ngModel)]="datos.topDownApproch.priceRoyal" min="0"
                    [ngStyle]="{'color': datos.topDownApproch.priceRoyal < 0 ? 'red':'black'}">


            </div>
            <div class="margin4 textoInput  border ">
                <input (change)="updateInput('bottomUpApproch', 'priceRoyal', -1)" type="number" step="any"
                    [(ngModel)]="datos.bottomUpApproch.priceRoyal" id="input101" matInput class="wblue style5b calibriFont" readonly
                    onkeypress="return event.charCode >= 48" min="0"
                    [ngStyle]="{'color': datos.bottomUpApproch.priceRoyal < 0 ? 'red':'black'}">


            </div>
            <div class="margin4 textoInput  border ">
                <input (change)="updateInput('Target', 'priceRoyal', -1)" type="number" step="any" id="input100" matInput
                    class="wblue style5b calibriFont" readonly [(ngModel)]="datos.Target.priceRoyal" onkeypress="return event.charCode >= 48" min="0"
                    [ngStyle]="{'color': datos.Target.priceRoyal < 0 ? 'red':'black'}">


            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" [(ngModel)]="datos.Actual.priceRoyal" id="input101" matInput class="w style5c calibriFont"
                    onkeypress="return event.charCode >= 48" min="0" readonly
                    [ngStyle]="{'color': datos.Actual.priceRoyal < 0 ? 'red':'black'}">


            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" [(ngModel)]="datos.Raw.priceRoyal" id="input101" matInput class="w style5c calibriFont"
                    onkeypress="return event.charCode >= 48" min="0" readonly
                    [ngStyle]="{'color': datos.Raw.priceRoyal < 0 ? 'red':'black'}">


            </div>
            <div class="margin4 textoInput  border ">
                <div style="display: flex;">

                    <input type="number" step="any" style="flex: 1;" [(ngModel)]="datos.PorCiento.priceRoyal" id="input101"
                        matInput class="w style5c calibriFont" onkeypress="return event.charCode >= 48" min="0" readonly
                        [ngStyle]="{'color': datos.Raw.priceRoyal < 0 ? 'red':'black'}">

                    <!--<p class="calibriFont style5c ">&nbsp;%</p>-->
                </div>
            </div>
        </div>
    </div>


    <div class="row" class="marginwhite2"><!--2 fila # royals-->
        <div class="grid-container style2a calibriFont">
            <div class="marginRigth colorblue textoazul">{{getSingleTextLabel('5033')}} </div>
            <div class="textoblanco" style="display: inline-flex;">
            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input200" matInput class="w style5b calibriFont borderEditables" [(ngModel)]="datos.topDownApproch.numberRoyals"
                    placeholder="0" (change)="updateInput('topDownApproch', 'numberRoyals', 1)" min="0"
                    [ngStyle]="{'color': datos.topDownApproch.numberRoyals < 0 ? 'red':'black'}">


            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input201" matInput class="wblue style5b calibriFont" readonly
                    [(ngModel)]="datos.bottomUpApproch.numberRoyals" min="0"
                    [ngStyle]="{'color': datos.bottomUpApproch.numberRoyals < 0 ? 'red':'black'}">


            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input200" matInput class="wblue style5b calibriFont" readonly [(ngModel)]="datos.Target.numberRoyals"
                    min="0" [ngStyle]="{'color': datos.Target.numberRoyals < 0 ? 'red':'black'}">


            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" [(ngModel)]="datos.Actual.numberRoyals" id="input101" matInput class="w style5c calibriFont" readonly
                    [ngStyle]="{'color': datos.Actual.numberRoyals < 0 ? 'red':'black'}">


            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" [(ngModel)]="datos.Raw.numberRoyals" id="input101" matInput class="w style5c calibriFont" readonly
                    [ngStyle]="{'color': datos.Raw.numberRoyals < 0 ? 'red':'black'}">


            </div>
            <div class="margin4 textoInput  border ">
                <div style="display: flex;">
                    <input type="number" step="any" [(ngModel)]="datos.PorCiento.numberRoyals" id="input101" matInput class="w style5c calibriFont"
                        onkeypress="return event.charCode >= 48" min="0" readonly
                        [ngStyle]="{'color': datos.PorCiento.numberRoyals < 0 ? 'red':'black'}">
                    <!--<p class="calibriFont style5c ">&nbsp;%</p>-->
                </div>

            </div>
        </div>
    </div>


    <div class="row" class="marginwhite2"><!--total sales-->
        <div class="grid-container style2aBold text-right calibriFont">
            <div class="marginRigth colorblue textoazul">{{getSingleTextLabel('14') | uppercase}}</div>
            <div class="textoblanco textRigth">
                <div style="display: inline-flex;">
                    <span class="equal" (click)="updateInput('topDownApproch', 'totalSale', 1)"
                        style="text-align: right; height: 1px; font-size: 20px; cursor: pointer; width: 10%;">&#61;</span>
                </div>
            </div>
            <div class="margin5 textoInput2 wblue border">
                <input type="number" step="any" id="input300" matInput class="w style6 calibriFont borderEditables" [(ngModel)]="datos.topDownApproch.totalSale"
                    placeholder="0" (change)="updateInput('topDownApproch', 'totalSale', 1)" min="0"
                    [ngStyle]="{'color': datos.topDownApproch.totalSale < 0 ? 'red':'black'}">

            </div>
            <div class="margin5 textoInput2 wblue border">
                <input type="number" step="any" id="input301" matInput class="wbluestrong style6 calibriFont" readonly
                    [(ngModel)]="datos.bottomUpApproch.totalSale" min="0"
                    [ngStyle]="{'color': datos.bottomUpApproch.totalSale < 0 ? 'red':'black'}">

            </div>
            <div class="margin5 textoInput2 wblue border">
                <input type="number" step="any" id="input300" matInput class="wbluestrong style6 calibriFont" readonly [(ngModel)]="datos.Target.totalSale"
                    (change)="updateInput('Target', 'totalSale', 2)" min="0"
                    [ngStyle]="{'color': datos.Target.totalSale < 0 ? 'red':'black'}">

            </div>
            <div class="margin5 textoInput2 wblue border">
                <input type="number" step="any" id="input301" matInput class="wgray style6c calibriFont" [(ngModel)]="datos.Actual.totalSale"
                    [ngStyle]="{'color': datos.Actual.totalSale < 0 ? 'red':'black'}" readonly>

            </div>
            <div class="margin5 textoInput2 wblue border">
                <input type="number" step="any" id="input301" matInput class="wgray style6c calibriFont" [(ngModel)]="datos.Raw.totalSale"
                    [ngStyle]="{'color': datos.Raw.totalSale < 0 ? 'red':'black'}" readonly>

            </div>
            <div class="margin5 textoInput2 wblue border">
                <div style="display: flex;">
                    <input type="number" step="any" id="input301" matInput class="wgray style6c calibriFont" [(ngModel)]="datos.PorCiento.totalSale"
                        [ngStyle]="{'color': datos.PorCiento.totalSale < 0 ? 'red':'black'}" readonly>
                    <!--<p class="calibriFont style6c ">&nbsp;%</p>-->
                </div>
            </div>
        </div>
    </div>


    <div class="row" class="marginwhite2"><!--4f cost/royal-->
        <div class="grid-container style2a calibriFont">
            <div class="marginRigth colorblue textoazul">{{getSingleTextLabel('8069')}}/{{getSingleTextLabel('200')}}</div>
            <div class="textoblanco" style="display: inline-flex;">
            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input400" matInput [(ngModel)]="datos.topDownApproch.costRoyal"
                    (change)="fnResultado(3)" class="wblue style5b calibriFont" readonly onkeypress="return event.charCode >= 48" min="0"
                    [ngStyle]="{'color': datos.topDownApproch.costRoyal < 0 ? 'red':'black'}">

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input401" matInput class="w style5b calibriFont" [(ngModel)]="datos.bottomUpApproch.costRoyal"
                    [ngStyle]="{'color': datos.bottomUpApproch.costRoyal < 0 ? 'red':'black'}"
                    readonly onkeypress="return event.charCode >= 48" (change)="updateInput('bottomUpApproch', 'costRoyal', 2)"
                    min="0">

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input400" matInput [(ngModel)]="datos.Target.costRoyal"
                   readonly (change)="updateInput('Target', 'costRoyal', 2)" class="w style5b calibriFont" onkeypress="return event.charCode >= 48"
                    min="0" [ngStyle]="{'color': datos.Target.costRoyal < 0 ? 'red':'black'}">

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" [(ngModel)]="datos.Actual.costRoyal" matInput class="w style5c calibriFont"
                    onkeypress="return event.charCode >= 48" readonly
                    [ngStyle]="{'color': datos.Actual.costRoyal < 0 ? 'red':'black'}">

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" [(ngModel)]="datos.Raw.costRoyal" matInput class="w style5c calibriFont"
                    onkeypress="return event.charCode >= 48" readonly
                    [ngStyle]="{'color': datos.Raw.costRoyal < 0 ? 'red':'black'}">

            </div>
            <div class="margin4 textoInput  border ">
                <div style="display: flex;">
                    <input type="number" step="any" [(ngModel)]="datos.PorCiento.costRoyal" matInput class="w style5c calibriFont"
                        onkeypress="return event.charCode >= 48" min="0" readonly
                        [ngStyle]="{'color': datos.PorCiento.costRoyal < 0 ? 'red':'black'}">
                    <!--<p class="calibriFont style5c ">&nbsp;%</p>-->
                </div>
            </div>
        </div>
    </div>


    <div class="row" class="marginwhite2"><!--5f # of royals-->
        <div class="grid-container style2a calibriFont">
            <div class="marginRigth colorblue textoazul">{{getSingleTextLabel('5033')}}</div>
            <div class="textoblanco" style="display: inline-flex;">
            </div>
            <div class="margin5 textoInput2 border">
                <input type="number" step="any" id="input500" matInput class="wblue style5b calibriFont" readonly
                    [(ngModel)]="datos.topDownApproch.numberRoyals" (change)="fnResultado(2)" min="0" 
                    [ngStyle]="{'color': datos.topDownApproch.numberRoyals < 0 ? 'red':'black'}">

            </div>
            <div class="margin5 textoInput2  border  w">
                <input type="number" step="any" id="input501" matInput class="w style5b calibriFont borderEditables" [(ngModel)]="datos.bottomUpApproch.numberRoyals"
                    placeholder="0" (change)="updateInput('bottomUpApproch', 'numberRoyals', 2)" min="0" 
                    [ngStyle]="{'color': datos.bottomUpApproch.numberRoyals < 0 ? 'red':'black'}">

            </div>
            <div class="margin5 textoInput2  border  w">
                <input type="number" step="any" id="input500" matInput class="w style5b calibriFont borderEditables" [(ngModel)]="datos.Target.numberRoyals"
                    placeholder="0" (change)="updateInput('Target', 'numberRoyals', 2)" min="0"  [disabled]="!canEditTarget"
                    [ngStyle]="{'color': datos.Target.numberRoyals < 0 ? 'red':'black'}">

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" [(ngModel)]="datos.Actual.numberRoyals" id="input101" matInput class="w style5c calibriFont"
                    onkeypress="return event.charCode >= 48" min="0" readonly
                    [ngStyle]="{'color': datos.Actual.numberRoyals < 0 ? 'red':'black'}">

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" [(ngModel)]="datos.Raw.numberRoyals" id="input101" matInput class="w style5c calibriFont"
                    onkeypress="return event.charCode >= 48" min="0" readonly
                    [ngStyle]="{'color': datos.Raw.numberRoyals < 0 ? 'red':'black'}">

            </div>
            <div class="margin4 textoInput  border ">
                <div style="display: flex;">
                    <input type="number" step="any" [(ngModel)]="datos.PorCiento.numberRoyals" id="input101" matInput class="w style5c calibriFont"
                        onkeypress="return event.charCode >= 48" min="0" readonly
                        [ngStyle]="{'color': datos.PorCiento.numberRoyals < 0 ? 'red':'black'}">
                    <!--<p class="calibriFont style5c ">&nbsp;%</p>-->
                </div>
            </div>
        </div>
    </div>


    <div class="row" class="marginwhite2"><!--5f Total COGS-->
        <div class="grid-container style2aBold text-right calibriFont">
            <div class="marginRigth colorblue textoazul">{{getSingleTextLabel('104')}} {{getSingleTextLabel('16')}}</div>
            <div class="textoblanco" style="display: inline-flex;">
            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input600" matInput class="w style6 calibriFont borderEditables" [(ngModel)]="datos.topDownApproch.totalCOGS"
                    [ngStyle]="{'color': datos.topDownApproch.totalCOGS < 0 ? 'red':'black'}"
                    placeholder="0" (change)="updateInput('topDownApproch', 'totalCOGS', 2)" onkeypress="return event.charCode >= 48"
                    min="0">

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input601" matInput class="wblue style6 calibriFont borderEditables"  placeholder="0" (change)="updateInput('bottomUpApproch', 'totalCOGS', 2)"[(ngModel)]="datos.bottomUpApproch.totalCOGS"
                    [ngStyle]="{'color': datos.bottomUpApproch.totalCOGS < 0 ? 'red':'black'}"
                    onkeypress="return event.charCode >= 48" min="0">

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input600" matInput class="wblue style6 calibriFont borderEditables"  placeholder="0" (change)="updateInput('Target', 'totalCOGS', 2)" [(ngModel)]="datos.Target.totalCOGS"
                    [ngStyle]="{'color': datos.Target.totalCOGS < 0 ? 'red':'black'}" [disabled]="!canEditTarget"
                    onkeypress="return event.charCode >= 48" min="0">

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input601" matInput class="wgray style6c calibriFont" [(ngModel)]="datos.Actual.totalCOGS"
                    [ngStyle]="{'color': datos.Actual.totalCOGS < 0 ? 'red':'black'}"
                    onkeypress="return event.charCode >= 48" min="0" readonly>

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input601" matInput class="wgray style6c calibriFont" [(ngModel)]="datos.Raw.totalCOGS"
                    [ngStyle]="{'color': datos.Raw.totalCOGS < 0 ? 'red':'black'}"
                    onkeypress="return event.charCode >= 48" min="0" readonly>

            </div>
            <div class="margin4 textoInput  border ">
                <div style="display: flex;">
                    <input type="number" step="any" id="input601" matInput class="wgray style6c calibriFont" [(ngModel)]="datos.PorCiento.totalCOGS"
                        [ngStyle]="{'color': datos.PorCiento.totalCOGS < 0 ? 'red':'black'}"
                        onkeypress="return event.charCode >= 48" min="0" readonly>
                    <!--<p class="calibriFont style6c ">&nbsp;%</p>-->
                </div>
            </div>
        </div>
    </div>


    <div class="row" class="marginwhite2"><!--7 f Contribution margin-->
        <div class="grid-container style2aBold text-right calibriFont">
            <div class="marginRigth colorblue textoazul">{{getSingleTextLabel('20')}}</div>
            <div class="textoblanco textRigth">
                <div style="display: inline-flex;">
                    <span class="equal" (click)="updateInput('topDownApproch', 'contribution', 2)"
                    style="text-align: right; height: 1px; font-size: 20px; cursor: pointer; width: 10%;">&#61;</span>
                    </div>
            </div>
            <div class="margin5 textoInput2  border ">
                <input type="number" step="any" id="input700" matInput class="wbluestrong style6 calibriFont" readonly
                    [(ngModel)]="datos.topDownApproch.contribution"
                    [ngStyle]="{'color': datos.topDownApproch.contribution < 0 ? 'red':'black'}">


            </div>
            <div class="margin5 textoInput2  border ">
                <input type="number" step="any" id="input701" matInput class="wbluestrong style6 calibriFont" readonly
                    [(ngModel)]="datos.bottomUpApproch.contribution"
                    [ngStyle]="{'color': datos.bottomUpApproch.contribution < 0 ? 'red':'black'}">

            </div>
            <div class="margin5 textoInput2  border ">
                <input type="number" step="any" id="input700" matInput class="wbluestrong style6 calibriFont" readonly [(ngModel)]="datos.Target.contribution"
                    [ngStyle]="{'color': datos.Target.contribution < 0 ? 'red':'black'}">

            </div>
            <div class="margin5 textoInput2  border ">
                <input type="number" step="any" id="input701" matInput class="wgraystrong style6c calibriFont" [(ngModel)]="datos.Actual.contribution"
                    [ngStyle]="{'color': datos.Actual.contribution < 0 ? 'red':'black'}">

            </div>
            <div class="margin5 textoInput2  border ">
                <input type="number" step="any" id="input701" matInput class="wgraystrong style6c calibriFont" [(ngModel)]="datos.Raw.contribution"
                    [ngStyle]="{'color': datos.Raw.contribution < 0 ? 'red':'black'}">

            </div>
            <div class="margin5 textoInput2  border ">
                <div style="display: flex;">
                    <input type="number" step="any" id="input701" matInput class="wgraystrong style6c calibriFont"
                        [(ngModel)]="datos.PorCiento.contribution"
                        [ngStyle]="{'color': datos.PorCiento.contribution < 0 ? 'red':'black'}">
                    <!--<p class="calibriFont style6c ">&nbsp;%</p>-->
                </div>
            </div>
        </div>
    </div>


    <div class="row" class="marginwhite2"><!--factory overheads-->
        <div class="grid-container style2a calibriFont">
            <div class="marginRigth colorblue textoazul">{{getSingleTextLabel('21')}}</div>
            <div class="textoblanco" style="display: inline-flex;">
            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input800" matInput class="w style5b calibriFont borderEditables" [(ngModel)]="datos.topDownApproch.factoryOverhead"
                    [ngStyle]="{'color': datos.topDownApproch.factoryOverhead < 0 ? 'red':'black'}"
                    placeholder="0" (change)="updateInput('topDownApproch', 'factoryOverhead', 3)"
                    onkeypress="return event.charCode >= 48" min="0">

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input801" matInput class="w style5b calibriFont borderEditables"
                    [(ngModel)]="datos.bottomUpApproch.factoryOverhead"
                    [ngStyle]="{'color': datos.bottomUpApproch.factoryOverhead < 0 ? 'red':'black'}"
                    onkeypress="return event.charCode >= 48"
                    placeholder="0" (change)="updateInput('bottomUpApproch', 'factoryOverhead', 3)" min="0">

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input800" matInput class="w style5b calibriFont borderEditables" [(ngModel)]="datos.Target.factoryOverhead"
                    [ngStyle]="{'color': datos.Target.factoryOverhead < 0 ? 'red':'black'}" [disabled]="!canEditTarget"
                    placeholder="0" (change)="updateInput('Target', 'factoryOverhead', 3)" onkeypress="return event.charCode >= 48"
                    min="0">

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input801" matInput class="w style5c calibriFont" [(ngModel)]="datos.Actual.factoryOverhead"
                    [ngStyle]="{'color': datos.Actual.factoryOverhead < 0 ? 'red':'black'}"
                    onkeypress="return event.charCode >= 48" min="0" readonly>

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input801" matInput class="w style5c calibriFont" [(ngModel)]="datos.Raw.factoryOverhead"
                    [ngStyle]="{'color': datos.Raw.factoryOverhead < 0 ? 'red':'black'}"
                    onkeypress="return event.charCode >= 48" min="0" readonly>

            </div>
            <div class="margin4 textoInput  border ">
                <div style="display: flex;">
                    <input type="number" step="any" id="input801" matInput class="w style5c calibriFont" [(ngModel)]="datos.PorCiento.factoryOverhead"
                        [ngStyle]="{'color': datos.PorCiento.factoryOverhead < 0 ? 'red':'black'}"
                        onkeypress="return event.charCode >= 48" min="0" readonly>
                    <!--<p class="calibriFont style5c ">&nbsp;%</p>-->
                </div>
            </div>
        </div>
    </div>


    <div class="row" class="marginwhite2"><!--9f SG&A-->
        <div class="grid-container style2a calibriFont">
            <div class="marginRigth colorblue textoazul">{{getSingleTextLabel('80')}}</div>
            <div class="textoblanco" style="display: inline-flex;">
            </div>
            <div class="margin5 textoInput2  border  wblue">
                <input type="number" step="any" id="input900" matInput class="w style5b calibriFont borderEditables" [(ngModel)]="datos.topDownApproch.SGA"
                    [ngStyle]="{'color': datos.topDownApproch.SGA < 0 ? 'red':'black'}" min="0"
                    placeholder="0" (change)="updateInput('topDownApproch', 'SGA', 3)">
            </div>
            <div class="margin5 textoInput2  border  wblue">
                <input type="number" step="any" id="input901" matInput class="w style5b calibriFont borderEditables" [(ngModel)]="datos.bottomUpApproch.SGA"
                    placeholder="0" (change)="updateInput('bottomUpApproch', 'SGA', 3)" min="0"
                    [ngStyle]="{'color': datos.bottomUpApproch.SGA < 0 ? 'red':'black'}">
            </div>
            <div class="margin5 textoInput2  border  wblue">
                <input type="number" step="any" id="input900" matInput class="w style5b calibriFont borderEditables" [(ngModel)]="datos.Target.SGA"
                    [ngStyle]="{'color': datos.Target.Finance < 0 ? 'red':'black'}" min="0" [disabled]="!canEditTarget"
                    placeholder="0" (change)="updateInput('Target', 'SGA', 3)">
            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input801" matInput class="w style5c calibriFont" [(ngModel)]="datos.Actual.SGA"
                    [ngStyle]="{'color': datos.Actual.SGA < 0 ? 'red':'black'}" onkeypress="return event.charCode >= 48"
                    min="0" readonly>

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input801" matInput class="w style5c calibriFont" [(ngModel)]="datos.Raw.SGA"
                    [ngStyle]="{'color': datos.Raw.SGA < 0 ? 'red':'black'}" onkeypress="return event.charCode >= 48"
                    min="0" readonly>

            </div>
            <div class="margin4 textoInput  border ">
                <div style="display: flex;">
                    <input type="number" step="any" id="input801" matInput class="w style5c calibriFont" [(ngModel)]="datos.PorCiento.SGA"
                        [ngStyle]="{'color': datos.PorCiento.SGA < 0 ? 'red':'black'}"
                        onkeypress="return event.charCode >= 48" min="0" readonly>
                    <!--<p class="calibriFont style5c ">&nbsp;%</p>-->
                </div>
            </div>
        </div>
    </div>



    <div class="row" class="marginwhite2"> <!--10 f finance-->
        <div class="grid-container style2a calibriFont">
            <div class="marginRigth colorblue textoazul">{{getSingleTextLabel('38')}}</div>

            <div class="textoblanco" style="display: inline-flex;">
            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input1000" matInput class="w style5b calibriFont borderEditables" [(ngModel)]="datos.topDownApproch.Finance"
                    placeholder="0" (change)="updateInput('topDownApproch', 'Finance', 3)" onkeypress="return event.charCode >= 48"
                    min="0">

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input1001" matInput class="w style5b calibriFont borderEditables" [(ngModel)]="datos.bottomUpApproch.Finance"
                    placeholder="0" placeholder="0" (change)="updateInput('bottomUpApproch', 'Finance', 3)"
                    [ngStyle]="{'color': datos.bottomUpApproch.Finance < 0 ? 'red':'black'}"
                    onkeypress="return event.charCode >= 48" min="0">

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input1000" matInput class="w style5b calibriFont borderEditables" [(ngModel)]="datos.Target.Finance"
                    [ngStyle]="{'color': datos.Target.Finance < 0 ? 'red':'black'}" [disabled]="!canEditTarget"
                    placeholder="0" (change)="updateInput('Target', 'Finance', 3)" onkeypress="return event.charCode >= 48" min="0">

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input801" matInput class="w style5c calibriFont" [(ngModel)]="datos.Actual.Finance"
                    [ngStyle]="{'color': datos.Actual.Finance < 0 ? 'red':'black'}"
                    onkeypress="return event.charCode >= 48" min="0" readonly>

            </div>
            <div class="margin4 textoInput  border ">
                <input type="number" step="any" id="input801" matInput class="w style5c calibriFont" [(ngModel)]="datos.Raw.Finance"
                    [ngStyle]="{'color': datos.Raw.Finance < 0 ? 'red':'black'}"
                    onkeypress="return event.charCode >= 48" min="0" readonly>

            </div>
            <div class="margin4 textoInput  border ">
                <div style="display: flex;">
                    <input type="number" step="any" id="input801" matInput class="w style5c calibriFont" [(ngModel)]="datos.PorCiento.Finance"
                        [ngStyle]="{'color': datos.PorCiento.Finance < 0 ? 'red':'black'}"
                        onkeypress="return event.charCode >= 48" min="0" readonly>
                    <!--<p class="calibriFont style5c ">&nbsp;%</p>-->
                </div>
            </div>
        </div>
    </div>


    <div class="row" class="marginwhite2"><!--11 f Total fixed Costs-->
        <div class="grid-container style2aBold text-right calibriFont">
            <div class="marginRigth colorblue textoazul">{{getSingleTextLabel('104')}} {{getSingleTextLabel('7004')}}</div>
            <div class="textoblanco textRigth">
                <div style="display: inline-flex;">

                    <span class="equal" (click)="updateInput('topDownApproch', 'totalFiexed', 3)"
                        style="text-align: right; height: 1px; font-size: 20px; cursor: pointer; width: 10%;">&#61;</span>
                </div>
            </div>
            <div class="margin5 textoInput2  border ">
                <input type="number" step="any" id="input1101" matInput class="wblue style6 calibriFont " readonly
                    [(ngModel)]="datos.topDownApproch.totalFiexed"
                    (change)="updateInput('topDownApproch', 'totalFiexed', -1)"
                    [ngStyle]="{'color': datos.topDownApproch.totalFiexed < 0 ? 'red':'black'}">

            </div>
            <div class="margin5 textoInput2  border ">
                <input type="number" step="any" id="input1101" matInput class="wblue style6 calibriFont" readonly
                    [(ngModel)]="datos.bottomUpApproch.totalFiexed"
                    [ngStyle]="{'color': datos.bottomUpApproch.totalFiexed < 0 ? 'red':'black'}">

            </div>
            <div class="margin5 textoInput2  border ">
                <input type="number" step="any" id="input1101" matInput class="wblue style6 calibriFont" readonly [(ngModel)]="datos.Target.totalFiexed"
                    [ngStyle]="{'color': datos.Target.totalFiexed < 0 ? 'red':'black'}">

            </div>
            <div class="margin5 textoInput2  border ">
                <input type="number" step="any" id="input1101" matInput class="wgray style6c calibriFont" [(ngModel)]="datos.Actual.totalFiexed"
                    [ngStyle]="{'color': datos.Actual.totalFiexed < 0 ? 'red':'black'}" readonly>

            </div>
            <div class="margin5 textoInput2  border ">
                <input type="number" step="any" id="input1101" matInput class="wgray style6c calibriFont" [(ngModel)]="datos.Raw.totalFiexed"
                    [ngStyle]="{'color': datos.Raw.totalFiexed < 0 ? 'red':'black'}" readonly>

            </div>
            <div class="margin5 textoInput2  border ">
                <div style="display: flex;">
                    <input type="number" step="any" id="input1101" matInput class="wgray style6c calibriFont" [(ngModel)]="datos.PorCiento.totalFiexed"
                        [ngStyle]="{'color': datos.PorCiento.totalFiexed < 0 ? 'red':'black'}" readonly>
                    <!--<p class="calibriFont style6c ">&nbsp;%</p>-->
                </div>
            </div>
        </div>
    </div>

    <div class="row" class="marginwhite2"><!--12 net Income-->
        <div class="grid-container style2aBold text-right calibriFont">
            <div class="marginRigth colorblue textoazul">{{getSingleTextLabel('42')}} </div>
            <div class="textoblanco textRigth">
                <div style="display: inline-flex;">

                    <span class="equal" (click)="updateInput('topDownApproch', 'netIncome', 90)"
                        style="text-align: right; height: 1px; font-size: 20px; cursor: pointer; width: 10%;">&#61;</span>
                </div>
            </div>
            <div class="margin5 textoInput2  border  wbluestrong">
                <input type="number" step="any" id="input1101" matInput class="wbluestrong style7b calibriFont" readonly
                    [(ngModel)]="datos.topDownApproch.netIncome"
                    (change)="updateInput('topDownApproch', 'netIncome', 5)"
                    [ngStyle]="{'color': datos.topDownApproch.netIncome < 0 ? 'red':'black'}">
            </div>
            <div class="margin5 textoInput2  border ">
                <input type="number" step="any" id="input1101" matInput class="w style7b calibriFont borderEditables" [(ngModel)]="datos.bottomUpApproch.netIncome"
                    placeholder="0" (change)="updateInput('bottomUpApproch', 'netIncome', 5)"
                    [ngStyle]="{'color': datos.bottomUpApproch.netIncome < 0 ? 'red':'black'}">

            </div>
            <div class="margin5 textoInput2  border ">
                <input type="number" step="any" id="input1101" matInput class="w style7b calibriFont borderEditables" [(ngModel)]="datos.Target.netIncome"
                    placeholder="0" (change)="updateInput('Target', 'netIncome', 5)"  [disabled]="!canEditTarget"
                    [ngStyle]="{'color': datos.Target.netIncome < 0 ? 'red':'black'}">

            </div>
            <div class="margin5 textoInput2  border ">
                <input type="number" step="any" id="input1101" matInput class="wgraystrong style7c calibriFont" [(ngModel)]="datos.Actual.netIncome"
                    [ngStyle]="{'color': datos.Actual.netIncome < 0 ? 'red':'black'}" readonly>

            </div>
            <div class="margin5 textoInput2  border ">
                <input type="number" step="any" id="input1101" matInput class="wgraystrong style7c calibriFont" [(ngModel)]="datos.Raw.netIncome"
                    [ngStyle]="{'color': datos.Raw.netIncome < 0 ? 'red':'black'}" readonly>

            </div>
            <div class="margin5 textoInput2  border ">
                <div style="display: flex;">
                    <input type="number" step="any" id="input1101" matInput class="wgraystrong style7c calibriFont"
                        [(ngModel)]="datos.PorCiento.netIncome"
                        [ngStyle]="{'color': datos.PorCiento.netIncome < 0 ? 'red':'black'}" readonly>
                    <!--<p class="calibriFont style7c ">&nbsp;%</p>-->
                </div>
            </div>
        </div>
    </div>
    <div style="text-align: center; display: block !important; margin-top: 14px;">
        <button [disabled]="this.canEditTarget == false" class="btn btn-primary mx-3 styleButtonOK calibriFont"
        (click)="fnCloseModal()">&nbsp;Save Target&nbsp;</button>
    </div>
    
</mat-dialog-content>
<!-- <mat-dialog-actions align="center">
    <button mat-button class="btn btn-secondary mx-3" (click)="matDialogRef.close()" style="Background-color:#808080; color:white; font-family: 'Montserrat';">Close</button>
</mat-dialog-actions> -->
<!-- <div mat-dialog-actions style="text-align: center; display: block !important">
    <button [disabled]="this.canEditTarget == false" class="btn btn-primary mx-3 styleButtonOK calibriFont"
        (click)="fnCloseModal()">&nbsp;Save Target&nbsp;</button>
</div> -->