import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { BoardService } from 'src/app/services/board/board.service';
import { BalanceIncomeService } from 'src/app/services/sockets/balance-income/balance-income.service';

class ListCheckbox{
  labels:string[] = [];
  checkList: Array<number> | any = [];
}
class CheckBox{
  CheckListId: number;
  idCheck: number;
  valor: number;
  TeamId: number;
  title: string;

  constructor(object:{CheckListId: number, idCheck: number, valor: number, TeamId: number,title: string}){
    this.CheckListId = object['CheckListId'];
    this.idCheck = object['idCheck'];
    this.valor = object['valor'];
    this.TeamId = object['TeamId'];
    this.title = object['title'];
  }
}

class CheckList{
  checkList: Array<CheckBox> = [];

  constructor(json:[]){
    json.forEach(item => {
      this.checkList.push(new CheckBox(item));
    })
  }
  codeCheckList(){
    return JSON.stringify(this.checkList);
  }
}

export class TeamCheckList{
  tableCheck:any;
  checkList: CheckList;
  Status: number;
  Month: number;
  Avatar: string;
  Color: string;
  Mode: number;
  Room: string;
  TeamId: number;
  WokshopId: number;
  constructor(json: any){
    this.checkList = new CheckList(json['checkList']);
    this.Status = json['Status'];
    this.Month = json['Month'];
    this.Avatar = json['Avatar'];
    this.Color = json['Color'];
    this.Mode = json['Mode'];
    this.Room = json['Room'];
    this.TeamId = json['TeamId'];
    this.WokshopId = json['WokshopId'];
  }
}


@Component({
  selector: 'app-dialog-logs',
  templateUrl: './dialog-logs.component.html',
  styleUrls: ['./dialog-logs.component.scss']
})
export class DialogLogsComponent implements OnInit {
  workshopId:number;
  lstTeamsInfo: Array<TeamCheckList> = [];
  lstUsersInfo = [];
  teamLogs = [];
  pageSizeOptions: number[] = [1, 5, 10, 25, 100];
  dataSourceTable: MatTableDataSource<any> = new MatTableDataSource([]);
  titulos: string[] = [
   // 'socket_name',
    'label',
    'SocketId'
  ];

  arrTypeStacks = ["","cash","captial stock", "loans", "capEx", "machine", "acuman","material/blue","royal blue(3/3)", "cash&loans","machine customizing", "Fields/expansion", "acuman/yellow", "<f13>", "material/green", "<f15>", "royal green/silver(1/3)", "royal blue/silver(3/2)", "royal green/silver(1/2)"]
/*
0 ponemos posicion 0 como "" para acceder directo al valor por el tipo de stack
1	cash
2	captial stock
3	loans
4	capEx
5	maquina
6	persona
7	materials	
8	royal blue(3/3)
9	cash&loans
10 maquina de customizing
11 Fields/expansion
12 acuman/yellow
14 material/green
16 royal green/silver(1/3)
17 royal blue/silver(3/2)
18 royal green/silver(1/2)
*/
  constructor(
    public matDialogRef: MatDialogRef<DialogLogsComponent>,
    private spinner: NgxSpinnerService,
    private boardService: BoardService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private balanceIncomeService: BalanceIncomeService,) { }

  ngOnInit(): void {
    console.log("DialogLogsComponent Data recibida:",this.data);
    this.workshopId = parseInt(this.data.workshopId);
    this.lstUsersInfo = this.data.teamsData;
    this.spinner.show();
    this.boardService.getTeamsChecklistByWorkshopId(this.workshopId).subscribe((response:any) =>{
      if(response.intResponse == 200){
        response.data.checklist.forEach(team => {
          let aux = new TeamCheckList(team);
          this.lstTeamsInfo.push(aux);
          
        });
        this.spinner.hide();
      }else{
        if(response.intResponse != 200){
          console.log(response.strAnswer);
          alert("Codigo de Error: "+response.strAnswer)
          this.spinner.hide();
        }
      }
    })
    console.log(this.lstTeamsInfo,this.lstUsersInfo);
  }
  selectTeam(objteam){
    this.spinner.show();
    console.log(objteam.Room);
    this.boardService.getTeamLogs(objteam.Room).subscribe((response:any) => {
      if(response.intResponse == 200){
        console.log(response.logs);
        this.teamLogs = response.logs;
        this.teamLogs.forEach(registro =>{
          this.setLabel(registro);
        })
        this.dataSourceTable = new MatTableDataSource(this.teamLogs);
        this.spinner.hide();
      }else{
        if(response.intResponse != 200){
          console.log(response.strAnswer);
          alert("Codigo de Error: "+response.strAnswer)
          this.spinner.hide();
        }
      }
    })
  }

  setLabel(registro){
    switch (registro['socketName']) {
      case 'join':
          registro['label'] = this.isJoin(registro['dtelog'],registro['room'],registro['userId']);
        break;
      case 'disableControl':
          registro['label'] = this.isdisableControl(registro['dtelog'],registro['userId']);
        break;
      case 'disablePiece':
          registro['label'] = this.isdisablePiece(registro['dtelog'],registro['userId'], registro['container'],registro['id'],registro ['type'], registro['valor'] ,registro['statusPiece'])
        break;
      case 'deleteCoin':
          registro['label'] = this.isdeleteCoin(registro['dtelog'],registro['room'], registro['container'],registro['id'],registro ['type'], registro['valor'])
        break;  
      case 'coinDraggingBySelf':
          registro['label'] = this.iscoinDraggingBySelf(registro['dtelog'],registro['userId'], registro['container'],registro['id'],registro ['type'], registro['valor'])
        break;
      case 'updateTeamStatus':
          registro['label'] = this.isupdateTeamStatus(registro['dtelog'],registro['room'], registro['status']);
        break; 
      case 'createCoin':
          registro['label'] = this.iscreateCoin(registro['dtelog'],registro['room'], registro['container'],registro['id'],registro ['type'], registro['valor']) 
        break; 
      case 'setBlnControlModeOpen':
          registro['label'] = this.issetBlnControlModeOpen(registro['dtelog'],registro['room'], registro['idBln'],registro['statusBln'])
        break;
      case 'coinDroppedBySelf':
          registro['label'] = this.iscoinDroppedBySelf(registro['dtelog'],registro['room'],registro['userId'], registro['container'],registro['id'],registro ['type'], registro['valor'])  
        break;
      case 'splitCoin':  
          registro['label'] = this.isSplitCoin(registro['dtelog'],registro['room'], registro['container'],registro['jsonList'])
        break;
      case 'setListOrderDeliver': 
        registro['label'] = this.isSetListOrderDeliver(registro['dtelog'],registro['room'], registro['container'],registro['jsonList'])
        break;
      case 'deleteOrder': 
        registro['label'] = this.isdeleteOrder(registro['dtelog'],registro['room'],registro['jsonOrder'])
        break;
      case 'payGrossProfit': 
        registro['label'] = this.ispayGrossProfit(registro['dtelog'],registro['room'],registro['id'], registro['type'], registro['valor'])
        break;
      case 'deliverOrder': 
        registro['label'] = this.isdeliverOrder(registro['dtelog'],registro['room'],registro['jsonOrder'])
        break;
      case 'updateCheckList': 
        registro['label'] = this.isupdateCheckList(registro['dtelog'],registro['room'],registro['checklist'])
        break;
      case 'updateCheckList': 
        registro['label'] = this.isaskImprovement(registro['dtelog'],registro['room'])
        break;
      case 'borrowFromBank': 
        registro['label'] = this.isborrowFromBank(registro['dtelog'],registro['room'],registro['container'], registro['type'] ,registro['valor'] )
        break;
      case 'facilitatorUpdStatus':  
        registro['label'] = this.isfacilitatorUpdStatus(registro['dtelog'],registro['room'],registro['status']);
        break;
      case 'facilitatorCreatePiece':  
        registro['label'] = this.isfacilitatorCreatePiece(registro['dtelog'],registro['room'],registro['container'],registro['id'], registro['type'] ,registro['valor'] );
        break;
      case 'facilitatorDeletePiece':  
        registro['label'] = this.isfacilitatorDeletePiece(registro['dtelog'],registro['room'],registro['container'],registro['id'], registro['type'] ,registro['valor'] );
        break;
      case 'facilitatorUpdatePiece':  
        registro['label'] = this.isfacilitatorUpdatePiece(registro['dtelog'],registro['room'],registro['container'],registro['id'], registro['type'] ,registro['valor'] );
        break;
      case 'facilitatorUpdateCheckList':
        registro['label'] = this.isfacilitatorUpdateCheckList(registro['dtelog'],registro['room'],registro['checklist'])  
        break;
      case 'UpdateMonth':
        registro['label'] = this.isUpdateMonth(registro['dtelog'],registro['room'],registro['month'])
        break;
      case 'DISCONECT':
          registro['label'] = this.isDisconnect(registro['dtelog'],registro['ipAddress'])
          break;
        default:
    }
  }
  getAvatar(roomID){
    let auxString = ""
    this.lstTeamsInfo.forEach(team =>{
      if(team.Room == roomID){
        auxString = team.Avatar;
      }
    })
    return auxString;
  }
  getUserName(userID){
    let auxString = "";
    this.lstUsersInfo.forEach(user =>{
      if(user.UserID == userID){
        auxString = user.FirstName;
      }
    }) 
    return auxString;
  }
  getDate(date){
    let strDate = new Date (date);
    let auxDate = `${(strDate.getUTCHours()).toString().padStart(2,'0')}:${(strDate.getUTCMinutes()).toString().padStart(2,'0')}:${(strDate.getUTCSeconds()).toString().padStart(2,'0')}`
    return auxDate;
  }

  

  ////////////////////////////Funciones de logs //////////////////////////
  isJoin(date,room,userId){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    let strUserName = this.getUserName(userId);
    let label = `${strDate} JOIN ${strUserName} a ${strAvatar}`
    return label;
  }
  isdisableControl(date,userId){
    let strDate = this.getDate(date);
    let strUserName = this.getUserName(userId);
    let label = `${strDate} ${strUserName} solicita control`
    return label;
  }
  isdisablePiece(date,userId,container,containerId,type,value,statusPiece){
    let strDate = this.getDate(date);
    let strUserName = this.getUserName(userId);
    if(statusPiece==1){
      let label = `${strDate} ${strUserName} deshabilita pieza [${container}, ${this.arrTypeStacks[type]}, ${value}, ${containerId}]`
      return label
    }else{
      let label = `${strDate} ${strUserName} habilita pieza [${container}, ${this.arrTypeStacks[type]}, ${value}, ${containerId}]`
      return label
    }
  }
  isdeleteCoin(date,room,container,containerId,type,value){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    let label = `${strDate} ${strAvatar} elimina pieza [${container}, ${this.arrTypeStacks[type]}, ${value}, ${containerId}]`
    return label;
  }
  iscoinDraggingBySelf(date,userId,container,containerId,type,value){
    let strDate = this.getDate(date);
    let strUserName = this.getUserName(userId);
    let label = `${strDate} ${strUserName} mueve moneda de Sell, Gen & Adm [${container}, ${this.arrTypeStacks[type]}, ${value}, ${containerId}]`;
    return label
  }
  isupdateTeamStatus(date,room,status){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    let label = `${strDate} ${strAvatar} se actualiza a status ${status}`;
    return label
  }
  iscreateCoin(date,room,container,containerId,type,value){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    let label = `${strDate} Pieza creada para ${strAvatar} [${container}, ${this.arrTypeStacks[type]}, ${value}, ${containerId}]`
    return label;
  }
  issetBlnControlModeOpen(date,room,idBln,statusBln){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    if(statusBln == 1){
      statusBln = 'TRUE'
    }else{
      statusBln = 'FALSE'
    }
    let label = `${strDate} La bandera ${idBln} se ha modificado a ${statusBln} para ${strAvatar}`
    return label;  
  }
  iscoinDroppedBySelf(date,room,userId,container,containerId,type,value){
    let strDate = this.getDate(date);
    let strUserName = this.getUserName(userId);
    let strAvatar = this.getAvatar(room);
    let label = `${strDate} En ${strAvatar}, ${strUserName} suelta pieza en [${container}, ${this.arrTypeStacks[type]}, ${value}, ${containerId}]`;
    return label;
  }

  isSplitCoin(date,room, container,jsonList){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    let arrJson = JSON.parse(jsonList);
    let strStacksInfo = this.getStackInfoFormatted(arrJson);
    //4:37:00 (SPLITCOIN) para 'avatar' en 'container'. Nuevos elementos [id,type,value]
    //[{"valor": 1, "type": 1, "id": 190488, "draggable": false}, {"valor": 20, "type": 1, "id": 190489, "draggable": false}, {"valor": 7, "type": 1, "id": 190490, "draggable": false}]
    let label = `${strDate} (SPLITCOIN) para ${strAvatar} en ${container}. Nuevos elementos ${strStacksInfo}`
    return label
  }

  isSetListOrderDeliver(date,room, container,jsonList){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    let arrJson = JSON.parse(jsonList);
    let strStacksInfo = this.getStackInfoFormatted(arrJson);
    //4:38:30 'avatar' recibe pago de orden en receivables: [container,type,value,id] 
    //[{"valor": 1, "type": 1, "id": 190488, "draggable": false}, {"valor": 20, "type": 1, "id": 190489, "draggable": false}, {"valor": 7, "type": 1, "id": 190490, "draggable": false}]
    let label = `${strDate}  ${strAvatar} recibe pago de orden en receivables: ${container}. stacks-> ${strStacksInfo}`
    return label
  }

  isdeleteOrder(date,room,jsonOrder){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    /*let arrJson = JSON.parse(jsonList);
    let strStacksInfo = this.getStackInfoFormatted(arrJson);*/
    //  4:39:00 'avatar' borra una orden:  [OrderId,OrderNum,Quantity,Terms] 			 //11
    //[{"valor": 1, "type": 1, "id": 190488, "draggable": false}, {"valor": 20, "type": 1, "id": 190489, "draggable": false}, {"valor": 7, "type": 1, "id": 190490, "draggable": false}]
    let label = `${strDate}  ${strAvatar}  borra una orden: ${jsonOrder}`
    return label
  }

  ispayGrossProfit(date,room, id, type, valor){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    let label = `${strDate}  ${strAvatar} pago admins: (${this.arrTypeStacks[type]}, ${valor}, ${id})`
    return label
  }

  isdeliverOrder(date,room,jsonOrder){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    let objJsonOrder = JSON.parse(jsonOrder)
     let label = `${strDate}  ${strAvatar} hace deliver: [${objJsonOrder.OrderId},${objJsonOrder.OrderNum},${objJsonOrder.Quantity},${objJsonOrder.Terms}]`
    return label
  }

  isupdateCheckList(date,room,checklist){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    let jsonCheckList = JSON.parse(checklist);
    let strInfo = this.getChecklistInfoFormatted(jsonCheckList);
    /*[{\"blnCheck\":false,\"disabled\":true,\"id\":1,\"idBd\":58891,\"title\":\"Clear Income Statement\"},
    4:36:45  [Substring : blnCheck] <array>     */        	 
    //[{"valor": 1, "type": 1, "id": 190488, "draggable": false}, {"valor": 20, "type": 1, "id": 190489, "draggable": false}, {"valor": 7, "type": 1, "id": 190490, "draggable": false}]
    let label = `${strDate}  ${strAvatar} se actualiza checklist [${strInfo}]`
    return label
  }

  isaskImprovement(date,room){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    /*let jsonCheckList = JSON.parse(checklist);
    let strInfo = this.getChecklistInfoFormatted(jsonCheckList);*/
    //4:37:30  'avatar'       	 
   let label = `${strDate} preguntar por improvement a ${strAvatar}`
    return label
  }

  isborrowFromBank(date,room,container,type,valor){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    //4:37:30  'avatar'       	 
   let label = `${strDate} ${strAvatar} toma prestamo:(${container},${this.arrTypeStacks[type]},${valor})`
    return label
  }

  isfacilitatorUpdStatus(date,room,status){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    let label = `${strDate} *FACILITATOR* actualiza status ${status} a ${strAvatar}`
    return label
  }
  isfacilitatorCreatePiece(date, room, container, id, type, value){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    let label = `${strDate} *FACILITATOR* crea una pieza a ${strAvatar}: [${container}, ${this.arrTypeStacks[type]}, ${value}, ${id}]`
    return label
  }
  isfacilitatorDeletePiece(date, room, container, id, type, value){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    let label = `${strDate} *FACILITATOR* borra una pieza a ${strAvatar}: [${container}, ${this.arrTypeStacks[type]}, ${value}, ${id}]`
    return label
  }
  isfacilitatorUpdatePiece(date, room, container, id, type, value){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    let label = `${strDate} *FACILITATOR* actualiza una pieza a ${strAvatar}: [${container}, ${this.arrTypeStacks[type]}, ${value}, ${id}]`
    return label
  }
  isfacilitatorUpdateCheckList(date, room, checklist){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    let jsonCheckList = JSON.parse(checklist);
    console.log(jsonCheckList);
    let strInfo = this.getChecklistInfoFormattedForFacilitators(jsonCheckList);
    let label = `${strDate} *FACILITATOR* ${strAvatar} se actualiza checklist [${strInfo}]`
    return label
  }
  isUpdateMonth(date,room,month){
    let strDate = this.getDate(date);
    let strAvatar = this.getAvatar(room);
    let label = `${strDate} ${strAvatar} se actualiza a mes ${month}`;
    return label
  }
  isDisconnect(date,ipAddress){
    let strDate = this.getDate(date);
    let label = `${strDate} Desconexion desde IP: ${ipAddress}`;
    return label
  }
  getChecklistInfoFormatted(jsonCheckList){
    let strFormatted = "";
    jsonCheckList.forEach(objCheck => {
      strFormatted += objCheck.title + ":" + objCheck.blnCheck+", ";
    });
    return strFormatted;
  }
  getChecklistInfoFormattedForFacilitators(jsonCheckList){
    let strFormatted = "";
    jsonCheckList.forEach(objCheck => {
      strFormatted += objCheck.title + ":" + objCheck.valor+", ";
    });
    return strFormatted;
  }

  getStackInfoFormatted(jsonList){
    let strFormatted = "";
    jsonList.forEach(objStack => {
      strFormatted += "(" + this.arrTypeStacks[objStack.type] + ", " + objStack.valor +") ";
    }); 
    return strFormatted;
  }

}
