import {
  CdkDrag,
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { UsuarioService } from './../../services/usuario/usuario.service';
import { stringify } from 'querystring';
@Component({
  selector: 'app-bulk-load',
  templateUrl: './bulk-load.component.html',
  styleUrls: ['./bulk-load.component.scss'],
})
export class BulkLoadComponent implements OnInit {
  //#endregion
  constructor(
    public matDialogRef: MatDialogRef<BulkLoadComponent>,
    private _fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private usuarioService: UsuarioService,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    console.log('this._data', this._data);
    this.lstOfTeamMembers = this._data.lstOfTeamMembers;
    this.ltsOfTeams = this._data.ltsOfTeams;
    this.lstFacs = this._data.facilitatorEmails;
    this.lstOfContacts = this._data.constactsEmails;
    this.wsStarted = this._data.wsStarted;
    this.teamsCount = this._data.teamsCount;
    this.lstOfNewTeamMembers = this._data.lstOfNewTeamMembers;
    let intStartDate = Date.parse(this._data.startDate);
    let date: Date = new Date();
    console.log(intStartDate, date.getTime());
    intStartDate = Number.isNaN(intStartDate) ? Number.POSITIVE_INFINITY : intStartDate;
    // this.canEditTeamMemebers = intStartDate >= date.getTime();
    this.ltsOfTeams.forEach(item => {
      item.teamMembers.forEach(teamMemeber => {
        teamMemeber.id = this.consecutiveId;
        this.consecutiveId++;
      });
    });
  }

  //#region variables

  @Output() teamMembers = new EventEmitter<any>();

  filename = '';
  fileForm: FormGroup;
  teemMemberForm: FormGroup;
  // @Input()
  lstOfTeamMembers = [];
  // @Input()
  auxTeamMembers = [];
  lstOfNewTeamMembers = [];
  lstOfModifiedTeamMembers = [];
  // @Input()
  teamsCount;

  // @Input()
  ltsOfTeams = [
    {
      TeamId: 1,
      TeamName: 'Team 1',
      teamMembers: [],
      TeamIdBD: null
    },
    {
      TeamId: 2,
      TeamName: 'Team 2',
      teamMembers: [],
      TeamIdBD: null
    },
    {
      TeamId: 3,
      TeamName: 'Team 3',
      teamMembers: [],
      TeamIdBD: null
    },
    {
      TeamId: 4,
      TeamName: 'Team 4',
      teamMembers: [],
      TeamIdBD: null
    },
  ];
  lstOfOldTeams = [];
  lstFacs = [];
  lstOfContacts = [];
  wsStarted = false;
  teamCount = -1;
  ltsOfTeamsData = [[], []];
  stringOfTeamsMembers = '';
  consecutiveId = 0;
  lstOfAllTeamMembers = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSize = 20;
  pageSizeOptions: number[] = [1, 5, 10, 25, 100];

  isEdit = false;
  lstOfOldTeamMembers = [];
  isEdited = false;
  lstOfOldNewTeamMembers = [];
  isEditContact = -1;
  loading = false;
  titulos: string[] = ['firstName', 'lastName', 'jobTitle', 'email', 'teamNumber', 'actions'];

  dataSourceTable = new MatTableDataSource([]);
  isResponseErrorEmailAlreadyRegister = false;
  //#endregion

  todo = ['Get to work', 'Pick up groceries', 'Go home', 'Fall asleep'];

  done = [];

  // canEditTeamMemebers = true;

  lstOfDragabbles = [
    {
      teamId: 1,
      lstName: 'Team 1',
      teamMembers: [],
    },
    {
      teamId: 2,
      lstName: 'Team 2',
      teamMembers: [],
    },
    {
      teamId: 3,
      lstName: 'Team 3',
      teamMembers: [],
    },
    {
      teamId: 4,
      lstName: 'Team 4',
      teamMembers: [],
    },
  ];

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.workshopID != 0) {
        this.isEdit = true;
        // this.lstOfTeamMembers = this.lstOfNewTeamMembers.concat(this.lstOfTeamMembers);
      }
    });

    this.fileForm = this._fb.group({
      file: [undefined, [Validators.required]],
    });
    this.fnCrearForm('', '', '', '',null);
    if (this.lstOfTeamMembers.length > 0) {
      this.loading = true;
    }
    this.lstOfOldTeamMembers = [...this.lstOfTeamMembers];
    for(let j = 0; j<this.ltsOfTeams.length; j++){
      this.lstOfOldTeams.push({
        teamId: j+1,
        lstName: 'Team '+(j+1),
        teamMembers: [...this.ltsOfTeams[j].teamMembers],
      });
    }
    const lenght = this.ltsOfTeams.length;
    for(let k = 0; k<(4-lenght); k++){
      this.ltsOfTeams.push({
        TeamId: k+1+lenght,
        TeamName: 'Team '+(k+1+lenght),
        teamMembers: [],
        TeamIdBD: null
      });
    }
    console.log("TEAMS",this.ltsOfTeams);
    console.log("OLD TEAMS",this.lstOfOldTeams);
    this.lstOfOldNewTeamMembers = Array.from(this.lstOfNewTeamMembers);

    this.lstOfAllTeamMembers = [...this.lstOfTeamMembers];
    if (this.teamsCount > 0) {
      console.log(this.lstOfTeamMembers, 'lst Of Team Members');
      this.lstOfAllTeamMembers = this.lstOfTeamMembers.filter(
        (user) => user.TeamId == -1
      );
    }
  
    this.auxTeamMembers = this.lstOfTeamMembers;
    console.log("AUXXXX",this.auxTeamMembers)
    this.dataSourceTable = new MatTableDataSource(this.lstOfTeamMembers);
    this.dataSourceTable.paginator = this.paginator;
  }
  ngAfterViewInit() {
    this.dataSourceTable.paginator = this.paginator;
  }
  //#region functions

  fnCrearForm(firstName, lastName, email, jobTitle, teamNumber) {
    this.teemMemberForm = new FormGroup({
      firstName: new FormControl(firstName, [Validators.required]),
      lastName: new FormControl(lastName, [Validators.required]),
      email: new FormControl(email, [
        Validators.required,
        Validators.email,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ]),
      file: new FormControl(undefined, []),
      jobTitle: new FormControl(jobTitle, []),
      teamNumber: new FormControl(teamNumber, [])
    });
  }

  fileLoad(event) {
    console.log(event);
    this.filename = event.target.files[0].name;
    Swal.fire({
      html: `<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 93.- Are you sure you want to use the document \" ${this.filename} \" to create a teams? </label>`,
      imageUrl: '../../../../assets/iconPrevention.svg',
      showCancelButton: true,
      confirmButtonColor: '#6FB45C',
      cancelButtonColor: '#ED5050',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then((result) => {
      console.log(result,event);
      if (result.isConfirmed) {
        const arrayOfFiles: FileList = event.target.files;
        console.log(arrayOfFiles);
        arrayOfFiles;
        this.spinner.show();
        for (let i = 0; i < arrayOfFiles.length; i++) {
          const fileData = arrayOfFiles.item(i);
          // Show preview
          const mimeType = fileData.type;
          if (
            mimeType.match(
              /application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet/
            ) == null &&
            mimeType.match(/text\/csv/) == null
          ) {
            console.log('Archivo invalido', fileData.name);
            // alert("archivo invalido" + fileData.name);
            return;
          }
          console.log(event);
          const reader = new FileReader();

          try {
            for (let i = this.ltsOfTeams.length; i < 6; i++) {
              this.ltsOfTeams.push({
                TeamId: this.ltsOfTeams.length + 1,
                TeamName: 'Team ' + (this.ltsOfTeams.length + 1),
                teamMembers: [],
                TeamIdBD: null
              });
            }
            this.teamCount = 6;
            new Promise((resolve, reject) => {
              reader.onload = async function (e) {
                const jsonRequest = [];
                const data = new Uint8Array(e.target.result as ArrayBufferLike);
                const workbook = XLSX.read(data, { type: 'array' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const sheet = XLSX.utils.sheet_to_json(worksheet, {
                  header: 1,
                }).filter((row: Array<any>) => row.length);
                const columnas = [
                  'FirstName',
                  'LastName',
                  'JobTitle',
                  'Email',
                  'TeamId',
                ];
                sheet.forEach((arrayDatos: Array<any>) => {
                  const [name, lastName, title, email, team] = arrayDatos;
                  console.log({name, lastName, title, email, team})
                  const tmpTeamId = +(team == 0 ? -1 : team > 6 ? 6 : team);
                  const jsonAux = {
                    FirstName: name,
                    LastName: lastName,
                    JobTitle: title,
                    Email: email.trim(),
                    TeamId: isNaN(tmpTeamId) ? -1 : tmpTeamId,
                    UserID: -1,
                  };
                  if(jsonAux['FirstName'] || jsonAux['LastName'] || jsonAux['FirstName'] || jsonAux['Email'])
                    jsonRequest.push(jsonAux);
                });
                resolve(jsonRequest);
              };
              reader.onerror = () => {
                reject(new Error('Unable to read...'));
              };
            }).then((result: Array<any>) => {
              this.spinner.hide();
              const arrayErrors: { id: number, errors: string[] }[] = [];
              result.forEach((value, index) => {
                const teamMember = {
                  UserID: -1,
                  FirstName: value.FirstName ?? '',
                  LastName: value.LastName ?? '',
                  Email: value.Email ?? '',
                  JobTitle: value.JobTitle ?? '',
                  type: 3,
                  TeamId: value.TeamId ?? -1,
                  id: this.consecutiveId,
                }
                const emailLower = value.Email.toLowerCase();
                const emailExistsInFacs = this.lstFacs.some(email => email.toLowerCase() === emailLower);
                const emailExistsInContacts = this.lstOfContacts.some(contact => contact.Email.toLowerCase() === emailLower);
                const emailExistsInTeamMembers = this.lstOfAllTeamMembers.some(member => member.Email.toLowerCase() === emailLower);
                const emailExistsInTeamMembers2 = this.lstOfTeamMembers.some(member => member.Email.toLowerCase() === emailLower);
                const arrayErrorsDescripction: string[] = [];
                if (value.FirstName === undefined && !emailExistsInFacs && !emailExistsInContacts && !emailExistsInTeamMembers && !emailExistsInTeamMembers2) {
                  arrayErrorsDescripction.push(`First Name was not found`)
                };
                if (value.LastName === undefined && !emailExistsInFacs && !emailExistsInContacts && !emailExistsInTeamMembers && !emailExistsInTeamMembers2) {
                  arrayErrorsDescripction.push(`Last Name was not found`)
                };
                if (value.Email === undefined && !emailExistsInFacs && !emailExistsInContacts && !emailExistsInTeamMembers && !emailExistsInTeamMembers2) {
                  arrayErrorsDescripction.push(`Email was not found and it´s a required field`)
                };
                if (value.JobTitle === undefined && !emailExistsInFacs && !emailExistsInContacts && !emailExistsInTeamMembers && !emailExistsInTeamMembers2) {
                  arrayErrorsDescripction.push(`Job Title was not found`)
                };
                if (value.TeamId === -1 && !emailExistsInFacs && !emailExistsInContacts && !emailExistsInTeamMembers && !emailExistsInTeamMembers2) {
                  arrayErrorsDescripction.push(`Team id was not found, it must be a number between 1 and 6`)
                };
                if (emailExistsInFacs || emailExistsInContacts || emailExistsInTeamMembers || emailExistsInTeamMembers2) {
                  arrayErrorsDescripction.push(`Email is already in the exclusion lists, skipping.`)
                };
                if (arrayErrorsDescripction.length > 0) {
                  arrayErrors.push({ id: index + 1, errors: arrayErrorsDescripction });
                }
                //Saltar el jugador si está repetido
                if (emailExistsInFacs || emailExistsInContacts || emailExistsInTeamMembers || emailExistsInTeamMembers2) {
                  Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: `Email ${value.Email} is already in the exclusion lists, skipping.`,
                    showConfirmButton: false,
                    timer: 3000,
                    toast: true
                  });
                  console.log(`Email ${value.Email} está repetido ya.`);
                  return; 
                }
                this.lstOfTeamMembers.push(teamMember);
                this.lstOfAllTeamMembers.push({ ...teamMember });
                this.consecutiveId++;
              });
              this.loading = true;
              if (arrayErrors.length > 0) {
                const html = `
                <div style="text-align: left; font-size: 16px;">
                <p>Some rows don´t match with the required format:</p>
                <table style="width: 100%; margin-bottom: 5px; text-align: center;">
                  <head>
                    <tr>
                      <th style="border: 1px solid;">First Name</th>
                      <th style="border: 1px solid;">Last Name</th>
                      <th style="border: 1px solid;">Job Title</th>
                      <th style="border: 1px solid;">Email</th>
                      <th style="border: 1px solid;">Team Id</th>
                    </tr>
                  </head>
                </table>
                ${arrayErrors.reduce((acc, error) => {
                  return acc + `
                    <p>Row: ${error.id}</p>
                    <ul>
                      ${error.errors.reduce((acc, description) => {
                    return acc + `<li>${description}</li>`;
                  }, '')}
                    </ul>`;
                }, '')}
                </div>`;
                Swal.fire({
                  html: html,
                  showConfirmButton: true,
                  confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + "Continue" + '&nbsp;&nbsp;&nbsp; ',
                  confirmButtonColor: '#5EA0CC',
                  showCancelButton: true,
                  cancelButtonColor: '#dc3545',
                  allowOutsideClick: false,
                  title: 'Warning',
                  icon: 'warning'
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    console.log("ACEPTOOOO")
                    this.dataSourceTable.data = this.lstOfTeamMembers;
                    this.dataSourceTable.paginator = this.paginator;
                    console.log(this.lstOfTeamMembers);
                    this.ltsOfTeams[0].teamMembers = this.lstOfTeamMembers.filter(
                      (item) => item.TeamId === 1
                    );
                    this.ltsOfTeams[1].teamMembers = this.lstOfTeamMembers.filter(
                      (item) => item.TeamId === 2
                    );
                    this.ltsOfTeams[2].teamMembers = this.lstOfTeamMembers.filter(
                      (item) => item.TeamId === 3
                    );
                    this.ltsOfTeams[3].teamMembers = this.lstOfTeamMembers.filter(
                      (item) => item.TeamId === 4
                    );
                    this.ltsOfTeams[4].teamMembers = this.lstOfTeamMembers.filter(
                      (item) => item.TeamId === 5
                    );
                    this.ltsOfTeams[5].teamMembers = this.lstOfTeamMembers.filter(
                      (item) => item.TeamId === 6
                    );
                    this.lstOfAllTeamMembers = this.lstOfTeamMembers.filter(
                      (item) => item.TeamId === -1
                    );
                  }else{
                    if(this.lstOfAllTeamMembers.length == 0){
                      
                    }
                    console.log("NO ACEPTOOOO")
                    if(this.auxTeamMembers.length > 0){
                      console.log("HAY MEMBERS");
                      this.lstOfTeamMembers = this.auxTeamMembers;
                      this.lstOfAllTeamMembers = this.auxTeamMembers;
                      console.log(this.lstOfAllTeamMembers,this.auxTeamMembers)
                      this.teemMemberForm.get('file').reset();
                    }else{
                      this.lstOfTeamMembers = [];
                      this.lstOfAllTeamMembers = [];
                      this.filename = '';
                      this.lstOfOldTeams = [];
                      this.lstOfAllTeamMembers = [];
                      this.lstOfTeamMembers = [];
                      this.teemMemberForm.get('file').reset();
                      this.ltsOfTeams = [
                        {
                          TeamId: 1,
                          TeamName: 'Team 1',
                          teamMembers: [],
                          TeamIdBD: null
                        },
                        {
                          TeamId: 2,
                          TeamName: 'Team 2',
                          teamMembers: [],
                          TeamIdBD: null
                        },
                        {
                          TeamId: 3,
                          TeamName: 'Team 3',
                          teamMembers: [],
                          TeamIdBD: null
                        },
                        {
                          TeamId: 4,
                          TeamName: 'Team 4',
                          teamMembers: [],
                          TeamIdBD: null
                        },
                      ];
                    }     
                    console.log(this.lstOfTeamMembers,
                      this.lstOfAllTeamMembers,this.ltsOfTeams,this.lstOfOldTeams,this.teemMemberForm.get('file'))
                  }
                });
              }else{
                this.dataSourceTable.data = this.lstOfTeamMembers;
                    this.dataSourceTable.paginator = this.paginator;
                    console.log(this.lstOfTeamMembers);
                    this.ltsOfTeams[0].teamMembers = this.lstOfTeamMembers.filter(
                      (item) => item.TeamId === 1
                    );
                    this.ltsOfTeams[1].teamMembers = this.lstOfTeamMembers.filter(
                      (item) => item.TeamId === 2
                    );
                    this.ltsOfTeams[2].teamMembers = this.lstOfTeamMembers.filter(
                      (item) => item.TeamId === 3
                    );
                    this.ltsOfTeams[3].teamMembers = this.lstOfTeamMembers.filter(
                      (item) => item.TeamId === 4
                    );
                    this.ltsOfTeams[4].teamMembers = this.lstOfTeamMembers.filter(
                      (item) => item.TeamId === 5
                    );
                    this.ltsOfTeams[5].teamMembers = this.lstOfTeamMembers.filter(
                      (item) => item.TeamId === 6
                    );
                    this.lstOfAllTeamMembers = this.lstOfTeamMembers.filter(
                      (item) => item.TeamId === -1
                    );
              }
              
            });
            // promise.then((result: Array<any>) => {
            //   this.spinner.hide();
            //   console.log(result);
            //   this.lstOfTeamMembers = result;
            // }).catch(reason => {
            //   this.spinner.hide();
            //   console.log("ERROOOOOOR", reason);
            // });
            reader.readAsArrayBuffer(fileData);
          } catch (e) {
            console.log('Exception', e);
          }
        }
      }
    });
  }

  addContactTable() {
    console.log(this.teemMemberForm.get('teamNumber').value);
    if(this.teemMemberForm.get('teamNumber').value > this.ltsOfTeams.length){
      Swal.fire({
        text: '',
        icon: 'error',
        html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">94.- This team not exists</label>`,
      });
      return;
    }
    this.loading = true;
    if (this.isEditContact != -1) {
      let isAlreadyOnLstAllTeamMemebers = this.lstOfAllTeamMembers.some(
        (member) => member.id == this.lstOfTeamMembers[this.isEditContact].id
      )
      this.lstOfTeamMembers[
        this.isEditContact
      ].FirstName = this.teemMemberForm.get('firstName').value;
      this.lstOfTeamMembers[
        this.isEditContact
      ].LastName = this.teemMemberForm.get('lastName').value;
      this.lstOfTeamMembers[this.isEditContact].Email = this.teemMemberForm.get(
        'email'
      ).value;
      this.lstOfTeamMembers[
        this.isEditContact
      ].JobTitle = this.teemMemberForm.get('jobTitle').value;
      this.lstOfTeamMembers[
        this.isEditContact
      ].TeamId = (this.teemMemberForm.get('teamNumber').value)?
      this.teemMemberForm.get('teamNumber').value:-1;
      let tempId = this.lstOfTeamMembers[this.isEditContact].id;
      if (isAlreadyOnLstAllTeamMemebers) {
        if(this.teemMemberForm.get('teamNumber').value == null){
          this.lstOfAllTeamMembers[
            this.isEditContact
          ].FirstName = this.teemMemberForm.get('firstName').value;
          this.lstOfAllTeamMembers[
            this.isEditContact
          ].LastName = this.teemMemberForm.get('lastName').value;
          this.lstOfAllTeamMembers[
            this.isEditContact
          ].Email = this.teemMemberForm.get('email').value;
          this.lstOfAllTeamMembers[
            this.isEditContact
          ].JobTitle = this.teemMemberForm.get('jobTitle').value;
          this.lstOfAllTeamMembers[
            this.isEditContact
          ].TeamId = (this.teemMemberForm.get('teamNumber').value)?
              this.teemMemberForm.get('teamNumber').value : -1;
          }else {
            console.log("ingresaron valor en team number:: ", this.lstOfAllTeamMembers);
            this.lstOfAllTeamMembers.splice(this.isEditContact,1);
            console.log("ingresaron valor en team number:: ", this.lstOfAllTeamMembers);
          }
        } 
        // LISTO
        console.log("lstofTeamMembers",this.lstOfTeamMembers)
        let userId;
        this.ltsOfTeams.forEach((value) => {
          value.teamMembers.forEach((member, i) => {
            if (member.id == this.lstOfTeamMembers[this.isEditContact].id) {
              console.log("Eliminar",value.teamMembers[i]);
              console.log(member.id )
              tempId = member.id;
              userId = member.UserID;
              value.teamMembers.splice(i,1);
            }
          });
        });
        console.log("temID::::::: ",tempId);
        console.log("LST OF AL TEAMS DESPUE DE ELIMINARLO",this.ltsOfTeams);
      // ---- Agregar al nuevo equipo-----
        if(this.teemMemberForm.get('teamNumber').value){
          //VALIDACIONES ANTERIORES AL 18/07/24 DEPRECADO 
          //if(this.ltsOfTeams[this.teemMemberForm.get('teamNumber').value-1].teamMembers.length == 0){
          if(this.wsStarted){
            Swal.fire({
              html: `<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 105.- Workshop has alredy started! </label>`,
              imageUrl: '../../../../assets/iconPrevention.svg',
            });
          }else {
            this.ltsOfTeams[this.teemMemberForm.get('teamNumber').value-1].teamMembers.unshift({
              UserID: userId,
              FirstName: this.teemMemberForm.get('firstName').value,
              LastName: this.teemMemberForm.get('lastName').value,
              Email: this.teemMemberForm.get('email').value,
              JobTitle: this.teemMemberForm.get('jobTitle').value,
              type: 3,
              TeamId: (this.teemMemberForm.get('teamNumber').value)?this.teemMemberForm.get('teamNumber').value:-1,
              id: tempId,
              changed: true
            });
          }
      } else {
        if(!isAlreadyOnLstAllTeamMemebers){
          this.lstOfAllTeamMembers.unshift({
            UserID: userId,
            FirstName: this.teemMemberForm.get('firstName').value,
            LastName: this.teemMemberForm.get('lastName').value,
            Email: this.teemMemberForm.get('email').value,
            JobTitle: this.teemMemberForm.get('jobTitle').value,
            type: 3,
            TeamId: (this.teemMemberForm.get('teamNumber').value)?this.teemMemberForm.get('teamNumber').value:-1,
            id: tempId,
            changed: true
          });
        }
      }
      // this.lstOfModifiedTeamMembers.unshift({
      //   UserID: userId,
      //   FirstName: this.teemMemberForm.get('firstName').value,
      //   LastName: this.teemMemberForm.get('lastName').value,
      //   Email: this.teemMemberForm.get('email').value,
      //   JobTitle: this.teemMemberForm.get('jobTitle').value,
      //   type: 3,
      //   TeamId: (this.teemMemberForm.get('teamNumber').value)?this.teemMemberForm.get('teamNumber').value:-1,
      //   id: tempId,
      // });
      // ----------------------------------
      console.log("LST OF AL TEAMS DESPUE DE agregarlos",this.ltsOfTeams)
      // fin if esta editando
    } else {
      // inicio else nuevo contacto
      console.log('NUEVO CONTACTO PROBAR');
      console.log("TEAM NUMBERR: ",this.teemMemberForm.get('teamNumber').value)
      let existsUser = false;
      this.lstOfTeamMembers.forEach((value) => {
        if (value.Email.toLowerCase() == this.teemMemberForm.get('email').value.toLowerCase()) {
          Swal.fire({
            html:
              '<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 95.- User already registered at the table! </label>',
            imageUrl: '../../../../assets/iconPrevention.svg',
          });
          existsUser = true;
          return;
        }
      });
      this.lstFacs.forEach((value) => {
        if (value.toLowerCase() == this.teemMemberForm.get('email').value.toLowerCase()) {
          console.log('es fac',value.toLowerCase(),this.teemMemberForm.get('email').value.toLowerCase());
          Swal.fire({
            html:
              '<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 95.- This user email has been selected as a facilitator! </label>',
            imageUrl: '../../../../assets/iconPrevention.svg',
          });
          existsUser = true;
          return;
        }
      });
      if (existsUser) {
        return;
      }
      this.lstOfContacts.forEach((value) => {
        if (value.Email.toLowerCase() == this.teemMemberForm.get('email').value.toLowerCase()) {
          console.log('es observer',value.Email.toLowerCase(),this.teemMemberForm.get('email').value.toLowerCase());
          Swal.fire({
            html:
              '<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 95.- This user email has been selected as an observer! </label>',
            imageUrl: '../../../../assets/iconPrevention.svg',
          });
          existsUser = true;
          return;
        }
      });
      if (existsUser) {
        return;
      }
      console.log('add contact');
      if(!this.teemMemberForm.get('teamNumber').value)
        this.lstOfTeamMembers.unshift({
          UserID: -1,
          FirstName: this.teemMemberForm.get('firstName').value,
          LastName: this.teemMemberForm.get('lastName').value,
          Email: this.teemMemberForm.get('email').value,
          JobTitle: this.teemMemberForm.get('jobTitle').value,
          type: 3,
          TeamId: (this.teemMemberForm.get('teamNumber').value)?this.teemMemberForm.get('teamNumber').value:-1,
          id: this.consecutiveId,
        });
      if(this.teemMemberForm.get('teamNumber').value){
        //VALIDACION DEPRECADA 18/07/24
        //if(this.ltsOfTeams[this.teemMemberForm.get('teamNumber').value-1].teamMembers.length == 0){
        if(this.wsStarted){
          Swal.fire({
            html: `<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 105.- Workshop has alredy started! </label>`,
            imageUrl: '../../../../assets/iconPrevention.svg',
          });
        }else{
          this.ltsOfTeams[this.teemMemberForm.get('teamNumber').value-1].teamMembers.unshift({
            UserID: -1,
            FirstName: this.teemMemberForm.get('firstName').value,
            LastName: this.teemMemberForm.get('lastName').value,
            Email: this.teemMemberForm.get('email').value,
            JobTitle: this.teemMemberForm.get('jobTitle').value,
            type: 3,
            TeamId: (this.teemMemberForm.get('teamNumber').value)?this.teemMemberForm.get('teamNumber').value:-1,
            id: this.consecutiveId,
          });
          this.lstOfTeamMembers.unshift({
            UserID: -1,
            FirstName: this.teemMemberForm.get('firstName').value,
            LastName: this.teemMemberForm.get('lastName').value,
            Email: this.teemMemberForm.get('email').value,
            JobTitle: this.teemMemberForm.get('jobTitle').value,
            type: 3,
            TeamId: (this.teemMemberForm.get('teamNumber').value)?this.teemMemberForm.get('teamNumber').value:-1,
            id: this.consecutiveId,
          });
        }
      } else {
        this.lstOfAllTeamMembers.unshift({
          UserID: -1,
          FirstName: this.teemMemberForm.get('firstName').value,
          LastName: this.teemMemberForm.get('lastName').value,
          Email: this.teemMemberForm.get('email').value,
          JobTitle: this.teemMemberForm.get('jobTitle').value,
          type: 3,
          TeamId: (this.teemMemberForm.get('teamNumber').value)?this.teemMemberForm.get('teamNumber').value:-1,
          id: this.consecutiveId,
        });
      }
      // this.lstOfNewTeamMembers.unshift({
      //   UserID: -1,
      //   FirstName: this.teemMemberForm.get('firstName').value,
      //   LastName: this.teemMemberForm.get('lastName').value,
      //   Email: this.teemMemberForm.get('email').value,
      //   JobTitle: this.teemMemberForm.get('jobTitle').value,
      //   type: 3,
      //   TeamId: (this.teemMemberForm.get('teamNumber').value) ? this.teemMemberForm.get('teamNumber').value : -1,
      //   id: this.consecutiveId,
      // });
      this.consecutiveId++;
      // fin else nuevo contacto
    }
    this.isEditContact = -1;
    // this.auxTeamMembers = this.lstOfTeamMembers
    console.log(this.lstOfTeamMembers, this.lstOfAllTeamMembers,this.ltsOfTeams)
    this.dataSourceTable = new MatTableDataSource(this.lstOfTeamMembers);
    this.dataSourceTable.paginator = this.paginator;
    this.teemMemberForm.get('firstName').setValue('');
    this.teemMemberForm.get('lastName').setValue('');
    this.teemMemberForm.get('email').setValue('');
    this.teemMemberForm.get('jobTitle').setValue('');
    this.teemMemberForm.get('teamNumber').setValue(null);
    document.getElementById('firstName').focus();
  }

  fnBtnCancelar() {
    if (
      this.teemMemberForm.get('firstName').value != '' ||
      this.teemMemberForm.get('lastName').value != '' ||
      this.teemMemberForm.get('email').value != '' ||
      this.teemMemberForm.get('jobTitle').value != '' ||
      this.lstOfTeamMembers.length > this.lstOfOldTeamMembers.length ||
      this.isEdited
    ) {
      Swal.fire({
        html:
          '<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">96.- Do you want to exit without saving changes? </label>',
        imageUrl: '../../../../assets/iconPrevention.svg',
        showCancelButton: true,
        confirmButtonColor: '#6FB45C',
        cancelButtonColor: '#ED5050',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true,
      }).then((result) => {
        console.log(this.lstOfOldTeamMembers);
        if (result.isConfirmed) {
          console.log("AQUIIIIIIIIIIII")
          // this.teamMembers.emit({status: 'cancel'});
          if(this.lstOfOldTeamMembers.length > 0){
            console.log("AQUIIIIIIIIIIII")
            this.lstOfTeamMembers = this.lstOfOldTeamMembers;
            this.ltsOfTeams = this.lstOfOldTeams
          } else {
            console.log("AQUIIIIIIIIIIII")
            this.lstOfTeamMembers = [];
            this.ltsOfTeams = [
              {
                TeamId: 1,
                TeamName: 'Team 1',
                teamMembers: [],
                TeamIdBD: null
              },
              {
                TeamId: 2,
                TeamName: 'Team 2',
                teamMembers: [],
                TeamIdBD: null
              },
              {
                TeamId: 3,
                TeamName: 'Team 3',
                teamMembers: [],
                TeamIdBD: null
              },
              {
                TeamId: 4,
                TeamName: 'Team 4',
                teamMembers: [],
                TeamIdBD: null
              },
            ];
          }
          console.log("LSTOFTEAMMEMBERS CANCEL :::: ", this.lstOfOldTeamMembers,this.ltsOfTeams)
          this.matDialogRef.close({
            lstOfTeams: this.ltsOfTeams,
            status: 'create',
          });
        }
      });
    } else {
      // this.teamMembers.emit({status: 'cancel'});
      console.log("LSTOFTEAMMEMBERS CANCEL :::: ", this.lstOfOldTeamMembers,this.ltsOfTeams,this.lstOfTeamMembers)
      this.matDialogRef.close({ lstOfTeams: this.ltsOfTeams,status: 'create' });
    }
  }

  fnVerifyTeamsLength(){
    let equiposConMiembros = 0;
    this.ltsOfTeams.forEach(equipo => {
      if (equipo.teamMembers.length > 0) {
        equiposConMiembros++;
      }
    });
  
    if (equiposConMiembros < 2) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You need at least to 2 teams with members to play the game!',
      });
      return false;
    }else{
      return true;
    }
  }

  fnGuardarContactos() {

    var allow = this.fnVerifyTeamsLength();
    if(allow){
      if (this.lstOfAllTeamMembers.length > 1 && this.ltsOfTeams.length > 1) {
        Swal.fire({
          html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 97.- There are unassigned members, if they are not assigned a team automatically they will be saved in team 1 </label>`,
          imageUrl: '../../../../assets/iconPrevention.svg',
          showCancelButton: true,
          confirmButtonColor: '#6FB45C',
          cancelButtonColor: '#ED5050',
          confirmButtonText: 'Yes, save!',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.lstOfTeamMembers = this.lstOfTeamMembers.filter(
              (user) => user.TeamId == -1
            );
            this.lstOfTeamMembers.forEach((value) => {
              value.TeamId = 1;
            });
            this.ltsOfTeams[0].teamMembers = this.ltsOfTeams[0].teamMembers.concat(
              this.lstOfTeamMembers
            );
            this.lstOfTeamMembers = [];
            if (this.isEdit) {
              // comentado pensando en que simpre deberan tratarse los members como nuevos
              /*this.lstOfNewTeamMembers = this.lstOfTeamMembers.filter(value => value.UserID == -1);
              this.lstOfTeamMembers = this.lstOfTeamMembers.filter(value => value.UserID != -1);
              this.teamMembers.emit({lstOfTeamMembers: this.lstOfTeamMembers, lstOfNewTeamMembers: this.lstOfNewTeamMembers, status: 'update'});*/
              // this.teamMembers.emit({lstOfTeams: this.ltsOfTeams, status: 'create'});
              console.log("Nuevos contactos if ", this.lstOfNewTeamMembers);
              console.log("Contactos modificados if ", this.lstOfModifiedTeamMembers);
              this.matDialogRef.close({
                lstOfTeams: this.ltsOfTeams,
                status: 'create',
              });
            } else {
              console.log('LTSOFTEAMSMEMBERS::::: ', this.lstOfTeamMembers);
              console.log("Nuevos contactos else ", this.lstOfNewTeamMembers);
              console.log("Contactos modificados else ", this.lstOfModifiedTeamMembers);
              // this.teamMembers.emit({lstOfTeams: this.ltsOfTeams, status: 'create'});
              this.matDialogRef.close({
                lstOfTeams: this.ltsOfTeams,
                status: 'create',
              });
            }
          }
        });
      } else {
        if (this.isEdit) {
          // comentado pensando en que simpre deberan tratarse los members como nuevos
          /*this.lstOfNewTeamMembers = this.lstOfTeamMembers.filter(value => value.UserID == -1);
          this.lstOfTeamMembers = this.lstOfTeamMembers.filter(value => value.UserID != -1);
          this.teamMembers.emit({lstOfTeamMembers: this.lstOfTeamMembers, lstOfNewTeamMembers: this.lstOfNewTeamMembers, status: 'update'});*/
          // this.teamMembers.emit({lstOfTeams: this.ltsOfTeams, status: 'create'});
          console.log("Nuevos contactos if ", this.lstOfNewTeamMembers);
          console.log("Contactos modificados if ", this.lstOfModifiedTeamMembers);
          this.matDialogRef.close({
            lstOfTeams: this.ltsOfTeams,
            status: 'create',
            lstOfNewTeamMembers: this.lstOfNewTeamMembers,
            lstOfModifiedTeamMembers: this.lstOfModifiedTeamMembers
          });
        } else {
          // this.teamMembers.emit({lstOfTeams: this.ltsOfTeams, status: 'create'});
          console.log("Nuevos contactos else ", this.lstOfNewTeamMembers);
          console.log("Contactos modificados else ", this.lstOfModifiedTeamMembers);
          this.matDialogRef.close({
            lstOfTeams: this.ltsOfTeams,
            status: 'create',
            lstOfNewTeamMembers: this.lstOfNewTeamMembers,
            lstOfModifiedTeamMembers: this.lstOfModifiedTeamMembers
          });
        }

    }

    
    }

    // this.spinner.show();
    //   HARD CODE PARA CREAR UN EQUIPO
    //   workshop, nameTeam, avatarTeam, lstofMembers
    // this.usuarioService.createUsersByBulkLoad(1,'Tema12','Avatar12',this.lstOfTeamMembers, this.isResponseErrorEmailAlreadyRegister).subscribe(response => {
    //   this.spinner.hide();
    //   this.fnShowMessageResponse(response);
    // }, error => {
    //   this.spinner.hide();
    //   Swal.fire({
    //     text: '',
    //     icon: 'error',
    //     html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> ${error.message}</label>`
    //   });
    // });
  }

  fnShowMessageResponse(response) {
    if (response.intResponse == 200) {
      Swal.fire({
        text: '',
        imageUrl: '../../../../assets/iconSuccessfully.svg',
        imageAlt: 'icon successfully',
        confirmButtonColor: '#6FB45C',
        html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 98.- Save team members successfully </label>`,
      });
      this.isResponseErrorEmailAlreadyRegister = false;
      this.lstOfTeamMembers = [];
      this.dataSourceTable.data = [];
      // this.router.navigate(['listado-usuarios']);
    } else if (
      response.intResponse == 203 &&
      response.strAnswer == 'User already exists!'
    ) {
      Swal.fire({
        text: '',
        icon: 'error',
        html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">99.- The user with the email ${response.emailInvalid} is already registered! </label>`,
      });
      this.isResponseErrorEmailAlreadyRegister = true;
      this.lstOfTeamMembers = response.remainingEmails;
      this.dataSourceTable = new MatTableDataSource(response.remainingEmails);
      this.dataSourceTable.paginator = this.paginator;
    } else {
      Swal.fire({
        text: '',
        icon: 'error',
        html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">100.- ${response.strAnswer} </label>`,
      });
    }
  }

  fnDeleteUser(row) {
    console.log(row);
    if(this.ltsOfTeams[row.TeamId-1].teamMembers.length == 1){
      Swal.fire({
        html: `<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 105.- If you delete this player, his team would be empty. </label>`,
        imageUrl: '../../../../assets/iconPrevention.svg',
      });
      return;
    }
    Swal.fire({
      html:
        '<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 101.- Are you sure you want to remove this team member from this workshop? </label>',
      imageUrl: '../../../../assets/iconPrevention.svg',
      showCancelButton: true,
      confirmButtonColor: '#6FB45C',
      cancelButtonColor: '#ED5050',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('usuario a eliminar: ', row);
        if (row.UserID != -1 && row.UserID) {
          console.log('diferente de -1');
          console.log('', row.UserID);
          this.usuarioService
            .deleteUserById(row.UserID)
            .subscribe((response) => {
              this.showResponeMesagge(response, row);
            });
        } 
        this.ltsOfTeams.forEach((value) => {
          value.teamMembers.forEach((member, i) => {
            if (member.Email == row.Email) {
              console.log("Se elimina a: ",member)
              value.teamMembers.splice(i, 1);
            }
          });
        });
        this.lstOfAllTeamMembers.forEach((item, i) => {
          if (item.Email == row.Email) {
            console.log("Se elimina a: ",item)
            this.lstOfAllTeamMembers.splice(i, 1);
          }
        });
        this.lstOfTeamMembers.forEach((item, i) => {
          if (item.Email == row.Email) {
            console.log("Se elimina a: ",item)
            this.lstOfTeamMembers.splice(i, 1);
          }
        });
        this.dataSourceTable.data = this.lstOfTeamMembers;
        this.teemMemberForm.get('firstName').setValue('');
        this.teemMemberForm.get('lastName').setValue('');
        this.teemMemberForm.get('email').setValue('');
        this.teemMemberForm.get('jobTitle').setValue('');
      }
    });
  }

  showResponeMesagge(response, row) {
    const whoDeleted = 'Observer';
    if (response.intResponse == 200) {
      Swal.fire({
        text: '',
        imageUrl: '../../../../assets/iconSuccessfully.svg',
        imageAlt: 'icon successfully',
        confirmButtonColor: '#6FB45C',
        html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">102.- ${whoDeleted} has been deleted successfully</label>`,
      });
      this.lstOfTeamMembers.forEach((item, i) => {
        if (item.Email == row.Email) {
          console.log("Se elimina a: ",item)
          this.lstOfTeamMembers.splice(i, 1);
        }
      });
      this.dataSourceTable.data = this.lstOfTeamMembers;
    } else {
      Swal.fire('Error!','103.-'+ response.strAnswer, 'error');
    }
  }

  fnEditUser(row, i) {
    if (
      this.teemMemberForm.get('firstName').value != '' ||
      this.teemMemberForm.get('lastName').value != '' ||
      this.teemMemberForm.get('email').value != '' ||
      this.teemMemberForm.get('jobTitle').value != ''
    ) {
      Swal.fire({
        html:
          '<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 104.- You still do not save the user entered! </label>',
        imageUrl: '../../../../assets/iconPrevention.svg',
        showCancelButton: true,
        confirmButtonColor: '#6FB45C',
        cancelButtonColor: '#ED5050',
        confirmButtonText: 'Yes, edit this user',
        cancelButtonText: 'No, save first',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.replaceInputsContactForm(row);
          this.isEditContact = i;
        } else {
          this.isEditContact = -1;
        }
      });
    } else {
      this.replaceInputsContactForm(row);
      this.isEditContact = i;
    }
  }

  replaceInputsContactForm(row) {
    this.isEdited = true;
    this.teemMemberForm.get('firstName').setValue(row.FirstName);
    this.teemMemberForm.get('lastName').setValue(row.LastName);
    this.teemMemberForm.get('email').setValue(row.Email);
    this.teemMemberForm.get('jobTitle').setValue(row.JobTitle);
    this.teemMemberForm.get('teamNumber').setValue(row.TeamId>0?row.TeamId:null);
  }

  addTeam() {
    console.log("Length teams;:",this.teamCount)
    if (this.teamCount >= 6) {
      Swal.fire({
        html: `<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 105.- You have exceeded the teams limit! </label>`,
        imageUrl: '../../../../assets/iconPrevention.svg',
      });
      return;
    } 
    this.teamCount = this.lstOfDragabbles.length + 1;
    this.lstOfDragabbles.push({
      teamId: this.teamCount,
      lstName: 'Team ' + this.teamCount,
      teamMembers: [],
    });
    this.teamCount = this.ltsOfTeams.length + 1;
    this.ltsOfTeams.push({
      TeamId: this.teamCount,
      TeamName: 'Team ' + this.teamCount,
      teamMembers: [],
      TeamIdBD: null
    });
  }

  dropTeamMemberToTeam(event: CdkDragDrop<any>, team) {
    console.log('dropped on team: ', team);
    console.log('fnDrop dropTeamMemberToTeam:', event);
    console.log(
      'event.previousContainer:::',
      event.previousContainer,
      ' event.container:::',
      event.container
    );

    if (event.previousContainer === event.container) {
      console.log(
        'IF  previousContainer:',
        event.previousContainer,
        ' event.container:',
        event.container
      );
      // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      // notificamos SERVIDOR
    } else {
      console.log(
        'ELSE previousContainer:',
        event,
        ' event.previousIndex::',
        event.previousIndex,
        ' event.previousContainer.data:::',
        event.previousContainer.data
      );
    }
  }

  eventDragginTeam(event, item, i) {
    console.log('fasdaaaaaaaaaaaaaaaaaaaa', event, item);
  }
  drop(event: CdkDragDrop<{}[]>, team) {
    if (event.previousContainer == event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      ////VALIDACIONES ANTERIORES AL 18/07/24 DEPRECADO 
      //if((event.container.data.length == 0 || ((event.previousContainer.data.length - 1) == 0 ) && event.previousContainer.id != "unSetTeamMembers")){
      if(this.wsStarted){
        Swal.fire({
          html: `<style>swal2-confirm{border-radius: 50px !important; } swal2-styled{border-radius: 50px !important; }</style><label  style="font-size: 22px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 105.- This is not a player! </label>`,
          imageUrl: '../../../../assets/iconPrevention.svg',
        });
        return;
      }
      this.lstOfTeamMembers.forEach((value) => {
        if (
          value.Email ==
          event.previousContainer.data[event.previousIndex]['Email']
        ) {
          value.TeamId = team.TeamId;
          console.log('Encuenta', value);
        }
      });
      event.previousContainer.data[event.previousIndex]['TeamId'] = team.TeamId;
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    console.log("NEW TEAMS",this.ltsOfTeams);
    console.log("OLD TEAMS",this.lstOfOldTeams);
  }
}
