<div class="all">
    <div *ngIf="jsonLabels[10] != undefined" mat-dialog-content cdkDrag cdkDragRootElement=".cdk-overlay-pane" style="height:85%" cdkDragHandle>
    
        <div>
            <h2 class="style1 calibriFont">{{fnObtenerLabel('13')}}</h2>
            <p class="style2b calibriFont p-2">{{fnObtenerLabel('3001')}}</p> 
        </div>
    
        <div class="divstylesize">
            <p class="style11 calibriFont text-center">{{fnObtenerLabel('3002')}}</p>
            <!-- <div class="stylebtnsize"><i class="fa fa-search-plus" (click)="fnResize(true)" title="Expand Size"></i>
                &nbsp;&nbsp;&nbsp;<i class="fa fa-search-minus" (click)="fnResize(false)" title="Minimize Size"></i></div> -->
        </div>
        <div class="row" class="marginwhite">
            <div class="grid-container">
                <div class="marginblack style12 calibriFont d-flex justify-content-center align-items-center" style="width: 510px; text-align: center;">{{fnObtenerLabel('13')}}</div>
                <div class="row" style="margin-right:0; margin-left:0;" [ngStyle]="{'width.px': arrColumnas.length * 60}">
                    <div class="marginblack style2a calibriFont" style="text-align: center; font-weight: bold;" [ngStyle]="{'width.px': arrColumnas.length * 60}">{{fnObtenerLabel('5013')}}</div>
                    <div class="marginblack style4 calibriFont" style="width: 60px; text-align: center;" *ngFor="let jsnTeam of arrColumnas; let i = index;">{{i+1}}</div>
                </div>
            </div>
        </div>
    
        <div class="row" class="marginwhite2">
            <div class="grid-container">
                <div class="marginLeft px-2 colorblue textoazul style2b calibriFont">{{fnObtenerLabel('3003')}}</div>
                <div class="textoblanco style2a calibriFont px-2 fontSize12" style="font-weight: bold;">{{fnObtenerLabel('14')}}</div>
                <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'marginRigth': i === arrColumnas.length - 1}">
                    <input [ngClass]="{'notSave': isInCurrentStatus}" [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['total'], 'total', i)"
                    [ngStyle]="{'color': totalSales != jsnTeam['total'] && intentos >= 3  && jsnTeam['total'] != null ? 'red':'black',
                    'border': totalSales != jsnTeam['total'] && intentos >= 3 && jsnTeam['total'] != null ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input10" matInput class="w style2b calibriFont" [(ngModel)]="jsnTeam['total']" (keyup.enter)="validateKey('input20')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
                    <!-- 'border': totalSales != jsnTeam['total'] && intentos >= 3 ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input10" matInput class="w style2b calibriFont" [(ngModel)]="jsnTeam['total']" (keyup.enter)="validateKey('input20')" (keyup)="moveFocusInput($event)" min="0" (keypress)="validateInputNumber($event)"> -->
                    <div *ngIf="i != mes || !canEdit || !canEdit">
                        {{jsnTeam['total']}}
                    </div>
                </div>   
            </div>
        </div>
    <!-- fin 1-->
    
    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginLeft px-2 colorblue textoazul style2b calibriFont">{{fnObtenerLabel('3004')}}</div>
            <div class="textoblanco style2a calibriFont px-2 textRigth fontSize12"><label class="colorred"> {{getSingleTextLabel('103')}} </label> {{fnObtenerLabel('18')}}</div>
            <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'marginRigth': i === arrColumnas.length - 1}">
                <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['costGood'], 'costGood', i)"
                [ngStyle]="{'color': costGoodSold != jsnTeam['costGood'] && intentos >= 3 && jsnTeam['costGood'] != null ? 'red':'black',
                'border': costGoodSold != jsnTeam['costGood'] && intentos >= 3 && jsnTeam['costGood'] != null ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input20" matInput class="w" [(ngModel)]="jsnTeam['costGood']" (keyup.enter)="validateKey('input30')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
                <!-- 'border': costGoodSold != jsnTeam['costGood'] && intentos >= 3 ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input20" matInput class="w" [(ngModel)]="jsnTeam['costGood']" (keyup.enter)="validateKey('input30')"(keyup)="moveFocusInput($event)" min="0" (keypress)="validateInputNumber($event)"> -->
                <div *ngIf="i != mes || !canEdit">
                    {{jsnTeam['costGood']}}
                </div>
            </div>   
        </div>
    </div>
    <!-- fin 2-->
    
    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginLeft px-2 colorblue textoazul style2b calibriFont">{{fnObtenerLabel('3005')}}</div>
            <div class="textoblanco style2a calibriFont px-2" style="display: inline-flex;">
                <div class="fontSize12" style="width: 90%;">{{fnObtenerLabel('20')}}</div>
                <span class="equal" (click)="fnResultado(10,20,30)" style="text-align: right; height: 1px; font-size: 20px; cursor: pointer; width: 10%;">&#61;</span>
            </div>
            <div class="margin5 textoInput2 wblue style6 calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'marginRigth': i === arrColumnas.length - 1}">
                <input *ngIf="i == mes && canEdit" type="number" id="input30" matInput class="wblue style6 calibriFont"
                [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['contribution'], 'contribution', i)"
                [(ngModel)]="jsnTeam['contribution']" (keyup.enter)="validateKey('input40')" (keypress)="validateInputNumber($event)">
                <div *ngIf="i != mes || !canEdit">
                    {{jsnTeam['contribution']}}
                </div>
            </div>   
        </div>
    </div>
    <!-- fin 3-->
     
    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginLeft px-2 colorblue textoazul style2b calibriFont">{{fnObtenerLabel('3006')}}</div>
            <div class="textoblanco style2a calibriFont px-2 textRigth fontSize12"> <label class="colorred"> {{getSingleTextLabel('103')}} </label> {{fnObtenerLabel('21')}}</div>
            <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'marginRigth': i === arrColumnas.length - 1}">
                <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['factory'], 'factory', i)"
                [ngStyle]="{'color': factotyOverheads != jsnTeam['factory'] && intentos >= 3 && jsnTeam['factory'] != null ? 'red':'black',
                'border': factotyOverheads != jsnTeam['factory'] && intentos >= 3 && jsnTeam['factory'] != null ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input40" matInput class="w" [(ngModel)]="jsnTeam['factory']" (keyup.enter)="validateKey('input50')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
                <div *ngIf="i != mes || !canEdit">
                    {{jsnTeam['factory']}}
                </div>
            </div>   
        </div>
    </div>
    <!-- fin 4-->
    
    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginLeft px-2 colorblue textoazul style2b calibriFont">{{fnObtenerLabel('3007')}}</div>
            <div class="textoblanco style2a calibriFont px-2" style="display: inline-flex;">
                <div class="fontSize12" style="width: 90%;">{{fnObtenerLabel('26')}}</div>
                <span class="equal" (click)="fnResultado(30,40,50)" style="text-align: right;  width: 10%; height: 1px; font-size: 20px; cursor: pointer;">&#61;</span>
            </div>
            <div class="margin5 textoInput2 wblue style6 calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'marginRigth': i === arrColumnas.length - 1}">
                <input *ngIf="i == mes && canEdit" type="number" id="input50" matInput class="wblue  style6 calibriFont"
                [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['gross'], 'gross', i)"
                [(ngModel)]="jsnTeam['gross']" (keyup.enter)="validateKey('input60')" (keypress)="validateInputNumber($event)">
                <div *ngIf="i != mes || !canEdit">
                    {{jsnTeam['gross']}}
                </div>
            </div>   
        </div>
    </div>
    <!-- fin 5-->
    
    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginLeft px-2 colorblue textoazul style2b calibriFont">{{fnObtenerLabel('3008')}}</div>
            <div class="textoblanco style2a calibriFont textRigth" style="padding-right: 1px; font-size: 12px !important;"><label class="colorred">{{getSingleTextLabel('103')}}</label> {{fnObtenerLabel('27')}}</div>
            <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'marginRigth': i === arrColumnas.length - 1}">
                <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['selling'], 'selling', i)"
                [ngStyle]="{'color': selling != jsnTeam['selling'] && intentos >= 3 && jsnTeam['selling'] != null ? 'red':'black',
                'border': selling != jsnTeam['selling'] && intentos >= 3 && jsnTeam['selling'] != null? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input60" matInput class="w" [(ngModel)]="jsnTeam['selling']" (keyup.enter)="validateKey('input70')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
                <div *ngIf="i != mes || !canEdit">
                    {{jsnTeam['selling']}}
                </div>
            </div>   
        </div>
    </div>
    <!-- fin 6-->
    
    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginLeft px-2 colorblue textoazul style2b calibriFont">{{fnObtenerLabel('3009')}}</div>
            <div class="textoblanco style2a calibriFont px-2" style="display: inline-flex;">
                <div class="fontSize12" style="width: 90%;">{{fnObtenerLabel('35')}}</div>
                <span class="equal" (click)="fnResultado(50,60,70)" style="text-align: right;  width: 10%; height: 1px; font-size: 20px; cursor: pointer;">&#61;</span>
            </div>
            <div class="margin5 textoInput2 wblue style6 calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'marginRigth': i === arrColumnas.length - 1}">
                <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['operating'], 'operating', i)"
                *ngIf="i == mes && canEdit" type="number" id="input70" matInput class="wblue style6 calibriFont" 
                [(ngModel)]="jsnTeam['operating']" (keyup.enter)="validateKey('input80')" (keypress)="validateInputNumber($event)">
                <div *ngIf="i != mes || !canEdit">
                    {{jsnTeam['operating']}}
                </div>
            </div>   
        </div>
    </div>
    <!-- fin 7-->
    
    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginLeft px-2 colorblue textoazul style2b calibriFont">{{fnObtenerLabel('3010')}}</div>
            <div class="textoblanco style2a calibriFont px-2 textRigth fontSize12"> <label class="colorred"> {{getSingleTextLabel('103')}} </label> {{fnObtenerLabel('39')}} </div>
            <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'marginRigth': i === arrColumnas.length - 1}">
                <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['interest'], 'interest', i)"
                [ngStyle]="{'color': interest != jsnTeam['interest'] && intentos >= 3 && jsnTeam['interest'] != null ? 'red':'black',
                'border': interest != jsnTeam['interest'] && intentos >= 3 && jsnTeam['interest'] != null  ? '2px solid #ff0000':'' }" *ngIf="i == mes && canEdit" type="number" id="input80" matInput class="w" [(ngModel)]="jsnTeam['interest']" (keyup.enter)="validateKey('input90')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
                <div *ngIf="i != mes || !canEdit">
                    {{jsnTeam['interest']}}
                </div>
            </div>   
        </div>
    </div>
    <!-- fin 8-->
    
    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginLeft px-2 colorblue textoazul style2b calibriFont">{{fnObtenerLabel('3011')}}</div>
            <div class="textoblanco style2a calibriFont px-2" style="display: inline-flex;">
                <div class="fontSize12" style="width: 90%;">{{fnObtenerLabel('40')}}</div>
                <span class="equal" (click)="fnResultado(70,80,90)" style="text-align: right;  width: 10%; height: 1px; font-size: 20px; cursor: pointer;">&#61;</span>
            </div>
            <div class="margin5 textoInput2 wblue style6 calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'marginRigth': i === arrColumnas.length - 1}">
                <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['income'], 'income', i)"
                *ngIf="i == mes && canEdit" type="number" id="input90" matInput class="wblue style6 calibriFont"  
                [(ngModel)]="jsnTeam['income']" (keyup.enter)="validateKey('input100')" (keypress)="validateInputNumber($event)">
                <div *ngIf="i != mes || !canEdit">
                    {{jsnTeam['income']}}
                </div>
            </div>   
        </div>
    </div>
    <!-- fin 9-->
    
    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginLeft px-2 colorblue textoazul style2b calibriFont">{{fnObtenerLabel('3012')}}</div>
            <div class="textoblanco style2a calibriFont px-2 textRigth fontSize12"><!--{{fnObtenerLabel('103')}}--> <label class="colorred"> {{getSingleTextLabel('103')}} </label> {{fnObtenerLabel('41')}}</div>
            <div class="margin4 textoInput style5b calibriFont verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'marginRigth': i === arrColumnas.length - 1}">
                <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['taxes'], 'taxes', i)"
                [ngStyle]="{'color': taxes != jsnTeam['taxes'] && intentos >= 3 && jsnTeam['taxes'] != null ? 'red':'black',
                'border': taxes != jsnTeam['taxes'] && intentos >= 3 && jsnTeam['taxes'] != null ? '2px solid #ff0000':''}" *ngIf="i == mes && canEdit" type="number" id="input100" matInput class="w"  [(ngModel)]="jsnTeam['taxes']" (keyup.enter)="validateKey('input110')" onkeypress="return event.charCode >= 48" min="0" (keypress)="validateInputNumber($event)">
                <div *ngIf="i != mes || !canEdit">
                    {{jsnTeam['taxes']}}
                </div>
            </div>   
        </div>
    </div>
    <!-- fin 10-->
    
    <div class="row" class="marginwhite2">
        <div class="grid-container">
            <div class="marginLeft px-2 colorblue textoazul style2b calibriFont borderBottomISBlue">{{fnObtenerLabel('3013')}}</div>
            <div class="textoblanco style2a calibriFont px-2 textRigth fontSize12 borderBottomISBlue"> 
                <strong  style="display: inline-flex;"> 
                    <div class="fontSize12" style="width: 90%; margin-right: 30px;">{{fnObtenerLabel('42')}} </div>
                    <span class="equal" (click)="fnResultado(90,100,110)" style="text-align: right; height: 1px; font-size: 20px; cursor: pointer; width: 10%;">&#61;</span>
                </strong>
            </div>
            <div class="margin5 textoInput2 wblue style7b calibriFont borderBottomISBlue verticalAligmentDiv" *ngFor="let jsnTeam of arrColumnas; let i = index;" [ngClass]="{'marginRigth': i === arrColumnas.length - 1}">
                <input [ngClass]="{'notSave': isInCurrentStatus}" (change)="fnChangeInput(jsnTeam['netIncome'], 'netIncome', i)"
                 *ngIf="i == mes && canEdit" type="number" id="input110" matInput class="wblue style7b calibriFont borderBottomISBlue" 
                 [(ngModel)]="jsnTeam['netIncome']" (keyup.enter)="validateKey('input120')" (keypress)="validateInputNumber($event)">
                <div *ngIf="i != mes || !canEdit">
                    {{jsnTeam['netIncome']}}
                </div>
            </div>   
        </div>
    </div>
    <!-- fin 11-->
    <!-- <div class="alert" id="alert"  *ngIf="blnShowAlert" >
        <ng-container *ngIf="netIncome > 0; else netIncomeLoss">
            <p>Congratulations! You made a profit of {{netIncome}}!</p>
        </ng-container>
        <ng-template #netIncomeLoss>
            <p>This is sad, you made a loss.</p>
        </ng-template>
        
      </div> -->
    </div>
    <div mat-dialog-actions style="text-align: center; display: block !important">  
        <button *ngIf="canEdit && (!isfacilitator || month != 2)"
         class="btn btn-primary mx-3 style8 calibriFont" type="submit" (click)="fnSave()">&nbsp;&nbsp;&nbsp;&nbsp;{{getSingleTextLabel('136')}}&nbsp;&nbsp;&nbsp;&nbsp;</button>
        <button  class="btn btn-secondary mx-3 style9 calibriFont" (click)="onCancel()">&nbsp;{{getSingleTextLabel('123')}}&nbsp;</button>
      </div>
    </div>
    
    <ngx-spinner bdColor="rgba(0,0,0,0.75)" size="large" color="#ffffff" type="ball-clip-rotate-multiple" [fullScreen]="true">
          <p style="color: white"> Loading... </p>
    </ngx-spinner>