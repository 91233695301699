import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BoardService } from 'src/app/services/board/board.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { takeUntil } from 'rxjs/operators';
import { SocketioService } from 'src/app/services/socketio.service';
import { BalanceIncomeService } from 'src/app/services/sockets/balance-income/balance-income.service';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { LanguageService } from 'src/app/services/language/language.service';
import { TeamService } from 'src/app/services/team/team.service';

class Piece {
  StatusBoardId: number;
  TeamId: number;
  AreaDrag: number;
  strAreaDrag: string;
  Valor: number;
  tipo: number;

  constructor(object:{AreaDrag: number, Valor: number, tipo: number, TeamId: number, StatusBoardId: number}){
    this.AreaDrag = object['AreaDrag'];
    this.strAreaDrag =   'Bank';
    this.Valor = object['Valor'];
    this.tipo = object['tipo'];
    this.TeamId = object['TeamId'];
    this.StatusBoardId = object['StatusBoardId'];
  }
}

class ListCheckbox{
  labels:string[] = [];
  checkList: Array<number> | any = [];
}
class CheckBox{
  CheckListId: number;
  idCheck: number;
  valor: number;
  TeamId: number;
  title: string;
  show: number | string;

  constructor(object:{CheckListId: number, idCheck: number, valor: number, TeamId: number,title: string}){
    this.CheckListId = object['CheckListId'];
    this.idCheck = object['idCheck'];
    this.valor = object['valor'];
    this.TeamId = object['TeamId'];
    this.title = object['title'];
    this.show = object['show'];
  }
}

class CheckList{
  checkList: Array<CheckBox> = [];

  constructor(json:[]){
    json.forEach(item => {
      this.checkList.push(new CheckBox(item));
    })
  }
  codeCheckList(){
    return JSON.stringify(this.checkList);
  }
}

export class TeamCheckList{
  tableCheck:any;
  checkList: CheckList;
  Status: number;
  Month: number;
  Avatar: string;
  Color: string;
  Mode: number;
  Room: string;
  TeamId: number;
  WokshopId: number;
  constructor(json: any){
    this.checkList = new CheckList(json['checkList']);
    this.Status = json['Status'];
    this.Month = json['Month'];
    this.Avatar = json['Avatar'];
    this.Color = json['Color'];
    this.Mode = json['Mode'];
    this.Room = json['Room'];
    this.TeamId = json['TeamId'];
    this.WokshopId = json['WokshopId'];
  }
}

@Component({
  selector: 'app-dialog-checklist-service',
  templateUrl: './dialog-checklist-service.component.html',
  styleUrls: ['./dialog-checklist-service.component.scss']
})

export class DialogChecklistServiceComponent implements OnInit {

  auxInsert = 3;
  labelsCheck = [
    "Select Improvement Option",
    "Clear Income Statement",
    "Update Receivables", 
    "Update Liabilities",
    "Buy or Upgrade Equipment",
    "Pay Recruitment & Training",
    "Buy Raw Materials",
    "Load Raw Materials to machine",
    "Pay Labor",
    "Take Depreciation",
    "Pay Expenses",
    "Prepare Bids",
    "Pay Advertising",
    "The Market",
    "Take Product from Machine",
    "Make Delivery & Recieve Payment",
    "Complete the Income Statement",
    "Adjust Retained Earnings*(Black Chips)",
    "Complete the Balance Sheet"];
  auxLength:number=0;
  dataSourceTable: MatTableDataSource<any> = new MatTableDataSource([]);
  destroy$: Subject<boolean> = new Subject<boolean>();
  workshopId: number;
  openMarket: number;
  muestraImprovement:boolean = true;
  lastStartMonth :number = 0
  businessModel = ''; 
  titulos: string[] = [
    'labels',
  ];
  lstCheckbox: ListCheckbox = {
    labels:[],
    checkList:[],
  };
  arrTextLabels = []
  teams:any [] = []

  lstTeamsChecklist: Array<TeamCheckList> = [];
  constructor( 
    public matDialogRef: MatDialogRef<DialogChecklistServiceComponent>,
    private socketService: SocketioService,
    private spinner: NgxSpinnerService,
    private boardService: BoardService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private balanceIncomeService: BalanceIncomeService,
    private languageservice : LanguageService,
    private teamsService: TeamService,


  ) { }

  async ngOnInit(): Promise<void> {
    console.log(this.data);
    this.workshopId = parseInt(this.data.workshopId);
    this.openMarket = this.data.openMarket;
    this.lastStartMonth = this.data.lastStartMonth;
    this.businessModel = this.data.boardType;
    this.muestraImprovement = this.data.muestraImprovements;
    if(this.lastStartMonth == 2){
      this.muestraImprovement = false;
    }
    this.spinner.show();
    this.updateChecklist();
    this.initSocket();
    this.arrTextLabels = (await this.languageservice.getValidationLabels(parseInt(this.workshopId.toString())).toPromise()).textLabels;
  }
  shouldShowRow(label: string): boolean {
    if (this.businessModel === 'S&S EBIT' && (label === 'Take Product from Machine' || label === 'Pay Labor')) {
      return false;
    }
    return true;
  }
  updateChecklist(){
    console.log('businessModel',this.businessModel);
    this.lstTeamsChecklist = [];
    this.spinner.show();
    this.boardService.getTeamsChecklistByWorkshopId(this.workshopId).subscribe((response:any) =>{
      if(response.intResponse == 200){
        console.log("Ok",response)
        response.data.checklist.forEach(team => {
          //console.log(team);
          let aux = new TeamCheckList(team);
          // this.initTableChech(aux.checkList);
          this.lstTeamsChecklist.push(aux);
          this.spinner.hide();
        });
        this.lstTeamsChecklist.forEach(team =>{
          let auxCheck = this.setTableChecklist(team.checkList, team.Status);
          team.tableCheck = auxCheck;
          console.log(this.lstTeamsChecklist);
          //Ajuste para saber si ya terminarion el mes
          if(team.Month>this.lastStartMonth){
            team['isMonthDone'] = true
          }else{
            team['isMonthDone'] = false
          }

        })
      }else{
        if(response.intResponse != 200){
          console.log(response.strAnswer);
          alert("Codigo de Error: "+response.strAnswer)
          this.spinner.hide();
        }
      }
      
    })
  }
  addLoanToTeam(team,name){
    // console.log(team);
    let pieceLoan = {
      "tipo": 9,
      "AreaDrag": 2,
      "Valor": 20,
      "TeamId": team,
      "StatusBoardId": -1
  };
  // console.log(pieceLoan);
  this.boardService.createCoin(pieceLoan).subscribe(response => {
    if(response.intResponse == 200){
      Swal.fire({icon: 'success', title:"New loan added to: "+ name});
      console.log(response)
    } else {
      Swal.fire({icon:'error', title:'Something wrong'});
    }
  })

  }
  initSocket(){
    this.balanceIncomeService.setupSocketConnection();
    this.balanceIncomeService._balance
    .pipe(takeUntil(this.destroy$)).subscribe(response => {
      if(response){
        console.log(response);
        switch (response.status) {
          case 'reloadTeamsChecklist':
            //console.log(response);
            //console.log(response.data.teamId);
            //console.log("FUNCIONAAAAAAAAAAAA");
            let teamId = response.data.teamId;
            this.teamsService.getAllTeamsById(this.workshopId).subscribe(response => {
              console.log(response)
              this.teams = response.teams;
              this.teams.forEach(team =>{
                  if(team.Month>this.lastStartMonth){
                    team['isMonthDone'] = true
                  }else{
                    team['isMonthDone'] = false
                  }
                  this.lstTeamsChecklist.forEach(team2 =>{
                    if(team2.TeamId == team.TeamId){
                      team2['isMonthDone'] = team['isMonthDone']
                      console.log("entra", team2['isMonthDone'])
                    }
                  })
                
              })
              console.log(this.lstTeamsChecklist);
              setTimeout(() => {
                this.boardService.getTeamChecklistByTeamId(teamId).subscribe((response:any) =>{
                 console.log(response);
                  //console.log(response.response);
                  this.updateTable(response.response,teamId)
                });
              }, 500);
            })
            
           
            break;
          case "resetChecklist":
            console.log("Si entra!!!")
            if(response.data.improveStatus == 2){
              this.lastStartMonth = response.data.month
              setTimeout(() => {
                this.updateChecklist();

              }, 1500);
            }
            break;
          default:
            break;
        }
      }
    });                         
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.balanceIncomeService.closeListener();
  }

  updateTable(checks:[],teamId:number){
    let newCheck = [];
    for (let index = 0; index < this.labelsCheck.length; index++) {
            let checkBox = {
              CheckListId: 0,
              idCheck: 0,
              valor: 2,
              TeamId: 0,
              title: ''
            }
          newCheck.push(checkBox);
    }
    //console.log(newCheck);
    checks.forEach(check=>{
       if(this.labelsCheck.includes(check['title'])){
        //  console.log(check.valor)
        let i = this.labelsCheck.indexOf(check['title']);
        newCheck[i] = check;
       }else{
        console.log("False");
        if(check['title']=="Adjust Retained Earnings"){
          newCheck[17] = check;
         }
        if(check['title']=="Pay Recruitment & Training" || check['title'] == 'Pay Recruitment & Training'){
          newCheck[5] = check;
          console.log("ASI ES")
         }
       }
     })   
      //console.log(newCheck);   

    this.lstTeamsChecklist.forEach(team =>{
      if(team.TeamId == teamId){
        team.tableCheck = newCheck;
      }
    })
    //console.log(this.lstTeamsChecklist); 
  }

  setTableChecklist(checks:CheckList, status:number){
 // console.log(arr)    
 let newCheck = [];
 let teamStatus = status;
 console.log('TEAM STATUS',teamStatus, 'mercado', this.openMarket);
 for (let index = 0; index < this.labelsCheck.length; index++) {
         let checkBox = {
           CheckListId: 0,
           idCheck: 0,
           valor: 2,
           TeamId: 0,
           title: ''
         }
       newCheck.push(checkBox);
 }
 //console.log(newCheck, checks);
 checks.checkList.forEach(check=>{
    if(this.labelsCheck.includes(check.title)){
    //console.log(check)
     let i = this.labelsCheck.indexOf(check.title);
     // Establecer todos los checks como no marcados por defecto
     newCheck[i] = { ...check, valor: 0 };
     if ((status < 10 && (check.title === "Prepare Bids" || check.title === "The Market")) ||
         (status === 10 && check.title === "Prepare Bids" && this.openMarket < 1)) {
       // Mantén estos como no marcados bajo estas condiciones
       newCheck[i] = { ...check, valor: 0 };
     } else if ((status === 10 && check.title === "Prepare Bids" && this.openMarket === 1) ||
                (status > 10 && check.title === "Prepare Bids") ||
                (status >= 14 && (check.title === "Prepare Bids" || check.title === "The Market"))) {
       // Marca los checks bajo estas condiciones
       newCheck[i] = check;
     } else {
      newCheck[i] = check;
     }
    }else{
     console.log("False");
     if(check.title=="Adjust Retained Earnings"){
      newCheck[17] = check;
     }
     if(check.title=="Pay Recruitment & Training" || check.title == 'Pay Recruitment & Training'){
      newCheck[5] = check;
      console.log("ASI ES")
     }

    }
  })   
   console.log(newCheck)   
 return newCheck
  }

  mapCheckboxTitleToLabel(title: string): string {
    switch (title) {
      case 'Clear Income Statement': return this.getSingleTextLabel('4004');
      case 'Update Receivables': return this.getSingleTextLabel('4005');
      case 'Update Liabilities': return this.getSingleTextLabel('4006');
      case 'Buy Raw Materials': return this.getSingleTextLabel('4010');
      case 'Load Raw Materials to machine': return this.getSingleTextLabel('4012');
      case 'Pay Labor': return this.getSingleTextLabel('4014');
      case 'Take Depreciation': return this.getSingleTextLabel('4015');
      case 'Pay Expenses': return this.getSingleTextLabel('4016');
      case 'Prepare Bids': return this.getSingleTextLabel('4029');
      case 'Pay Advertising': return this.getSingleTextLabel('4017');
      case 'The Market': return this.getSingleTextLabel('4018');
      case 'Take Product from Machine': return this.getSingleTextLabel('4019');
      case 'Make Delivery & Recieve Payment': return `${this.getSingleTextLabel('4020')} & ${this.getSingleTextLabel('4022')}`;
      case 'Complete the Income Statement': return this.getSingleTextLabel('4024');
      case 'Adjust Retained Earnings': return this.getSingleTextLabel('4025');
      case 'Adjust Retained Earnings*(Black Chips)': return this.getSingleTextLabel('4025');
      case 'Complete the Balance Sheet': return this.getSingleTextLabel('4026');
      case 'Select Improvement Option': return this.getSingleTextLabel('4030');
      case 'Buy or Upgrade Equipment': return this.getSingleTextLabel('4008');
      case 'Pay Recruitment & Training': return this.getSingleTextLabel('4009');
      default: return title;
    }
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }

}
