<!--<div class="container-ppal">-->
<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="container">
  <div mat-dialog-content >
    <mat-form-field appearance="fill">
        <mat-label>Team Log...</mat-label>
    <mat-select >
          <mat-option *ngFor="let objteam of lstTeamsInfo;let indice=index" [value]="lstTeamsInfo[indice].Avatar" (click)="selectTeam(objteam)" >
              <ng-container *ngIf="lstTeamsInfo[indice].Avatar == null || lstTeamsInfo[indice].Avatar == ''">
                  <label>{{lstTeamsInfo[indice].Avatar}}</label>
              </ng-container>   
              <ng-container *ngIf="lstTeamsInfo[indice].Avatar != null || lstTeamsInfo[indice].Avatar != ''">             
                    <label>{{lstTeamsInfo[indice].Avatar}}</label>
                </ng-container> 
          </mat-option>
                  
    </mat-select>
    </mat-form-field>

    <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="mat-elevation-z8">
                      <table mat-table [dataSource]="dataSourceTable" style="border: 1px solid #D5DCE3" class="mt-2" matSort aria-label="Elements">
                          <!-- Socket NAME Column -->
                        <ng-container matColumnDef="socket_name">
                          <th class="header" mat-header-cell *matHeaderCellDef> Socket Name </th>
                          <td class="cell styleAlignLeft" mat-cell *matCellDef="let row; let i = index">
                            {{row.socketName}}
                          </td>
                        </ng-container>
                          <!-- Label Column -->
                        <ng-container matColumnDef="label">
                          <th class="header" mat-header-cell *matHeaderCellDef> Register </th>
                          <td class="cell styleAlignLeft" mat-cell *matCellDef="let row; let i = index" style="text-align: left; align-content: flex-start;align-items: flex-start;-ms-text-combine-horizontal: all;">
                            {{row.label}}
                          </td>
                        </ng-container>
                          <!-- SocketID Column -->
                        <ng-container matColumnDef="SocketId">
                          <th class="header" mat-header-cell *matHeaderCellDef> Socket ID </th>
                          <td class="cell styleAlignLeft" mat-cell *matCellDef="let row; let i = index" style="text-align: right; align-content: flex-end;align-items: flex-end;-ms-text-combine-horizontal: all;">
                            {{row.socketId}}
                          </td>
                        </ng-container>
                    
                        <div class="tableContainer">
                          <tr class="tableHeader" mat-header-row *matHeaderRowDef="titulos"></tr>
                        </div>
                        <tr class="tableRow" mat-row *matRowDef="let row; columns: titulos;"></tr>
                      </table>
                      <div *ngIf="dataSourceTable.data.length === 0"> <p class="text-center">No records found</p></div>
                      <mat-paginator class="cell_paginator" [pageSizeOptions]="[5,10,25]" showFirstLastButtons></mat-paginator>
              </div>
          </div>
        </div>
      </div>

    </div>  
</div>

